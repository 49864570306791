
/*
Template Name: Synadmin
Author: codervent
Email: codervent@gmail.com
File: app.css
*/

/*  
  - Google Font
  - General
  - Header
  - Page Wrapper
  - Pricing Table
  - Metismenu
  - Forms
  - Components
  - Buttons
  - Background Colors
  - Text Colors
  - Authentication
  - File Manager
  - Tables
  - Invoice Page
  - Chat box
  - Email box
  - Compose Mail
  - Extra Css
  - Switcher
  - Responsive
*/

/* Google Font*/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Roboto&display=swap');

/* General */

html {

	width: 100%;

	height: 100%;

}

body {

	font-family: 'Open Sans', sans-serif;

	font-family: 'Roboto', sans-serif;

	background-color: #f6f6f6;

	letter-spacing: .1px;

	font-size: 14px;

}

a:hover {

	text-decoration: none;

}

/*Header*/

.wrapper {

	width: 100%;

	height: 100%;

	position: relative;

}

.top-header {

	width: 100%;

	height: 70px;

	line-height: 70px;

	background: #fff;

	position: fixed;

	top: 0;

	z-index: 15;

	border-bottom: 0px solid #ededed;

	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);

}

.toggle-btn {

	margin-right: 10px;

	font-size: 28px;

	color: #404142;

	width: 45px;

	height: 45px;

	line-height: 45px;

	background: transparent;

	text-align: center;

	border-radius: 50%;

	cursor: pointer;

	outline: none;

}

.toggle-btn:hover {

	color: #404142;

}

.toggle-btn:active {

	color: #404142;

	background: #f1f1f1;

}

.logo-icon {

	width: 120px;

}
.logo-white {

	display: none;

}
.logo-dark {

	display: block;

}

.logo-icon-2 {

	width: 120px;

}

.logo-text {

	color: #404142;

	font-size: 25px;

}

.top-header .navbar {

	padding: 0;

	background: transparent;

}

.left-topbar {

	width: 260px;

	height: 70px;

	float: left;

	padding-left: 15px;

	background: transparent;

}

.right-topbar {

	width: auto;

	height: 70px;

	padding-right: 15px;

}

.sidebar-header {

	width: auto;

	display: flex;

	align-items: center;

	padding: 7px 10px;

	border-bottom: 1px solid #ededed;

}

.right-topbar .navbar-nav .nav-link {

	padding: 0rem 1.4rem;

	border-left: 1px solid #ededed;

	color: #404142;

	height: 70px;

	font-size: 25px;

}

.search-bar {

	padding: 0rem 2.4rem;

}

.search-arrow-back {

	display: none;

}

.search-btn-mobile {

	display: none;

}

.search-bar .btn-search-back {

	background: #f1f1f1;

	border: 1px solid #d9d3d3;

	padding: 0 20px;

	border-radius: 2px;

	height: calc(1.3em + .75rem + 2px);

}

.search-bar .btn-search-back i {

	font-size: 20px;

	color: #727171;

}

.search-bar .btn-search {

	background: #f1f1f1;

	border: 1px solid #d9d3d3;

	padding: 0 30px;

	border-radius: 2px;

	height: calc(1.3em + .75rem + 2px);

}

.search-bar .btn-search i {

	font-size: 16px;

	color: #727171;

}

.search-bar input {

	height: calc(1.3em + .75rem + 2px);

	border: 0;

	border: 1px solid #d9d3d3;

	box-shadow: inset 0 1px 2px #f1f1f1;

	width: 100%;

	border-radius: 2px;

}

.dropdown-toggle-nocaret:after {

	display: none

}

.user-box {

	line-height: 1.3;

	height: 70px;

}

.user-info {

	text-align: right;

	margin-right: 15px;

}

.user-info .user-name {

	font-size: 16px;

	font-weight: 500;

}

.user-info .designattion {

	font-size: 14px;

	color: #a9a8a8;

}

.user-img {

	width: 48px;

	height: 48px;

	border-radius: 10%;

	border: 1px solid #e5e5e5;

	padding: 3px;

}

.user-img img {

	border-radius: 10%;

}

.msg-count {

	position: absolute;

	left: 36px;

	top: 19px;

	font-size: 11px;

	font-weight: 500;

	width: 17px;

	height: 17px;

	text-align: center;

	border-radius: 50%;

	z-index: 5;

	padding: 0;

	color: #fff;

	background-color: #007bff;

	display: flex;

	justify-content: center;

	align-items: center;

}

.lang i {

	font-size: 18px !important;

}

.lang span {

	font-size: 18px !important;

	padding-left: 6px;

}

.dropdown-menu {

	-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);

	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);

	line-height: initial;

	border: 0px solid #e9ecef;

	margin: 0;

	font-size: 14px;

}

.top-header .navbar .dropdown-menu {

	-webkit-animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown;

	animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown;

}

.top-header .navbar .dropdown-lg .dropdown-menu::after {

	content: '';

	width: 13px;

	height: 13px;

	background: #007bff;

	position: absolute;

	top: -6px;

	right: 28px;

	transform: rotate(45deg);

	border-top: 1px solid #007bff;

	border-left: 1px solid #007bff;

}

.top-header .navbar .dropdown-menu::after {

	content: '';

	width: 13px;

	height: 13px;

	background: #ffff;

	position: absolute;

	top: -6px;

	right: 28px;

	transform: rotate(45deg);

	border-top: 1px solid #ddd;

	border-left: 1px solid #ddd;

}

@-webkit-keyframes animdropdown {

	from {

		-webkit-transform: translate3d(0, 6px, 0);

		transform: translate3d(0, 6px, 0);

		opacity: 0

	}

	to {

		-webkit-transform: translate3d(0, 0, 0);

		transform: translate3d(0, 0, 0);

		opacity: 1

	}

}

@keyframes animdropdown {

	from {

		-webkit-transform: translate3d(0, 6px, 0);

		transform: translate3d(0, 6px, 0);

		opacity: 0

	}

	to {

		-webkit-transform: translate3d(0, 0, 0);

		transform: translate3d(0, 0, 0);

		opacity: 1

	}

}

.dropdown-lg {

	position: relative;

}

.dropdown-lg .dropdown-menu {

	width: 380px;

	padding: 0px;

	margin: 0;

	border: 0;

}

.dropdown-lg .dropdown-menu .dropdown-item {

	padding: .72rem 1.2rem;

	border-bottom: 1px solid #ededed;

}

.dropdown-lg .user-online {

	position: relative;

}

.dropdown-lg .user-online:after {

	content: '';

	position: absolute;

	bottom: 1px;

	right: 17px;

	width: 8px;

	height: 8px;

	border-radius: 50%;

	box-shadow: 0 0 0 2px #fff;

	background: #16e15e;

}

.dropdown-lg .msg-avatar {

	width: 45px;

	height: 45px;

	border-radius: 50%;

	margin-right: 15px;

}

.dropdown-lg .msg-name {

	font-size: 14px;

	margin-bottom: 0;

}

.dropdown-lg .msg-info {

	font-size: 13px;

	margin-bottom: 0;

}

.dropdown-lg .msg-time {

	font-size: 12px;

	margin-bottom: 0;

	color: #919191;

}

.dropdown-lg .msg-header {

	padding: .72rem 1.2rem;

	color: #fff;

	border-bottom: 0px solid #ededed;

	background: #007bff;

	text-align: center;

	border-top-left-radius: .25rem;

	border-top-right-radius: .25rem;

}

.dropdown-lg .msg-header .msg-header-title {

	font-size: 22px;

	margin-bottom: 0;

}

.dropdown-lg .msg-header .msg-header-subtitle {

	font-size: 14px;

	margin-bottom: 0;

}

.dropdown-lg .msg-footer {

	padding: .72rem 1.2rem;

	color: #007bff;

	border-top: 0px solid #ededed;

	background: transparent;

	font-size: 14px;

	font-weight: 500;

	border-bottom-left-radius: .25rem;

	border-bottom-right-radius: .25rem;

}

.dropdown-lg .notify {

	width: 45px;

	height: 45px;

	line-height: 45px;

	font-size: 22px;

	text-align: center;

	border-radius: 50%;

	background-color: #f1f1f1;

	margin-right: 15px;

}

.dropdown-user-profile .dropdown-menu i {

	vertical-align: middle;

	margin-right: 10px;

}

.dropdown-user-profile .dropdown-menu span {

	vertical-align: middle;

}

.dropdown-language .dropdown-menu i {

	vertical-align: middle;

	margin-right: 10px;

	font-size: 14px;

}

.dropdown-language .dropdown-menu span {

	vertical-align: middle;

	font-size: 14px;

}

.header-notifications-list {

	position: relative;

	height: 420px;

}

.header-message-list {

	position: relative;

	height: 420px;

}

/*Page Wrapper*/

.page-wrapper {

	position: relative;

	margin-top: 70px;

}

.sidebar-wrapper {

	width: 260px;

	height: calc(100% - 70px);

	position: fixed;

	top: 70px;

	left: 0;

	bottom: 0;

	z-index: 12;

	background: #ffffff;

	border-right: 0px solid #d2d2d2;

	box-shadow: 0 .3rem 0.6rem rgba(0, 0, 0, .13);

	transition: all .3s ease-out;

}

.page-content-wrapper {

	margin-left: 0px;

	transition: all .3s ease-out;

}

.wrapper.toggled .page-content-wrapper {

	margin-left: 0px;

}

.wrapper.toggled .footer {

	margin-left: 0px;

}

.page-content {

	padding: 30px;

}

/*Pricing Table */

.lis-bg-light {

	background-color: #00000005

}

.lis-light {

	color: #707070

}

.price-table {

	-webkit-transition: 0.3s ease

}

.lis-brd-light {

	border-color: #dadada !important

}

.lis-rounded-top {

	border-top-right-radius: 20px;

	border-top-left-radius: 20px

}

.lis-bg-light {

	background-color: #f7f7f7

}

.lis-latter-spacing-2 {

	letter-spacing: 2px

}

.lis-font-weight-500 {

	font-weight: 500

}

.price-table sup {

	top: -1.5em

}

.price-table sup,

.price-table small {

	font-size: 1.25rem

}

.price-table small {

	font-size: 1.25rem

}

sup {

	position: relative;

	font-size: 75%;

	line-height: 0

}

.lis-brd-light {

	border-color: #dadada !important

}

.lis-line-height-3 {

	line-height: 3 !important

}

.list-unstyled {

	padding-left: 0;

	list-style: none

}

.lis-line-height-3 {

	line-height: 3 !important

}

.lis-rounded-circle-50 {

	border-radius: 50px

}

.lis-bg-primary {

	background-color: #ff214f

}

.price-table.active {

	transform: scale(1.045);

	-webkit-transform: scale(1.045)

}

.price-table {

	-webkit-transition: 0.3s ease

}

.lis-rounded-bottom {

	border-bottom-right-radius: 20px;

	border-bottom-left-radius: 20px

}

/*Metismenu*/

.sidebar-wrapper ul {

	padding: 0;

	margin: 0;

	list-style: none;

	background: transparent;

}

.sidebar-wrapper .metismenu {

	background: transparent;

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;

	padding: 10px;

	-webkit-box-orient: vertical;

	-webkit-box-direction: normal;

	-ms-flex-direction: column;

	flex-direction: column;

}

.sidebar-wrapper .metismenu li+li {

	margin-top: 5px;

}

.sidebar-wrapper .metismenu li:first-child {

	margin-top: 5px;

}

.sidebar-wrapper .metismenu li:last-child {

	margin-bottom: 5px;

}

.sidebar-wrapper .metismenu>li {

	/*-webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;*/

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;

	-webkit-box-orient: vertical;

	-webkit-box-direction: normal;

	-ms-flex-direction: column;

	flex-direction: column;

	position: relative;

}

.sidebar-wrapper .metismenu a {

	position: relative;

	display: flex;

	align-items: center;

	justify-content: left;

	padding: 10px 15px;

	font-size: 15px;

	color: #5f5f5f;

	outline-width: 0;

	text-overflow: ellipsis;

	overflow: hidden;

	letter-spacing: .5px;

	border: 1px solid #ededed;

	transition: all .3s ease-out;

}

.sidebar-wrapper .metismenu a .parent-icon {

	font-size: 24px;

	line-height: 1;

}

.sidebar-wrapper .metismenu a .menu-title {

	margin-left: 10px;

}

.sidebar-wrapper .metismenu ul a {

	padding: 8px 15px 8px 15px;

	font-size: 15px;

	border: 0;

}

.sidebar-wrapper .metismenu ul a i {

	margin-right: 10px;

}

.sidebar-wrapper .metismenu ul {

	border: 1px solid #ededed;

}

.sidebar-wrapper .metismenu ul ul a {

	padding: 8px 15px 8px 30px;

}

.sidebar-wrapper .metismenu ul ul ul a {

	padding: 8px 15px 8px 45px;

}

.sidebar-wrapper .metismenu a:hover,

.sidebar-wrapper .metismenu a:focus,

.sidebar-wrapper .metismenu a:active,

.sidebar-wrapper .metismenu .mm-active>a {

	color: #363636;

	text-decoration: none;

	background: #ededed;

}

.menu-label {

	padding: 20px 15px 5px 5px;

	color: #b0afaf;

	text-transform: uppercase;

	font-size: 12px;

	letter-spacing: 0.5px;

}

.metismenu .has-arrow:after {

	position: absolute;

	content: "";

	width: .50em;

	height: .50em;

	border-style: solid;

	border-width: 1.2px 0 0 1.2px;

	border-color: initial;

	right: 15px;

	transform: rotate(-45deg) translateY(-50%);

	transform-origin: top;

	top: 50%;

	transition: all .3s ease-out;

}

.footer {

	position: fixed;

	bottom: 0;

	left: 0;

	right: 0;

	background: #ffffff;

	border-top: 1px solid #cccbcb;

	padding: 8px 15px;

	font-size: 13px;

	text-align: center;

	margin-left: 0px;

	transition: all .3s ease-out;

	z-index: 10;

}

.p-tab-name {

	display: none !important;

}

@media screen and (min-width: 576px) {

	.p-tab-name {

		display: block !important;

	}

}

@media screen and (min-width: 1025px) {

	.wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper {

		width: 0px;

	}

	.wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu a {

		justify-content: center;

	}

	.wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu .menu-title {

		display: none;

	}

	.wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu li ul {

		display: none;

	}

	.wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu li.menu-label {

		display: none;

	}

	.wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu .has-arrow:after {

		display: none;

	}

	.sidebar-header {

		display: none !important;

	}

	.chat-toggle-btn {

		display: none !important;

	}

	.email-toggle-btn {

		display: none !important;

	}

}

/*Forms */

input::placeholder {

	color: #000 !important;

	opacity: .3 !important

}

.custom-file-label {

	font-size: 1rem;

}

.form-check-label {

	font-size: 1rem;

}

.form-text {

	font-size: 13px;

}

.invalid-feedback {

	font-size: 100%;

	margin-top: 0.55rem;

}

.custom-control-label {

	font-size: 1rem;

}

.select2-container--bootstrap4 .select2-selection--single {

	font-size: 1rem;

}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {

	font-size: 1rem;

}

.select2-sm .select2-container--bootstrap4 .select2-selection {

	height: calc(1.5em + .5rem + 2px);

	font-size: .875rem;

}

.select2-container--bootstrap4 .select2-dropdown {

	border-color: rgba(206, 212, 218, 0.55);

	border-radius: 0;

	box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);

}

.g-r-left .select2-container--bootstrap4 .select2-selection {

	border-top-left-radius: 0!important;

	border-bottom-left-radius: 0!important;

}

.g-r-right .select2-container--bootstrap4 .select2-selection {

	border-top-right-radius: 0!important;

	border-bottom-right-radius: 0!important;

}

.scrollspy-1 {

	height: 200px;

	position: relative;

	overflow-y: scroll;

}

.scrollspy-2 {

	height: 200px;

	position: relative;

	overflow-y: scroll;

}

.chip {

	display: inline-block;

	height: 32px;

	padding: 0 12px;

	margin-right: 1rem;

	margin-bottom: 1rem;

	font-size: 14px;

	font-weight: 500;

	line-height: 32px;

	color: rgba(0, 0, 0, 0.70);

	cursor: pointer;

	background-color: #f1f1f1;

	border: 1px solid rgba(0, 0, 0, 0.15);

	border-radius: 16px;

	-webkit-transition: all .3s linear;

	transition: all .3s linear;

	box-shadow: none;

}

.chip img {

	float: left;

	width: 32px;

	height: 32px;

	margin: 0 8px 0 -12px;

	border-radius: 50%;

}

.chip .closebtn {

	padding-left: 10px;

	font-weight: bold;

	float: right;

	font-size: 16px;

	cursor: pointer;

}

.chip.chip-md {

	height: 42px;

	line-height: 42px;

	border-radius: 21px;

}

.chip.chip-md img {

	height: 42px;

	width: 42px;

}

.chip.chip-lg {

	height: 52px;

	line-height: 52px;

	border-radius: 26px;

}

.chip.chip-lg img {

	height: 52px;

	width: 52px;

}

/*Components */

.card {

	box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);

	border: 1px solid rgba(0, 0, 0, 0);

	margin-bottom: 30px;

}

.card-header {

	background-color: transparent;

	font-size: 16px;

	text-transform: capitalize;

}

.card-group {

	margin-bottom: 30px;

}

.card-group {

	box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);

}

.card-group .card {

	box-shadow: none;

}

.metismenu-card .card-header {

	cursor: pointer;

}

.card-deck .card {

	margin-bottom: 30px;

}

/*Buttons*/

.btn {

	text-transform: capitalize;

}

.btn i {

	vertical-align: middle;

	font-size: 1.3rem;

	margin-top: -1em;

	margin-bottom: -1em;

}

.btn-white {

	color: #212529;

	background-color: #ffffff;

	border-color: #ced4da;

}

.btn-facebook {

	box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14), 0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);

	background-color: #3b5998;

	border-color: #3b5998;

	color: #fff;

}

.btn-facebook:hover {

	color: #fff;

}

.btn-facebook:focus {

	box-shadow: 0 0 0 .2rem rgba(59, 89, 152, 0.5)

}

.btn-twitter {

	box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14), 0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);

	background-color: #55acee;

	border-color: #55acee;

	color: #fff;

}

.btn-twitter:hover {

	color: #fff;

}

.btn-twitter:focus {

	box-shadow: 0 0 0 .2rem rgba(85, 172, 238, 0.5)

}

.btn-linkedin {

	box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14), 0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);

	background-color: #0976b4;

	border-color: #0976b4;

	color: #fff;

}

.btn-linkedin:hover {

	color: #fff;

}

.btn-linkedin:focus {

	box-shadow: 0 0 0 .2rem rgba(9, 118, 180, 0.5)

}

.btn-youtube {

	box-shadow: 0 2px 2px 0 rgba(229, 45, 39, 0.14), 0 3px 1px -2px rgba(229, 45, 39, 0.2), 0 1px 5px 0 rgba(229, 45, 39, 0.12);

	background-color: #e52d27;

	border-color: #e52d27;

	color: #fff;

}

.btn-youtube:hover {

	color: #fff;

}

.btn-youtube:focus {

	box-shadow: 0 0 0 .2rem rgba(229, 45, 39, 0.5)

}

.btn-github {

	box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14), 0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);

	background-color: #333333;

	border-color: #333333;

	color: #fff;

}

.btn-github:hover {

	color: #fff;

}

.btn-github:focus {

	box-shadow: 0 0 0 .2rem rgba(51, 51, 51, 0.5)

}

.btn-skype {

	box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14), 0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);

	background-color: #00aff0;

	border-color: #00aff0;

	color: #fff;

}

.btn-skype:hover {

	color: #fff;

}

.btn-skype:focus {

	box-shadow: 0 0 0 .2rem rgba(0, 175, 240, 0.5)

}

.btn-pinterest {

	box-shadow: 0 2px 2px 0 rgba(204, 33, 39, 0.14), 0 3px 1px -2px rgba(204, 33, 39, 0.2), 0 1px 5px 0 rgba(204, 33, 39, 0.12);

	background-color: #cc2127;

	border-color: #cc2127;

	color: #fff;

}

.btn-pinterest:hover {

	color: #fff;

}

.btn-pinterest:focus {

	box-shadow: 0 0 0 .2rem rgba(204, 33, 39, 0.5)

}

.btn-dribbble {

	box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14), 0 3px 1px -2px rgba(234, 76, 137, 0.2), 0 1px 5px 0 rgba(234, 76, 137, 0.12);

	background-color: #ea4c89;

	border-color: #ea4c89;

	color: #fff;

}

.btn-dribbble:hover {

	color: #fff;

}

.btn-dribbble:focus {

	box-shadow: 0 0 0 .2rem rgba(234, 76, 137, 0.5)

}

.btn-group-round button:first-child {

	border-top-left-radius: 10px;

	border-bottom-left-radius: 10px;

}

.btn-group-round button:last-child {

	border-top-right-radius: 10px;

	border-bottom-right-radius: 10px;

}

/*Background Colors*/

.bg-rose {

	background-color: #ff007c!important;

}

.bg-voilet {

	background-color: #7d00b5!important;

}

.bg-dribbble {

	background-color: #ea4c89!important;

}

.bg-facebook {

	background-color: #3b5998!important;

}

.bg-twitter {

	background-color: #55acee!important;

}

.bg-google {

	background-color: #e52d27!important;

}

.bg-linkedin {

	background-color: #0976b4!important;

}

.bg-body {

	background-color: #f6f6f6!important;

}

.bg-gray {

	background-color: #dee1e6!important;

}

.bg-red-light {

	background-color: #fe6555!important;

}

.bg-primary-blue {

	background-color: #265ed7!important;

}

.bg-dark-blue {

	background-color: #0c1b7a!important;

}

.bg-shine-info {

	background-color: #54dee4!important;

}

.bg-wall {

	background-color: #00cd98!important;

}

.bg-sunset {

	background-color: #ff8b01!important;

}

.bg-light-primary {

	background-color: #d9ebff!important;

}

.bg-light-success {

	background-color: #d6efdc!important;

}

.bg-light-danger {

	background-color: #ffe6e8!important;

}

.bg-light-warning {

	background-color: #fff3d1!important;

}

.bg-light-info {

	background-color: #ccf3f9!important;

}

.bg-light-purple {

	background-color: #dcdcff!important;

}

.bg-light-shineblue {

	background-color: #ccf7ff!important;

}

.bg-light-cyne {

	background-color: #cce8e8!important;

}

.bg-split-primary {

	background-color: #0270e6;

	border-color: #0462c7;

}

.bg-light-sinata {

	background-color: #f7cbff!important;

}

.bg-light-mehandi {

	background: #f0ffb8!important;

}

.bg-gradient-danger {

	background-image: linear-gradient(to left, #ff758c 0%, #ff7eb3 100%);

}

.bg-gradient-voilet {

	background-image: linear-gradient(to left, #a18cd1 0%, #fbc2eb 100%);

}

.bg-gradient-success {

	background-image: linear-gradient(to left, #05ac9d 0%, #20cfbf 100%);

}

.bg-gradient-mehandi {

	background-image: linear-gradient(to right, #dbe287, #cac531);

}

/*Text Colors*/

.text-facebook {

	color: #3b5998!important;

}

.text-twitter {

	color: #55acee!important;

}

.text-skype {

	color: #00aff0!important;

}

.text-purple {

	color: #8280ff!important;

}

.text-shineblue {

	color: #01d7ff!important;

}

.text-cyne {

	color: #008b8b!important;

}

.text-primary-blue {

	color: #265ed7!important;

}

.text-dark-blue {

	color: #0c1b7a!important;

}

.text-shine-info {

	color: #54dee4!important;

}

.text-grey {

	color: #dee1e6!important;

}

.text-sinata {

	color: #a52bbb!important;

}

.text-mehandi {

	color: #839838!important;

}

/*Authentication*/

.section-authentication {

	height: 100%;

	padding-top: 1.0rem;

	padding-bottom: 1.0rem

}

.authentication-forgot {

	height: 100vh;

	padding: 0 1rem;

}

.forgot-box {

	width: 27rem;

	border-radius: 1.25rem;

}

.authentication-reset-password {

	height: 100vh;

	padding: 0 1rem;

}

.authentication-lock-screen {

	height: 100vh;

	padding: 0 1rem;

}

.error-404 {

	height: 100vh;

	padding: 0 1rem;

}

.login-card {

	border-right: 1px solid #dee2e6!important;

}

.error-social a {

	display: inline-block;

	width: 40px;

	height: 40px;

	line-height: 40px;

	font-size: 18px;

	color: #fff;

	text-align: center;

	border-radius: 50%;

	margin: 5px;

	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;

}

.bg-forgot {

	background-image: url(../images/login-images/4087092.jpg);

	background-size: cover;

	background-position: center;

	background-repeat: no-repeat;

}

.bg-lock-screen {

	background-image: url(../images/login-images/lock-screen-img.jpg);

	background-size: cover;

	background-position: center;

	background-repeat: no-repeat;

}

/*File Manager*/

.fm-wrapper {

	position: relative;

}

.fm-left-sidebar {

	background: #ffffff;

	width: 220px;

	height: 100%;

	position: fixed;

	padding: 10px;

	overflow-x: hidden;

	overflow-y: scroll;

}

.fm-body {

	background: white;

	width: 100%;

	height: 100%;

}

.fm-menu .list-group a {

	font-size: 16px;

	color: #5f5f5f;

	display: flex;

	align-items: center;

}

.fm-menu .list-group a i {

	font-size: 23px;

}

.fm-menu .list-group a:hover {

	background: #007bff;

	color: #ffffff;

	transition: all .2s ease-out;

}

.fm-icon-box {

	font-size: 32px;

	background: #ffffff;

	width: 52px;

	height: 52px;

	display: flex;

	align-items: center;

	justify-content: center;

	border-radius: .25rem;

}

.fm-file-box {

	font-size: 25px;

	background: #e9ecef;

	width: 44px;

	height: 44px;

	display: flex;

	align-items: center;

	justify-content: center;

	border-radius: .25rem;

}

.user-groups img {

	margin-left: -14px;

	border: 1px solid #e4e4e4;

	padding: 2px;

	cursor: pointer;

}

.user-plus {

	width: 33px;

	height: 33px;

	margin-left: -14px;

	line-height: 33px;

	background: #ffffff;

	border-radius: 50%;

	text-align: center;

	font-size: 22px;

	cursor: pointer;

	border: 1px dotted #a9b2bb;

	color: #404142;

}

/*Tables*/

.table-responsive {

	white-space: nowrap;

}

.table td,

.table th {

	vertical-align: middle;

	color: #6d757b;

}

/*Invoice Page*/

#invoice {

	padding: 0px;

}

.invoice {

	position: relative;

	background-color: transparent;

	min-height: 680px;

	padding: 15px

}

.invoice header {

	padding: 10px 0;

	margin-bottom: 20px;

	border-bottom: 1px solid #007bff

}

.invoice .company-details {

	text-align: right

}

.invoice .company-details .name {

	margin-top: 0;

	margin-bottom: 0

}

.invoice .contacts {

	margin-bottom: 20px

}

.invoice .invoice-to {

	text-align: left

}

.invoice .invoice-to .to {

	margin-top: 0;

	margin-bottom: 0

}

.invoice .invoice-details {

	text-align: right

}

.invoice .invoice-details .invoice-id {

	margin-top: 0;

	color: #007bff

}

.invoice main {

	padding-bottom: 50px

}

.invoice main .thanks {

	margin-top: -100px;

	font-size: 2em;

	margin-bottom: 50px

}

.invoice main .notices {

	padding-left: 6px;

	border-left: 6px solid #007bff;

	background: #e7f2ff;

	padding: 10px;

}

.invoice main .notices .notice {

	font-size: 1.2em

}

.invoice table {

	width: 100%;

	border-collapse: collapse;

	border-spacing: 0;

	margin-bottom: 20px

}

.invoice table td,

.invoice table th {

	padding: 15px;

	background: #eee;

	border-bottom: 1px solid #fff

}

.invoice table th {

	white-space: nowrap;

	font-weight: 400;

	font-size: 16px

}

.invoice table td h3 {

	margin: 0;

	font-weight: 400;

	color: #007bff;

	font-size: 1.2em

}

.invoice table .qty,

.invoice table .total,

.invoice table .unit {

	text-align: right;

	font-size: 1.2em

}

.invoice table .no {

	color: #fff;

	font-size: 1.6em;

	background: #007bff

}

.invoice table .unit {

	background: #ddd

}

.invoice table .total {

	background: #007bff;

	color: #fff

}

.invoice table tbody tr:last-child td {

	border: none

}

.invoice table tfoot td {

	background: 0 0;

	border-bottom: none;

	white-space: nowrap;

	text-align: right;

	padding: 10px 20px;

	font-size: 1.2em;

	border-top: 1px solid #aaa

}

.invoice table tfoot tr:first-child td {

	border-top: none

}

.invoice table tfoot tr:last-child td {

	color: #007bff;

	font-size: 1.4em;

	border-top: 1px solid #007bff

}

.invoice table tfoot tr td:first-child {

	border: none

}

.invoice footer {

	width: 100%;

	text-align: center;

	color: #777;

	border-top: 1px solid #aaa;

	padding: 8px 0

}

@media print {

	.invoice {

		font-size: 11px!important;

		overflow: hidden!important

	}

	.invoice footer {

		position: absolute;

		bottom: 10px;

		page-break-after: always

	}

	.invoice>div:last-child {

		page-break-before: always

	}

}

.main-row {

	height: 100vh;

}

.main-col {

	max-width: 500px;

	min-height: 300px;

}

.todo-done {

	text-decoration: line-through;

}

/*Chat box*/

.chat-wrapper {

	width: auto;

	height: 600px;

	border-radius: 0.25rem;

	position: relative;

	background: #ffffff;

	box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);

}

.chat-sidebar {

	width: 340px;

	height: 100%;

	position: absolute;

	background: #f8f9fa;

	left: 0;

	top: 0;

	bottom: 0;

	z-index: 2;

	overflow: hidden;

	border-right: 1px solid rgba(0, 0, 0, .125);

	border-top-left-radius: 0.25rem;

	border-bottom-left-radius: 0.25rem;

}

.chat-sidebar-header {

	width: auto;

	height: auto;

	position: relative;

	background: #ffffff;

	border-bottom: 1px solid rgba(0, 0, 0, .125);

	border-right: 0px solid rgba(0, 0, 0, .125);

	border-top-left-radius: 0.25rem;

	padding: 15px;

}

.chat-sidebar-content {

	padding: 0px;

}

.chat-user-online {

	position: relative;

}

.chat-sidebar-header .chat-user-online:before {

	content: '';

	position: absolute;

	bottom: 7px;

	left: 40px;

	width: 8px;

	height: 8px;

	border-radius: 50%;

	box-shadow: 0 0 0 2px #fff;

	background: #16e15e;

}

.chat-list .chat-user-online:before {

	content: '';

	position: absolute;

	bottom: 7px;

	left: 36px;

	width: 8px;

	height: 8px;

	border-radius: 50%;

	box-shadow: 0 0 0 2px #fff;

	background: #16e15e;

}

.chat-content {

	margin-left: 340px;

	padding: 85px 15px 15px 15px;

}

.chat-header {

	position: absolute;

	height: 70px;

	left: 340px;

	right: 0;

	top: 0;

	padding: 15px;

	background: #f8f9fa;

	border-bottom: 1px solid rgba(0, 0, 0, .125);

	border-top-right-radius: 0.25rem;

	z-index: 1;

}

.chat-footer {

	position: absolute;

	height: 70px;

	left: 340px;

	right: 0;

	bottom: 0;

	padding: 15px;

	background: #f8f9fa;

	border-top: 1px solid rgba(0, 0, 0, .125);

	border-bottom-right-radius: 0.25rem;

}

.chat-footer-menu a {

	display: inline-block;

	width: 40px;

	height: 40px;

	line-height: 40px;

	font-size: 18px;

	color: #6c757d;

	text-align: center;

	border-radius: 50%;

	margin: 3px;

	background-color: white;

	border: 1px solid rgb(0 0 0 / 15%);

}

.chat-tab-menu li a.nav-link {

	padding: .3rem 0.2rem;

	line-height: 1.2;

	color: #4a4b4c;

}

.chat-tab-menu .nav-pills .nav-link.active,

.chat-tab-menu .nav-pills .show>.nav-link {

	color: #007bff;

	background-color: rgb(0 123 255 / 0%);

}

.chat-title {

	font-size: 16px;

	color: #272b2f;

}

.chat-msg {

	font-size: 14px;

	color: #6c757d;

}

.chat-time {

	font-size: 13px;

	color: #6c757d;

}

.chat-list {

	position: relative;

	height: 300px;

}

.chat-list .list-group-item {

	border: 1px solid rgb(0 0 0 / 0%);

	background-color: transparent;

}

.chat-list .list-group-item:hover {

	border: 1px solid rgb(0 0 0 / 0%);

	background-color: rgb(0 123 255 / 15%);

}

.chat-list .list-group-item.active {

	background-color: rgb(0 123 255 / 15%);

}

.chart-online {

	color: #16e15e;

}

.chat-top-header-menu a {

	display: inline-block;

	width: 40px;

	height: 40px;

	line-height: 40px;

	font-size: 18px;

	color: #6c757d;

	text-align: center;

	border-radius: 50%;

	margin: 3px;

	background-color: white;

	border: 1px solid rgb(0 0 0 / 15%);

}

.chat-content {

	position: relative;

	width: auto;

	height: 520px;

}

.chat-content-leftside .chat-left-msg {

	width: fit-content;

	background-color: #eff2f5;

	padding: 0.80rem;

	border-radius: 12px;

	max-width: 480px;

	text-align: left;

	border-top-left-radius: 0;

}

.chat-content-rightside .chat-right-msg {

	width: fit-content;

	background-color: #dcedff;

	padding: 0.80rem;

	border-radius: 12px;

	float: right;

	max-width: 480px;

	text-align: left;

	border-bottom-right-radius: 0;

}

.chat-toggle-btn {

	width: 40px;

	height: 40px;

	line-height: 40px;

	margin-right: 15px;

	text-align: center;

	font-size: 24px;

	color: #6c757d;

	border-radius: 50%;

	cursor: pointer;

	background-color: white;

	border: 1px solid rgb(0 0 0 / 15%);

}

/*Email box*/

.email-wrapper {

	width: auto;

	height: 600px;

	overflow: hidden;

	border-radius: 0.25rem;

	position: relative;

	background: #ffffff;

	box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);

}

.email-sidebar {

	width: 250px;

	height: 100%;

	position: absolute;

	background: #fff;

	left: 0;

	top: 0;

	bottom: 0;

	z-index: 2;

	overflow: hidden;

	border-right: 1px solid rgba(0, 0, 0, .125);

	border-top-left-radius: 0.25rem;

	border-bottom-left-radius: 0.25rem;

}

.email-sidebar-header {

	width: auto;

	height: auto;

	position: relative;

	background: #ffffff;

	border-bottom: 1px solid rgba(0, 0, 0, .125);

	border-right: 0px solid rgba(0, 0, 0, .125);

	border-top-left-radius: 0.25rem;

	padding: 15px;

}

.email-navigation {

	position: relative;

	padding: 0px;

	height: 330px;

	border-bottom: 1px solid rgba(0, 0, 0, .125);

}

.email-header {

	position: absolute;

	height: 70px;

	left: 250px;

	right: 0;

	top: 0;

	padding: 15px;

	background: #ffffff;

	border-bottom: 1px solid rgba(0, 0, 0, .125);

	border-top-right-radius: 0.25rem;

	z-index: 1;

}

.email-content {

	position: absolute;

	left: 0;

	right: 0;

	width: auto;

	top: 70px;

	height: auto;

	margin-left: 250px;

	padding: 0;

	background: #ffffff;

	border-top-left-radius: 0.25rem;

	border-top-right-radius: 0.25rem;

}

.email-navigation a.list-group-item {

	color: #404142;

	padding: .15rem 1.25rem;

	background-color: white;

	border-bottom: 1px solid rgb(0 0 0 / 0%);

	transition: all .3s ease-out;

}

.email-navigation a.list-group-item:hover {

	background-color: rgb(0 123 255 / 15%);

}

.email-navigation a.list-group-item.active {

	color: #007bff;

	font-weight: 600;

	background-color: rgb(0 123 255 / 15%);

}

.email-meeting {

	position: absolute;

	left: 0;

	right: 0;

	bottom: 0;

}

.email-meeting a.list-group-item {

	color: #404142;

	padding: .15rem 1.25rem;

	background-color: white;

	border-bottom: 1px solid rgb(0 0 0 / 0%);

}

.email-meeting a.list-group-item:hover {

	background-color: rgb(0 123 255 / 15%);

	transition: all .3s ease-out;

}

.email-hangout .chat-user-online:before {

	content: '';

	position: absolute;

	bottom: 7px;

	left: 37px;

	width: 8px;

	height: 8px;

	border-radius: 50%;

	box-shadow: 0 0 0 2px #fff;

	background: #16e15e;

}

.email-toggle-btn {

	width: auto;

	height: auto;

	margin-right: 10px;

	text-align: center;

	font-size: 24px;

	color: #404142;

	border-radius: 0;

	cursor: pointer;

	background-color: transparent;

	border: 0px solid rgb(0 0 0 / 15%);

}

.email-actions {

	width: 230px;

	;

}

.email-time {

	font-size: 13px;

	color: #6c757d;

}

.email-list div.email-message {

	background: #ffffff;

	border-bottom: 1px solid rgb(0 0 0 / 8%);

	color: #383a3c;

}

.email-list div.email-message:hover {

	transition: all .2s ease-out;

	background-color: #eceef1;

}

.email-list {

	position: relative;

	height: 530px;

}

.email-star {

	color: #6c757d;

}

.email-read-box {

	position: relative;

	height: 530px;

}

/*Compose Mail*/

.compose-mail-popup {

	width: 42%;

	position: fixed;

	bottom: -30px;

	right: 30px;

	z-index: 15;

	display: none;

}

.compose-mail-toggled {

	display: block;

}

.compose-mail-title {

	font-size: 16px;

}

.compose-mail-close {

	width: 25px;

	height: 25px;

	line-height: 25px;

	text-align: center;

	font-size: 14px;

	border-radius: 2px;

	background-color: rgb(255 255 255 / 0%);

}

.compose-mail-close:hover {

	background-color: rgb(255 255 255 / 20%);

}

/*Extra Css*/

.contacts-social a {

	font-size: 16px;

	width: 26px;

	height: 26px;

	line-height: 26px;

	background: #f7f5f5;

	text-align: center;

	border-radius: 0.25rem;

}

.iconFilterTypes {

	display: flex;

	justify-content: space-between;

	margin-bottom: 16px;

}

.iconFilterTypes .tab.active,

.iconFilterTypes .tab:hover {

	font-weight: 700;

	color: #756af8;

}

.iconFilterTypes .tab {

	color: #000;

	font-weight: 700;

	display: inline-block;

	cursor: pointer;

}

.list {

	position: relative;

}

ul.icons {

	list-style: none;

}

ul.icons li {

	position: relative;

}

ul.icons li {

	cursor: pointer;

	padding: 10px;

	width: 204px;

	float: left;

	box-shadow: 0 1px 5px #e3e7eb;

	margin: 13px;

	-webkit-border-radius: 4px;

	-moz-border-radius: 4px;

	border-radius: 4px;

	background: #f8f9fb;

	text-overflow: ellipsis;

	white-space: nowrap;

	-webkit-transition: all .1s linear;

	-moz-transition: all .1s linear;

	-o-transition: all .1s linear;

	transition: all .1s linear;

}

ul.icons li i {

	color: #007bff;

	width: 30px;

	height: 30px;

	font-size: 30px;

	text-align: center;

	display: block;

	float: left;

	line-height: 34px;

	margin-left: 5px;

}

ul.icons li:hover {

	background: #eef1f7;

}

ul.icons li span {

	text-indent: 15px;

	display: block;

	line-height: 32px;

	overflow: hidden;

	text-overflow: ellipsis;

}

.pByfF {

	animation-fill-mode: forwards;

	cursor: pointer;

	display: inline-block;

	flex: 0 0 10%;

	transition: all 0.15s ease 0s;

	border-radius: 8px;

	overflow: hidden;

	outline: none !important;

	background: #f8f9fb;

	box-shadow: 0 1px 5px #e3e7eb;

	width: 122px;

	height: 122px;

	margin: 7px 7px;

	padding: 10px 14px;

}

.pByfF.active .icon-box-inner {

	background: rgb(238, 238, 238);

}

.pByfF .icon-box-inner {

	font-weight: 700;

	position: relative;

	font-size: 16px;

	line-height: 35px;

	text-align: center;

	pointer-events: none;

	margin: 1px;

	border-radius: 8px;

	background: transparent;

	padding: 18px 10px;

}

.pByfF i {

	color: #007bff;

	font-size: 36px;

}

.pByfF .icon-box-inner .icon-box-name {

	font-size: 12px;

	text-transform: capitalize;

	user-select: none;

	color: #a5a5a5;

	font-weight: 400;

	text-overflow: ellipsis;

	white-space: nowrap;

	line-height: 15px;

	overflow: hidden;

	margin: 5px auto 2px;

}

.pByfF:hover {

	background: #eaecf1;

}

.media-icons {

	font-size: 54px;

	width: 60px;

	height: 60px;

	line-height: 60px;

	text-align: center;

	color: #fff;

	border-radius: 50%;

}

.dashboard-icons {

	width: 55px;

	height: 55px;

	display: flex;

	align-items: center;

	justify-content: center;

	background-color: #ededed;

	font-size: 42px;

	border-radius: 10px;

}

.widgets-icons {

	width: 50px;

	height: 50px;

	display: flex;

	align-items: center;

	justify-content: center;

	background-color: #ededed;

	font-size: 26px;

	border-radius: 10px;

}

.icon-box {

	padding: 10px 14px;

	background: #ffffff;

	box-shadow: 0 1px 5px #e3e7eb;

}

.icon-symbol i {

	font-size: 30px;

	color: #007bff;

}

.icon-name {} .p-10 {

	padding: 10px;

}

.p-15 {

	padding: 15px;

}

.font-small {

	font-size: 50%;

}

.font-13 {

	font-size: 13px;

}

.font-14 {

	font-size: 14px;

}

.font-20 {

	font-size: 20px;

}

.font-24 {

	font-size: 24px;

}

.font-30 {

	font-size: 30px;

}

.font-60 {

	font-size: 60px;

}

.radius-10 {

	border-radius: 10px;

}

.radius-30 {

	border-radius: 30px;

}

.cursor-pointer {

	cursor: pointer;

}

.chart-container1 {

	position: relative;

	height: 340px;

}

.chart-container2 {

	position: relative;

	height: 400px;

}

.chart-container3 {

	position: relative;

	width: 200px;

	height: 80px;

	right: -1.25rem;

	top: -1.0rem;

}

.gmaps,

.gmaps-panaroma {

	height: 400px;

	background: #eeeeee;

	border-radius: 3px;

}

.product-img {

	width: 45px;

	height: 45px;

	display: flex;

	align-items: center;

	justify-content: center;

	background: #f3f3f3;

	border-radius: 10px;

}

.page-breadcrumb .breadcrumb {

	background-color: transparent;

}

.breadcrumb-title {

	font-size: 20px;

	border-right: 1.5px solid #aaa4a4;

}

.page-breadcrumb .breadcrumb li a {} .page-breadcrumb .breadcrumb li.breadcrumb-item {

	font-size: 16px;

}

.page-breadcrumb .breadcrumb-item+.breadcrumb-item::before {

	display: inline-block;

	padding-right: .5rem;

	color: #6c757d;

	font-family: 'LineIcons';

	content: "\ea5c";

}

.vertical-align-middle {

	vertical-align: middle;

}

.back-to-top {

	display: none;

	width: 40px;

	height: 40px;

	line-height: 40px;

	text-align: center;

	font-size: 26px;

	color: white;

	position: fixed;

	border-radius: 10px;

	bottom: 20px;

	right: 12px;

	background-color: #007bff;

	z-index: 10000;

}

.back-to-top:hover {

	color: white;

	background-color: #000;

	transition: all .5s;

}

/*Switcher*/

.switcher-wrapper{
	width: 240px;
    height: 100%;
    position: fixed;
    right: -240px;
    top: 0;
    bottom: 0;
    z-index: 16;
    background: #fff;
    border-left: 0px solid #d2d2d2;
    box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, .13);
    transition: all .2s ease-out;
}
.switcher-btn{
	width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
    background: #007bff;
	box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, .13);
    color: #fff;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 40%;
    right: 100%;
	cursor: pointer;
}
.switcher-wrapper.switcher-toggled{
	right: 0px;
}
.switcher-body{
	padding:1.25rem;
}


/*Responsive*/

@media screen and (max-width: 1280px) {

	.left-topbar {

		width: auto;

	}

	.search-bar {

		padding: 0rem 1.4rem;

	}

	.email-header {

		height: auto;

	}

	.email-content {

		padding: 100px 0px 0px 0px;

	}

}

@media screen and (max-width: 1024px) {

	.search-bar .btn-search-back {

		background: #ffffff;

		border: 1px solid #ffffff;

		padding: 0 10px;

	}

	.search-arrow-back {

		display: flex;

		margin-right: 0px;

	}

	.search-btn-mobile {

		display: flex;

	}

	.search-bar {

		display: none;

	}

	.full-search-bar {

		display: flex;

		align-items: center;

		width: 100%;

		position: absolute;

		left: 0;

		z-index: 100;

		background: #fff;

		height: 70px;

		padding: 0rem 1.4rem;

	}

	.sidebar-wrapper {

		width: 260px!important;

		height: 100%;

		top: 0px;

		left: -260px;

		box-shadow: none;

		z-index: 12;

	}

	.page-content-wrapper {

		margin-left: 0px;

	}

	.wrapper.toggled .sidebar-wrapper {

		left: 0px;

		box-shadow: 0 .3rem 0.6rem rgba(0, 0, 0, .13);

		transition: all .2s ease-out;

		z-index: 16;

	}

	.wrapper.toggled .page-content-wrapper {

		margin-left: 0px;

	}

	.wrapper.toggled .overlay {

		position: fixed;

		top: 0;

		right: 0;

		bottom: 0;

		left: 0;

		background: #000;

		opacity: 0.6;

		z-index: 15;

		display: block;

		cursor: move;

		transition: all .2s ease-out;

	}

	.footer {

		margin-left: 0px;

	}

	.wrapper.toggled .footer {

		margin-left: 0px;

	}

	.chat-header {

		border-top-left-radius: 0.25rem;

	}

	.chat-footer {

		border-bottom-left-radius: 0.25rem;

	}

	.chat-sidebar {

		left: -370px;

	}

	.chat-content {

		margin-left: 0px;

	}

	.chat-header {

		left: 0px;

	}

	.chat-footer {

		left: 0px;

	}

	/* chat toggled css */

	.chat-toggled .chat-sidebar {

		left: 0px;

	}

	.chat-toggled .overlay {

		position: absolute;

		top: 0;

		right: 0;

		bottom: 0;

		left: 340px;

		background: #000;

		opacity: 0.5;

		z-index: 11;

		display: block;

		cursor: move;

		transition: all .3s ease-out;

	}

	.email-header {

		border-top-left-radius: 0.25rem;

	}

	.email-sidebar {

		left: -280px;

	}

	.email-content {

		margin-left: 0px;

	}

	.email-header {

		left: 0px;

	}

	/* email toggled */

	.email-toggled .email-sidebar {

		left: 0px;

	}

	.email-toggled .overlay {

		position: absolute;

		top: 0;

		right: 0;

		bottom: 0;

		left: 250px;

		background: #000;

		opacity: 0.5;

		z-index: 9;

		display: block;

		cursor: move;

		transition: all .3s ease-out;

	}

}

@media screen and (max-width: 991px) {

	.login-card {

		border-right: 0px solid #dee2e6!important;

		border-bottom: 1px solid #dee2e6!important;

	}

	.authentication-reset-password {

		height: auto;

		padding: 2.0rem 1rem;

	}

	.authentication-lock-screen {

		height: auto;

		padding: 2.0rem 1rem;

	}

	.error-404 {

		height: auto;

		padding: 6.0rem 1rem;

	}

	.compose-mail-popup {

		width: auto;

		position: fixed;

		bottom: -30px;

		right: 0;

		left: 0;

	}

}

@media screen and (max-width: 767px) {

	.user-info {

		display: none;

	}

	.lang span {

		display: none;

	}

	.right-topbar .navbar-nav .nav-link {

		padding: 0rem 14px;

		border-left: 0px solid #ededed;

		font-size: 25px;

	}

	.msg-count {

		left: 26px;

	}

	.search-bar .btn-search {

		padding: 0 20px;

	}

	.authentication-forgot {

		height: auto;

		padding: 2.5rem 1rem;

	}

	.fc .fc-toolbar {

		display: flow-root;

	}

}

@media screen and (max-width: 620px) {

	.top-header .navbar .dropdown-menu::after {

		display: none;

	}

	.top-header .navbar .dropdown {

		position: static !important;

	}

	.top-header .navbar .dropdown-menu {

		width: 100% !important;

	}

	.dropdown-lg-content {

		max-height: 350px;

		overflow-y: scroll;

	}

}

@media screen and (max-width: 520px) {

	.logo-icon {

		display: none;

	}

	.chat-footer-menu,

	.chat-top-header-menu {

		display: none;

	}

}