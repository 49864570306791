
/*
Template Name: Synadmin
Author: codervent
Email: codervent@gmail.com
File: app.css
*/

/* boxicons font */

@font-face {
    font-family: 'boxicons';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/boxicons.svg');
    src: url('../fonts/boxicons.svg') format('embedded-opentype'), url('../fonts/boxicons.woff2') format('woff2'), url('../fonts/boxicons.woff') format('woff'), url('../fonts/boxicons.ttf') format('truetype'), url('../fonts/boxicons.svg?#boxicons') format('svg')
}
.bx {
    font-family: 'boxicons'!important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: inherit;
    display: inline-block;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
.bx-ul {
    margin-left: 2em;
    padding-left: 0;
    list-style: none
}
.bx-ul>li {
    position: relative
}
.bx-ul .bx {
    font-size: inherit;
    line-height: inherit;
    position: absolute;
    left: -2em;
    width: 2em;
    text-align: center
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}
@-webkit-keyframes burst {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    90% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}
@keyframes burst {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    90% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}
@-webkit-keyframes flashing {
    0% {
        opacity: 1
    }
    45% {
        opacity: 0
    }
    90% {
        opacity: 1
    }
}
@keyframes flashing {
    0% {
        opacity: 1
    }
    45% {
        opacity: 0
    }
    90% {
        opacity: 1
    }
}
@-webkit-keyframes fade-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0
    }
}
@keyframes fade-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0
    }
}
@-webkit-keyframes fade-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0
    }
}
@keyframes fade-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0
    }
}
@-webkit-keyframes fade-up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0
    }
}
@keyframes fade-up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0
    }
}
@-webkit-keyframes fade-down {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}
@keyframes fade-down {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}
@-webkit-keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    10%, 20% {
        -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
        transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg)
    }
    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg)
    }
    40%, 60%, 80% {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg)
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}
@keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    10%, 20% {
        -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
        transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg)
    }
    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg)
    }
    40%, 60%, 80% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg)
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}
.bx-spin {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
}
.bx-spin-hover:hover {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
}
.bx-tada {
    -webkit-animation: tada 1.5s ease infinite;
    animation: tada 1.5s ease infinite
}
.bx-tada-hover:hover {
    -webkit-animation: tada 1.5s ease infinite;
    animation: tada 1.5s ease infinite
}
.bx-flashing {
    -webkit-animation: flashing 1.5s infinite linear;
    animation: flashing 1.5s infinite linear
}
.bx-flashing-hover:hover {
    -webkit-animation: flashing 1.5s infinite linear;
    animation: flashing 1.5s infinite linear
}
.bx-burst {
    -webkit-animation: burst 1.5s infinite linear;
    animation: burst 1.5s infinite linear
}
.bx-burst-hover:hover {
    -webkit-animation: burst 1.5s infinite linear;
    animation: burst 1.5s infinite linear
}
.bx-fade-up {
    -webkit-animation: fade-up 1.5s infinite linear;
    animation: fade-up 1.5s infinite linear
}
.bx-fade-up-hover:hover {
    -webkit-animation: fade-up 1.5s infinite linear;
    animation: fade-up 1.5s infinite linear
}
.bx-fade-down {
    -webkit-animation: fade-down 1.5s infinite linear;
    animation: fade-down 1.5s infinite linear
}
.bx-fade-down-hover:hover {
    -webkit-animation: fade-down 1.5s infinite linear;
    animation: fade-down 1.5s infinite linear
}
.bx-fade-left {
    -webkit-animation: fade-left 1.5s infinite linear;
    animation: fade-left 1.5s infinite linear
}
.bx-fade-left-hover:hover {
    -webkit-animation: fade-left 1.5s infinite linear;
    animation: fade-left 1.5s infinite linear
}
.bx-fade-right {
    -webkit-animation: fade-right 1.5s infinite linear;
    animation: fade-right 1.5s infinite linear
}
.bx-fade-right-hover:hover {
    -webkit-animation: fade-right 1.5s infinite linear;
    animation: fade-right 1.5s infinite linear
}
.bx-xs {
    font-size: 1rem!important
}
.bx-sm {
    font-size: 1.55rem!important
}
.bx-md {
    font-size: 2.25rem!important
}
.bx-fw {
    font-size: 1.2857142857em;
    line-height: .8em;
    width: 1.2857142857em;
    height: .8em;
    margin-top: -.2em!important;
    vertical-align: middle
}
.bx-lg {
    font-size: 3.0!important
}
.bx-pull-left {
    float: left;
    margin-right: .3em!important
}
.bx-pull-right {
    float: right;
    margin-left: .3em!important
}
.bx-rotate-90 {
    transform: rotate(90deg);
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=1)'
}
.bx-rotate-180 {
    transform: rotate(180deg);
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)'
}
.bx-rotate-270 {
    transform: rotate(270deg);
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)'
}
.bx-flip-horizontal {
    transform: scaleX(-1);
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0,mirror=1)'
}
.bx-flip-vertical {
    transform: scaleY(-1);
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2,mirror=1)'
}
.bx-border {
    padding: .25em;
    border: .07em solid rgba(0, 0, 0, .1);
    border-radius: .25em
}
.bx-border-circle {
    padding: .25em;
    border: .07em solid rgba(0, 0, 0, .1);
    border-radius: 50%
}
.bxl-500px:before {
    content: "\e900"
}
.bxl-adobe:before {
    content: "\e901"
}
.bxl-airbnb:before {
    content: "\e902"
}
.bxl-algolia:before {
    content: "\e903"
}
.bxl-amazon:before {
    content: "\e904"
}
.bxl-android:before {
    content: "\e905"
}
.bxl-angular:before {
    content: "\e906"
}
.bxl-apple:before {
    content: "\e907"
}
.bxl-audible:before {
    content: "\e908"
}
.bxl-baidu:before {
    content: "\e909"
}
.bxl-behance:before {
    content: "\e90a"
}
.bxl-bing:before {
    content: "\e90b"
}
.bxl-bitcoin:before {
    content: "\e90c"
}
.bxl-blogger:before {
    content: "\e90d"
}
.bxl-bootstrap:before {
    content: "\e90e"
}
.bxl-chrome:before {
    content: "\e90f"
}
.bxl-codepen:before {
    content: "\e910"
}
.bxl-creative-commons:before {
    content: "\e911"
}
.bxl-css3:before {
    content: "\e912"
}
.bxl-dailymotion:before {
    content: "\e913"
}
.bxl-deviantart:before {
    content: "\e914"
}
.bxl-dev-to:before {
    content: "\e915"
}
.bxl-digg:before {
    content: "\e916"
}
.bxl-digitalocean:before {
    content: "\e917"
}
.bxl-discord:before {
    content: "\e918"
}
.bxl-discourse:before {
    content: "\e919"
}
.bxl-dribbble:before {
    content: "\e91a"
}
.bxl-dropbox:before {
    content: "\e91b"
}
.bxl-drupal:before {
    content: "\e91c"
}
.bxl-ebay:before {
    content: "\e91d"
}
.bxl-edge:before {
    content: "\e91e"
}
.bxl-etsy:before {
    content: "\e91f"
}
.bxl-facebook:before {
    content: "\e920"
}
.bxl-facebook-circle:before {
    content: "\e921"
}
.bxl-facebook-square:before {
    content: "\e922"
}
.bxl-figma:before {
    content: "\e923"
}
.bxl-firefox:before {
    content: "\e924"
}
.bxl-flickr:before {
    content: "\e925"
}
.bxl-flickr-square:before {
    content: "\e926"
}
.bxl-foursquare:before {
    content: "\e927"
}
.bxl-git:before {
    content: "\e928"
}
.bxl-github:before {
    content: "\e929"
}
.bxl-gitlab:before {
    content: "\e92a"
}
.bxl-google:before {
    content: "\e92b"
}
.bxl-google-plus:before {
    content: "\e92c"
}
.bxl-google-plus-circle:before {
    content: "\e92d"
}
.bxl-html5:before {
    content: "\e92e"
}
.bxl-imdb:before {
    content: "\e92f"
}
.bxl-instagram:before {
    content: "\e930"
}
.bxl-instagram-alt:before {
    content: "\e931"
}
.bxl-internet-explorer:before {
    content: "\e932"
}
.bxl-invision:before {
    content: "\e933"
}
.bxl-javascript:before {
    content: "\e934"
}
.bxl-joomla:before {
    content: "\e935"
}
.bxl-jquery:before {
    content: "\e936"
}
.bxl-jsfiddle:before {
    content: "\e937"
}
.bxl-kickstarter:before {
    content: "\e938"
}
.bxl-less:before {
    content: "\e939"
}
.bxl-linkedin:before {
    content: "\e93a"
}
.bxl-linkedin-square:before {
    content: "\e93b"
}
.bxl-magento:before {
    content: "\e93c"
}
.bxl-mailchimp:before {
    content: "\e93d"
}
.bxl-mastercard:before {
    content: "\e93e"
}
.bxl-medium:before {
    content: "\e93f"
}
.bxl-medium-old:before {
    content: "\e940"
}
.bxl-medium-square:before {
    content: "\e941"
}
.bxl-messenger:before {
    content: "\e942"
}
.bxl-microsoft:before {
    content: "\e943"
}
.bxl-nodejs:before {
    content: "\e944"
}
.bxl-opera:before {
    content: "\e945"
}
.bxl-patreon:before {
    content: "\e946"
}
.bxl-paypal:before {
    content: "\e947"
}
.bxl-periscope:before {
    content: "\e948"
}
.bxl-pinterest:before {
    content: "\e949"
}
.bxl-pinterest-alt:before {
    content: "\e94a"
}
.bxl-play-store:before {
    content: "\e94b"
}
.bxl-pocket:before {
    content: "\e94c"
}
.bxl-product-hunt:before {
    content: "\e94d"
}
.bxl-quora:before {
    content: "\e94e"
}
.bxl-react:before {
    content: "\e94f"
}
.bxl-redbubble:before {
    content: "\e950"
}
.bxl-reddit:before {
    content: "\e951"
}
.bxl-redux:before {
    content: "\e952"
}
.bxl-sass:before {
    content: "\e953"
}
.bxl-shopify:before {
    content: "\e954"
}
.bxl-skype:before {
    content: "\e955"
}
.bxl-slack:before {
    content: "\e956"
}
.bxl-slack-old:before {
    content: "\e957"
}
.bxl-snapchat:before {
    content: "\e958"
}
.bxl-soundcloud:before {
    content: "\e959"
}
.bxl-spotify:before {
    content: "\e95a"
}
.bxl-squarespace:before {
    content: "\e95b"
}
.bxl-stack-overflow:before {
    content: "\e95c"
}
.bxl-stripe:before {
    content: "\e95d"
}
.bxl-telegram:before {
    content: "\e95e"
}
.bxl-trello:before {
    content: "\e95f"
}
.bxl-tumblr:before {
    content: "\e960"
}
.bxl-twitch:before {
    content: "\e961"
}
.bxl-twitter:before {
    content: "\e962"
}
.bxl-unsplash:before {
    content: "\e963"
}
.bxl-vimeo:before {
    content: "\e964"
}
.bxl-visa:before {
    content: "\e965"
}
.bxl-vk:before {
    content: "\e966"
}
.bxl-vuejs:before {
    content: "\e967"
}
.bxl-whatsapp:before {
    content: "\e968"
}
.bxl-whatsapp-square:before {
    content: "\e969"
}
.bxl-wikipedia:before {
    content: "\e96a"
}
.bxl-windows:before {
    content: "\e96b"
}
.bxl-wix:before {
    content: "\e96c"
}
.bxl-wordpress:before {
    content: "\e96d"
}
.bxl-yahoo:before {
    content: "\e96e"
}
.bxl-yelp:before {
    content: "\e96f"
}
.bxl-youtube:before {
    content: "\e970"
}
.bx-abacus:before {
    content: "\e971"
}
.bx-accessibility:before {
    content: "\e972"
}
.bx-add-to-queue:before {
    content: "\e973"
}
.bx-adjust:before {
    content: "\e974"
}
.bx-alarm:before {
    content: "\e975"
}
.bx-alarm-add:before {
    content: "\e976"
}
.bx-alarm-exclamation:before {
    content: "\e977"
}
.bx-alarm-off:before {
    content: "\e978"
}
.bx-alarm-snooze:before {
    content: "\e979"
}
.bx-album:before {
    content: "\e97a"
}
.bx-align-justify:before {
    content: "\e97b"
}
.bx-align-left:before {
    content: "\e97c"
}
.bx-align-middle:before {
    content: "\e97d"
}
.bx-align-right:before {
    content: "\e97e"
}
.bx-analyse:before {
    content: "\e97f"
}
.bx-anchor:before {
    content: "\e980"
}
.bx-angry:before {
    content: "\e981"
}
.bx-aperture:before {
    content: "\e982"
}
.bx-arch:before {
    content: "\e983"
}
.bx-archive:before {
    content: "\e984"
}
.bx-archive-in:before {
    content: "\e985"
}
.bx-archive-out:before {
    content: "\e986"
}
.bx-area:before {
    content: "\e987"
}
.bx-arrow-back:before {
    content: "\e988"
}
.bx-arrow-from-bottom:before {
    content: "\e989"
}
.bx-arrow-from-left:before {
    content: "\e98a"
}
.bx-arrow-from-right:before {
    content: "\e98b"
}
.bx-arrow-from-top:before {
    content: "\e98c"
}
.bx-arrow-to-bottom:before {
    content: "\e98d"
}
.bx-arrow-to-left:before {
    content: "\e98e"
}
.bx-arrow-to-right:before {
    content: "\e98f"
}
.bx-arrow-to-top:before {
    content: "\e990"
}
.bx-at:before {
    content: "\e991"
}
.bx-atom:before {
    content: "\e992"
}
.bx-award:before {
    content: "\e993"
}
.bx-badge:before {
    content: "\e994"
}
.bx-badge-check:before {
    content: "\e995"
}
.bx-ball:before {
    content: "\e996"
}
.bx-band-aid:before {
    content: "\e997"
}
.bx-bar-chart:before {
    content: "\e998"
}
.bx-bar-chart-alt:before {
    content: "\e999"
}
.bx-bar-chart-alt-2:before {
    content: "\e99a"
}
.bx-bar-chart-square:before {
    content: "\e99b"
}
.bx-barcode:before {
    content: "\e99c"
}
.bx-barcode-reader:before {
    content: "\e99d"
}
.bx-baseball:before {
    content: "\e99e"
}
.bx-basket:before {
    content: "\e99f"
}
.bx-basketball:before {
    content: "\e9a0"
}
.bx-bath:before {
    content: "\e9a1"
}
.bx-battery:before {
    content: "\e9a2"
}
.bx-bed:before {
    content: "\e9a3"
}
.bx-been-here:before {
    content: "\e9a4"
}
.bx-beer:before {
    content: "\e9a5"
}
.bx-bell:before {
    content: "\e9a6"
}
.bx-bell-minus:before {
    content: "\e9a7"
}
.bx-bell-off:before {
    content: "\e9a8"
}
.bx-bell-plus:before {
    content: "\e9a9"
}
.bx-bible:before {
    content: "\e9aa"
}
.bx-bitcoin:before {
    content: "\e9ab"
}
.bx-blanket:before {
    content: "\e9ac"
}
.bx-block:before {
    content: "\e9ad"
}
.bx-bluetooth:before {
    content: "\e9ae"
}
.bx-body:before {
    content: "\e9af"
}
.bx-bold:before {
    content: "\e9b0"
}
.bx-bolt-circle:before {
    content: "\e9b1"
}
.bx-bomb:before {
    content: "\e9b2"
}
.bx-bone:before {
    content: "\e9b3"
}
.bx-bong:before {
    content: "\e9b4"
}
.bx-book:before {
    content: "\e9b5"
}
.bx-book-add:before {
    content: "\e9b6"
}
.bx-book-alt:before {
    content: "\e9b7"
}
.bx-book-bookmark:before {
    content: "\e9b8"
}
.bx-book-content:before {
    content: "\e9b9"
}
.bx-book-heart:before {
    content: "\e9ba"
}
.bx-bookmark:before {
    content: "\e9bb"
}
.bx-bookmark-minus:before {
    content: "\e9bc"
}
.bx-bookmark-plus:before {
    content: "\e9bd"
}
.bx-bookmarks:before {
    content: "\e9be"
}
.bx-book-open:before {
    content: "\e9bf"
}
.bx-book-reader:before {
    content: "\e9c0"
}
.bx-border-all:before {
    content: "\e9c1"
}
.bx-border-bottom:before {
    content: "\e9c2"
}
.bx-border-inner:before {
    content: "\e9c3"
}
.bx-border-left:before {
    content: "\e9c4"
}
.bx-border-none:before {
    content: "\e9c5"
}
.bx-border-outer:before {
    content: "\e9c6"
}
.bx-border-radius:before {
    content: "\e9c7"
}
.bx-border-right:before {
    content: "\e9c8"
}
.bx-border-top:before {
    content: "\e9c9"
}
.bx-bot:before {
    content: "\e9ca"
}
.bx-bowling-ball:before {
    content: "\e9cb"
}
.bx-box:before {
    content: "\e9cc"
}
.bx-bracket:before {
    content: "\e9cd"
}
.bx-braille:before {
    content: "\e9ce"
}
.bx-brain:before {
    content: "\e9cf"
}
.bx-briefcase:before {
    content: "\e9d0"
}
.bx-briefcase-alt:before {
    content: "\e9d1"
}
.bx-briefcase-alt-2:before {
    content: "\e9d2"
}
.bx-brightness:before {
    content: "\e9d3"
}
.bx-brightness-half:before {
    content: "\e9d4"
}
.bx-broadcast:before {
    content: "\e9d5"
}
.bx-brush:before {
    content: "\e9d6"
}
.bx-brush-alt:before {
    content: "\e9d7"
}
.bx-bug:before {
    content: "\e9d8"
}
.bx-bug-alt:before {
    content: "\e9d9"
}
.bx-building:before {
    content: "\e9da"
}
.bx-building-house:before {
    content: "\e9db"
}
.bx-buildings:before {
    content: "\e9dc"
}
.bx-bulb:before {
    content: "\e9dd"
}
.bx-bullseye:before {
    content: "\e9de"
}
.bx-buoy:before {
    content: "\e9df"
}
.bx-bus:before {
    content: "\e9e0"
}
.bx-bus-school:before {
    content: "\e9e1"
}
.bx-cabinet:before {
    content: "\e9e2"
}
.bx-cake:before {
    content: "\e9e3"
}
.bx-calculator:before {
    content: "\e9e4"
}
.bx-calendar:before {
    content: "\e9e5"
}
.bx-calendar-alt:before {
    content: "\e9e6"
}
.bx-calendar-check:before {
    content: "\e9e7"
}
.bx-calendar-edit:before {
    content: "\e9e8"
}
.bx-calendar-event:before {
    content: "\e9e9"
}
.bx-calendar-exclamation:before {
    content: "\e9ea"
}
.bx-calendar-heart:before {
    content: "\e9eb"
}
.bx-calendar-minus:before {
    content: "\e9ec"
}
.bx-calendar-plus:before {
    content: "\e9ed"
}
.bx-calendar-star:before {
    content: "\e9ee"
}
.bx-calendar-week:before {
    content: "\e9ef"
}
.bx-calendar-x:before {
    content: "\e9f0"
}
.bx-camera:before {
    content: "\e9f1"
}
.bx-camera-home:before {
    content: "\e9f2"
}
.bx-camera-movie:before {
    content: "\e9f3"
}
.bx-camera-off:before {
    content: "\e9f4"
}
.bx-capsule:before {
    content: "\e9f5"
}
.bx-captions:before {
    content: "\e9f6"
}
.bx-car:before {
    content: "\e9f7"
}
.bx-card:before {
    content: "\e9f8"
}
.bx-caret-down:before {
    content: "\e9f9"
}
.bx-caret-down-circle:before {
    content: "\e9fa"
}
.bx-caret-down-square:before {
    content: "\e9fb"
}
.bx-caret-left:before {
    content: "\e9fc"
}
.bx-caret-left-circle:before {
    content: "\e9fd"
}
.bx-caret-left-square:before {
    content: "\e9fe"
}
.bx-caret-right:before {
    content: "\e9ff"
}
.bx-caret-right-circle:before {
    content: "\ea00"
}
.bx-caret-right-square:before {
    content: "\ea01"
}
.bx-caret-up:before {
    content: "\ea02"
}
.bx-caret-up-circle:before {
    content: "\ea03"
}
.bx-caret-up-square:before {
    content: "\ea04"
}
.bx-carousel:before {
    content: "\ea05"
}
.bx-cart:before {
    content: "\ea06"
}
.bx-cart-alt:before {
    content: "\ea07"
}
.bx-cast:before {
    content: "\ea08"
}
.bx-cctv:before {
    content: "\ea09"
}
.bx-certification:before {
    content: "\ea0a"
}
.bx-chair:before {
    content: "\ea0b"
}
.bx-chalkboard:before {
    content: "\ea0c"
}
.bx-chart:before {
    content: "\ea0d"
}
.bx-chat:before {
    content: "\ea0e"
}
.bx-check:before {
    content: "\ea0f"
}
.bx-checkbox:before {
    content: "\ea10"
}
.bx-checkbox-checked:before {
    content: "\ea11"
}
.bx-checkbox-square:before {
    content: "\ea12"
}
.bx-check-circle:before {
    content: "\ea13"
}
.bx-check-double:before {
    content: "\ea14"
}
.bx-check-shield:before {
    content: "\ea15"
}
.bx-check-square:before {
    content: "\ea16"
}
.bx-chevron-down:before {
    content: "\ea17"
}
.bx-chevron-down-circle:before {
    content: "\ea18"
}
.bx-chevron-down-square:before {
    content: "\ea19"
}
.bx-chevron-left:before {
    content: "\ea1a"
}
.bx-chevron-left-circle:before {
    content: "\ea1b"
}
.bx-chevron-left-square:before {
    content: "\ea1c"
}
.bx-chevron-right:before {
    content: "\ea1d"
}
.bx-chevron-right-circle:before {
    content: "\ea1e"
}
.bx-chevron-right-square:before {
    content: "\ea1f"
}
.bx-chevrons-down:before {
    content: "\ea20"
}
.bx-chevrons-left:before {
    content: "\ea21"
}
.bx-chevrons-right:before {
    content: "\ea22"
}
.bx-chevrons-up:before {
    content: "\ea23"
}
.bx-chevron-up:before {
    content: "\ea24"
}
.bx-chevron-up-circle:before {
    content: "\ea25"
}
.bx-chevron-up-square:before {
    content: "\ea26"
}
.bx-chip:before {
    content: "\ea27"
}
.bx-church:before {
    content: "\ea28"
}
.bx-circle:before {
    content: "\ea29"
}
.bx-clinic:before {
    content: "\ea2a"
}
.bx-clipboard:before {
    content: "\ea2b"
}
.bx-closet:before {
    content: "\ea2c"
}
.bx-cloud:before {
    content: "\ea2d"
}
.bx-cloud-download:before {
    content: "\ea2e"
}
.bx-cloud-drizzle:before {
    content: "\ea2f"
}
.bx-cloud-lightning:before {
    content: "\ea30"
}
.bx-cloud-light-rain:before {
    content: "\ea31"
}
.bx-cloud-rain:before {
    content: "\ea32"
}
.bx-cloud-snow:before {
    content: "\ea33"
}
.bx-cloud-upload:before {
    content: "\ea34"
}
.bx-code:before {
    content: "\ea35"
}
.bx-code-alt:before {
    content: "\ea36"
}
.bx-code-block:before {
    content: "\ea37"
}
.bx-code-curly:before {
    content: "\ea38"
}
.bx-coffee:before {
    content: "\ea39"
}
.bx-coffee-togo:before {
    content: "\ea3a"
}
.bx-cog:before {
    content: "\ea3b"
}
.bx-coin:before {
    content: "\ea3c"
}
.bx-coin-stack:before {
    content: "\ea3d"
}
.bx-collapse:before {
    content: "\ea3e"
}
.bx-collection:before {
    content: "\ea3f"
}
.bx-color-fill:before {
    content: "\ea40"
}
.bx-columns:before {
    content: "\ea41"
}
.bx-command:before {
    content: "\ea42"
}
.bx-comment:before {
    content: "\ea43"
}
.bx-comment-add:before {
    content: "\ea44"
}
.bx-comment-check:before {
    content: "\ea45"
}
.bx-comment-detail:before {
    content: "\ea46"
}
.bx-comment-dots:before {
    content: "\ea47"
}
.bx-comment-edit:before {
    content: "\ea48"
}
.bx-comment-error:before {
    content: "\ea49"
}
.bx-comment-minus:before {
    content: "\ea4a"
}
.bx-comment-x:before {
    content: "\ea4b"
}
.bx-compass:before {
    content: "\ea4c"
}
.bx-confused:before {
    content: "\ea4d"
}
.bx-conversation:before {
    content: "\ea4e"
}
.bx-cookie:before {
    content: "\ea4f"
}
.bx-cool:before {
    content: "\ea50"
}
.bx-copy:before {
    content: "\ea51"
}
.bx-copy-alt:before {
    content: "\ea52"
}
.bx-copyright:before {
    content: "\ea53"
}
.bx-credit-card:before {
    content: "\ea54"
}
.bx-credit-card-alt:before {
    content: "\ea55"
}
.bx-credit-card-front:before {
    content: "\ea56"
}
.bx-crop:before {
    content: "\ea57"
}
.bx-crosshair:before {
    content: "\ea58"
}
.bx-crown:before {
    content: "\ea59"
}
.bx-cube:before {
    content: "\ea5a"
}
.bx-cube-alt:before {
    content: "\ea5b"
}
.bx-cuboid:before {
    content: "\ea5c"
}
.bx-current-location:before {
    content: "\ea5d"
}
.bx-customize:before {
    content: "\ea5e"
}
.bx-cut:before {
    content: "\ea5f"
}
.bx-cycling:before {
    content: "\ea60"
}
.bx-cylinder:before {
    content: "\ea61"
}
.bx-data:before {
    content: "\ea62"
}
.bx-desktop:before {
    content: "\ea63"
}
.bx-detail:before {
    content: "\ea64"
}
.bx-devices:before {
    content: "\ea65"
}
.bx-dialpad:before {
    content: "\ea66"
}
.bx-dialpad-alt:before {
    content: "\ea67"
}
.bx-diamond:before {
    content: "\ea68"
}
.bx-dice-1:before {
    content: "\ea69"
}
.bx-dice-2:before {
    content: "\ea6a"
}
.bx-dice-3:before {
    content: "\ea6b"
}
.bx-dice-4:before {
    content: "\ea6c"
}
.bx-dice-5:before {
    content: "\ea6d"
}
.bx-dice-6:before {
    content: "\ea6e"
}
.bx-directions:before {
    content: "\ea6f"
}
.bx-disc:before {
    content: "\ea70"
}
.bx-dish:before {
    content: "\ea71"
}
.bx-dislike:before {
    content: "\ea72"
}
.bx-dizzy:before {
    content: "\ea73"
}
.bx-dna:before {
    content: "\ea74"
}
.bx-dock-bottom:before {
    content: "\ea75"
}
.bx-dock-left:before {
    content: "\ea76"
}
.bx-dock-right:before {
    content: "\ea77"
}
.bx-dock-top:before {
    content: "\ea78"
}
.bx-dollar:before {
    content: "\ea79"
}
.bx-dollar-circle:before {
    content: "\ea7a"
}
.bx-donate-blood:before {
    content: "\ea7b"
}
.bx-donate-heart:before {
    content: "\ea7c"
}
.bx-door-open:before {
    content: "\ea7d"
}
.bx-dots-horizontal:before {
    content: "\ea7e"
}
.bx-dots-horizontal-rounded:before {
    content: "\ea7f"
}
.bx-dots-vertical:before {
    content: "\ea80"
}
.bx-dots-vertical-rounded:before {
    content: "\ea81"
}
.bx-doughnut-chart:before {
    content: "\ea82"
}
.bx-down-arrow:before {
    content: "\ea83"
}
.bx-down-arrow-alt:before {
    content: "\ea84"
}
.bx-down-arrow-circle:before {
    content: "\ea85"
}
.bx-download:before {
    content: "\ea86"
}
.bx-downvote:before {
    content: "\ea87"
}
.bx-drink:before {
    content: "\ea88"
}
.bx-droplet:before {
    content: "\ea89"
}
.bx-dumbbell:before {
    content: "\ea8a"
}
.bx-duplicate:before {
    content: "\ea8b"
}
.bx-edit:before {
    content: "\ea8c"
}
.bx-edit-alt:before {
    content: "\ea8d"
}
.bx-envelope:before {
    content: "\ea8e"
}
.bx-envelope-open:before {
    content: "\ea8f"
}
.bx-equalizer:before {
    content: "\ea90"
}
.bx-eraser:before {
    content: "\ea91"
}
.bx-error:before {
    content: "\ea92"
}
.bx-error-alt:before {
    content: "\ea93"
}
.bx-error-circle:before {
    content: "\ea94"
}
.bx-euro:before {
    content: "\ea95"
}
.bx-exclude:before {
    content: "\ea96"
}
.bx-exit:before {
    content: "\ea97"
}
.bx-exit-fullscreen:before {
    content: "\ea98"
}
.bx-expand:before {
    content: "\ea99"
}
.bx-export:before {
    content: "\ea9a"
}
.bx-extension:before {
    content: "\ea9b"
}
.bx-face:before {
    content: "\ea9c"
}
.bx-fast-forward:before {
    content: "\ea9d"
}
.bx-fast-forward-circle:before {
    content: "\ea9e"
}
.bx-female:before {
    content: "\ea9f"
}
.bx-female-sign:before {
    content: "\eaa0"
}
.bx-file:before {
    content: "\eaa1"
}
.bx-file-blank:before {
    content: "\eaa2"
}
.bx-file-find:before {
    content: "\eaa3"
}
.bx-film:before {
    content: "\eaa4"
}
.bx-filter:before {
    content: "\eaa5"
}
.bx-filter-alt:before {
    content: "\eaa6"
}
.bx-fingerprint:before {
    content: "\eaa7"
}
.bx-first-aid:before {
    content: "\eaa8"
}
.bx-first-page:before {
    content: "\eaa9"
}
.bx-flag:before {
    content: "\eaaa"
}
.bx-folder:before {
    content: "\eaab"
}
.bx-folder-minus:before {
    content: "\eaac"
}
.bx-folder-open:before {
    content: "\eaad"
}
.bx-folder-plus:before {
    content: "\eaae"
}
.bx-font:before {
    content: "\eaaf"
}
.bx-font-color:before {
    content: "\eab0"
}
.bx-font-family:before {
    content: "\eab1"
}
.bx-font-size:before {
    content: "\eab2"
}
.bx-food-menu:before {
    content: "\eab3"
}
.bx-food-tag:before {
    content: "\eab4"
}
.bx-football:before {
    content: "\eab5"
}
.bx-fridge:before {
    content: "\eab6"
}
.bx-fullscreen:before {
    content: "\eab7"
}
.bx-game:before {
    content: "\eab8"
}
.bx-gas-pump:before {
    content: "\eab9"
}
.bx-ghost:before {
    content: "\eaba"
}
.bx-gift:before {
    content: "\eabb"
}
.bx-git-branch:before {
    content: "\eabc"
}
.bx-git-commit:before {
    content: "\eabd"
}
.bx-git-compare:before {
    content: "\eabe"
}
.bx-git-merge:before {
    content: "\eabf"
}
.bx-git-pull-request:before {
    content: "\eac0"
}
.bx-git-repo-forked:before {
    content: "\eac1"
}
.bx-glasses:before {
    content: "\eac2"
}
.bx-glasses-alt:before {
    content: "\eac3"
}
.bx-globe:before {
    content: "\eac4"
}
.bx-globe-alt:before {
    content: "\eac5"
}
.bx-grid:before {
    content: "\eac6"
}
.bx-grid-alt:before {
    content: "\eac7"
}
.bx-grid-horizontal:before {
    content: "\eac8"
}
.bx-grid-small:before {
    content: "\eac9"
}
.bx-grid-vertical:before {
    content: "\eaca"
}
.bx-group:before {
    content: "\eacb"
}
.bx-handicap:before {
    content: "\eacc"
}
.bx-happy:before {
    content: "\eacd"
}
.bx-happy-alt:before {
    content: "\eace"
}
.bx-happy-beaming:before {
    content: "\eacf"
}
.bx-happy-heart-eyes:before {
    content: "\ead0"
}
.bx-hash:before {
    content: "\ead1"
}
.bx-hdd:before {
    content: "\ead2"
}
.bx-heading:before {
    content: "\ead3"
}
.bx-headphone:before {
    content: "\ead4"
}
.bx-health:before {
    content: "\ead5"
}
.bx-heart:before {
    content: "\ead6"
}
.bx-heart-circle:before {
    content: "\ead7"
}
.bx-heart-square:before {
    content: "\ead8"
}
.bx-help-circle:before {
    content: "\ead9"
}
.bx-hide:before {
    content: "\eada"
}
.bx-highlight:before {
    content: "\eadb"
}
.bx-history:before {
    content: "\eadc"
}
.bx-hive:before {
    content: "\eadd"
}
.bx-home:before {
    content: "\eade"
}
.bx-home-alt:before {
    content: "\eadf"
}
.bx-home-circle:before {
    content: "\eae0"
}
.bx-home-heart:before {
    content: "\eae1"
}
.bx-home-smile:before {
    content: "\eae2"
}
.bx-horizontal-center:before {
    content: "\eae3"
}
.bx-hotel:before {
    content: "\eae4"
}
.bx-hourglass:before {
    content: "\eae5"
}
.bx-id-card:before {
    content: "\eae6"
}
.bx-image:before {
    content: "\eae7"
}
.bx-image-add:before {
    content: "\eae8"
}
.bx-image-alt:before {
    content: "\eae9"
}
.bx-images:before {
    content: "\eaea"
}
.bx-import:before {
    content: "\eaeb"
}
.bx-infinite:before {
    content: "\eaec"
}
.bx-info-circle:before {
    content: "\eaed"
}
.bx-info-square:before {
    content: "\eaee"
}
.bx-intersect:before {
    content: "\eaef"
}
.bx-italic:before {
    content: "\eaf0"
}
.bx-joystick:before {
    content: "\eaf1"
}
.bx-joystick-alt:before {
    content: "\eaf2"
}
.bx-joystick-button:before {
    content: "\eaf3"
}
.bx-key:before {
    content: "\eaf4"
}
.bx-label:before {
    content: "\eaf5"
}
.bx-landscape:before {
    content: "\eaf6"
}
.bx-laptop:before {
    content: "\eaf7"
}
.bx-last-page:before {
    content: "\eaf8"
}
.bx-laugh:before {
    content: "\eaf9"
}
.bx-layer:before {
    content: "\eafa"
}
.bx-layer-minus:before {
    content: "\eafb"
}
.bx-layer-plus:before {
    content: "\eafc"
}
.bx-layout:before {
    content: "\eafd"
}
.bx-left-arrow:before {
    content: "\eafe"
}
.bx-left-arrow-alt:before {
    content: "\eaff"
}
.bx-left-arrow-circle:before {
    content: "\eb00"
}
.bx-left-down-arrow-circle:before {
    content: "\eb01"
}
.bx-left-indent:before {
    content: "\eb02"
}
.bx-left-top-arrow-circle:before {
    content: "\eb03"
}
.bx-like:before {
    content: "\eb04"
}
.bx-line-chart:before {
    content: "\eb05"
}
.bx-line-chart-down:before {
    content: "\eb06"
}
.bx-link:before {
    content: "\eb07"
}
.bx-link-alt:before {
    content: "\eb08"
}
.bx-link-external:before {
    content: "\eb09"
}
.bx-lira:before {
    content: "\eb0a"
}
.bx-list-check:before {
    content: "\eb0b"
}
.bx-list-minus:before {
    content: "\eb0c"
}
.bx-list-ol:before {
    content: "\eb0d"
}
.bx-list-plus:before {
    content: "\eb0e"
}
.bx-list-ul:before {
    content: "\eb0f"
}
.bx-loader:before {
    content: "\eb10"
}
.bx-loader-alt:before {
    content: "\eb11"
}
.bx-loader-circle:before {
    content: "\eb12"
}
.bx-location-plus:before {
    content: "\eb13"
}
.bx-lock:before {
    content: "\eb14"
}
.bx-lock-alt:before {
    content: "\eb15"
}
.bx-lock-open:before {
    content: "\eb16"
}
.bx-lock-open-alt:before {
    content: "\eb17"
}
.bx-log-in:before {
    content: "\eb18"
}
.bx-log-in-circle:before {
    content: "\eb19"
}
.bx-log-out:before {
    content: "\eb1a"
}
.bx-log-out-circle:before {
    content: "\eb1b"
}
.bx-low-vision:before {
    content: "\eb1c"
}
.bx-magnet:before {
    content: "\eb1d"
}
.bx-mail-send:before {
    content: "\eb1e"
}
.bx-male:before {
    content: "\eb1f"
}
.bx-male-sign:before {
    content: "\eb20"
}
.bx-map:before {
    content: "\eb21"
}
.bx-map-alt:before {
    content: "\eb22"
}
.bx-map-pin:before {
    content: "\eb23"
}
.bx-mask:before {
    content: "\eb24"
}
.bx-medal:before {
    content: "\eb25"
}
.bx-meh:before {
    content: "\eb26"
}
.bx-meh-alt:before {
    content: "\eb27"
}
.bx-meh-blank:before {
    content: "\eb28"
}
.bx-memory-card:before {
    content: "\eb29"
}
.bx-menu:before {
    content: "\eb2a"
}
.bx-menu-alt-left:before {
    content: "\eb2b"
}
.bx-menu-alt-right:before {
    content: "\eb2c"
}
.bx-merge:before {
    content: "\eb2d"
}
.bx-message:before {
    content: "\eb2e"
}
.bx-message-add:before {
    content: "\eb2f"
}
.bx-message-alt:before {
    content: "\eb30"
}
.bx-message-alt-add:before {
    content: "\eb31"
}
.bx-message-alt-check:before {
    content: "\eb32"
}
.bx-message-alt-detail:before {
    content: "\eb33"
}
.bx-message-alt-dots:before {
    content: "\eb34"
}
.bx-message-alt-edit:before {
    content: "\eb35"
}
.bx-message-alt-error:before {
    content: "\eb36"
}
.bx-message-alt-minus:before {
    content: "\eb37"
}
.bx-message-alt-x:before {
    content: "\eb38"
}
.bx-message-check:before {
    content: "\eb39"
}
.bx-message-detail:before {
    content: "\eb3a"
}
.bx-message-dots:before {
    content: "\eb3b"
}
.bx-message-edit:before {
    content: "\eb3c"
}
.bx-message-error:before {
    content: "\eb3d"
}
.bx-message-minus:before {
    content: "\eb3e"
}
.bx-message-rounded:before {
    content: "\eb3f"
}
.bx-message-rounded-add:before {
    content: "\eb40"
}
.bx-message-rounded-check:before {
    content: "\eb41"
}
.bx-message-rounded-detail:before {
    content: "\eb42"
}
.bx-message-rounded-dots:before {
    content: "\eb43"
}
.bx-message-rounded-edit:before {
    content: "\eb44"
}
.bx-message-rounded-error:before {
    content: "\eb45"
}
.bx-message-rounded-minus:before {
    content: "\eb46"
}
.bx-message-rounded-x:before {
    content: "\eb47"
}
.bx-message-square:before {
    content: "\eb48"
}
.bx-message-square-add:before {
    content: "\eb49"
}
.bx-message-square-check:before {
    content: "\eb4a"
}
.bx-message-square-detail:before {
    content: "\eb4b"
}
.bx-message-square-dots:before {
    content: "\eb4c"
}
.bx-message-square-edit:before {
    content: "\eb4d"
}
.bx-message-square-error:before {
    content: "\eb4e"
}
.bx-message-square-minus:before {
    content: "\eb4f"
}
.bx-message-square-x:before {
    content: "\eb50"
}
.bx-message-x:before {
    content: "\eb51"
}
.bx-meteor:before {
    content: "\eb52"
}
.bx-microchip:before {
    content: "\eb53"
}
.bx-microphone:before {
    content: "\eb54"
}
.bx-microphone-off:before {
    content: "\eb55"
}
.bx-minus:before {
    content: "\eb56"
}
.bx-minus-back:before {
    content: "\eb57"
}
.bx-minus-circle:before {
    content: "\eb58"
}
.bx-minus-front:before {
    content: "\eb59"
}
.bx-mobile:before {
    content: "\eb5a"
}
.bx-mobile-alt:before {
    content: "\eb5b"
}
.bx-mobile-landscape:before {
    content: "\eb5c"
}
.bx-mobile-vibration:before {
    content: "\eb5d"
}
.bx-money:before {
    content: "\eb5e"
}
.bx-moon:before {
    content: "\eb5f"
}
.bx-mouse:before {
    content: "\eb60"
}
.bx-mouse-alt:before {
    content: "\eb61"
}
.bx-move:before {
    content: "\eb62"
}
.bx-move-horizontal:before {
    content: "\eb63"
}
.bx-move-vertical:before {
    content: "\eb64"
}
.bx-movie:before {
    content: "\eb65"
}
.bx-music:before {
    content: "\eb66"
}
.bx-navigation:before {
    content: "\eb67"
}
.bx-network-chart:before {
    content: "\eb68"
}
.bx-news:before {
    content: "\eb69"
}
.bx-no-entry:before {
    content: "\eb6a"
}
.bx-note:before {
    content: "\eb6b"
}
.bx-notepad:before {
    content: "\eb6c"
}
.bx-notification:before {
    content: "\eb6d"
}
.bx-notification-off:before {
    content: "\eb6e"
}
.bx-outline:before {
    content: "\eb6f"
}
.bx-package:before {
    content: "\eb70"
}
.bx-paint:before {
    content: "\eb71"
}
.bx-paint-roll:before {
    content: "\eb72"
}
.bx-palette:before {
    content: "\eb73"
}
.bx-paperclip:before {
    content: "\eb74"
}
.bx-paper-plane:before {
    content: "\eb75"
}
.bx-paragraph:before {
    content: "\eb76"
}
.bx-paste:before {
    content: "\eb77"
}
.bx-pause:before {
    content: "\eb78"
}
.bx-pause-circle:before {
    content: "\eb79"
}
.bx-pen:before {
    content: "\eb7a"
}
.bx-pencil:before {
    content: "\eb7b"
}
.bx-phone:before {
    content: "\eb7c"
}
.bx-phone-call:before {
    content: "\eb7d"
}
.bx-phone-incoming:before {
    content: "\eb7e"
}
.bx-phone-outgoing:before {
    content: "\eb7f"
}
.bx-photo-album:before {
    content: "\eb80"
}
.bx-pie-chart:before {
    content: "\eb81"
}
.bx-pie-chart-alt:before {
    content: "\eb82"
}
.bx-pie-chart-alt-2:before {
    content: "\eb83"
}
.bx-pin:before {
    content: "\eb84"
}
.bx-planet:before {
    content: "\eb85"
}
.bx-play:before {
    content: "\eb86"
}
.bx-play-circle:before {
    content: "\eb87"
}
.bx-plug:before {
    content: "\eb88"
}
.bx-plus:before {
    content: "\eb89"
}
.bx-plus-circle:before {
    content: "\eb8a"
}
.bx-plus-medical:before {
    content: "\eb8b"
}
.bx-pointer:before {
    content: "\eb8c"
}
.bx-poll:before {
    content: "\eb8d"
}
.bx-polygon:before {
    content: "\eb8e"
}
.bx-pound:before {
    content: "\eb8f"
}
.bx-power-off:before {
    content: "\eb90"
}
.bx-printer:before {
    content: "\eb91"
}
.bx-pulse:before {
    content: "\eb92"
}
.bx-purchase-tag:before {
    content: "\eb93"
}
.bx-purchase-tag-alt:before {
    content: "\eb94"
}
.bx-pyramid:before {
    content: "\eb95"
}
.bx-question-mark:before {
    content: "\eb96"
}
.bx-radar:before {
    content: "\eb97"
}
.bx-radio:before {
    content: "\eb98"
}
.bx-radio-circle:before {
    content: "\eb99"
}
.bx-radio-circle-marked:before {
    content: "\eb9a"
}
.bx-receipt:before {
    content: "\eb9b"
}
.bx-rectangle:before {
    content: "\eb9c"
}
.bx-recycle:before {
    content: "\eb9d"
}
.bx-redo:before {
    content: "\eb9e"
}
.bx-refresh:before {
    content: "\eb9f"
}
.bx-rename:before {
    content: "\eba0"
}
.bx-repeat:before {
    content: "\eba1"
}
.bx-reply:before {
    content: "\eba2"
}
.bx-reply-all:before {
    content: "\eba3"
}
.bx-repost:before {
    content: "\eba4"
}
.bx-reset:before {
    content: "\eba5"
}
.bx-restaurant:before {
    content: "\eba6"
}
.bx-revision:before {
    content: "\eba7"
}
.bx-rewind:before {
    content: "\eba8"
}
.bx-rewind-circle:before {
    content: "\eba9"
}
.bx-right-arrow:before {
    content: "\ebaa"
}
.bx-right-arrow-alt:before {
    content: "\ebab"
}
.bx-right-arrow-circle:before {
    content: "\ebac"
}
.bx-right-down-arrow-circle:before {
    content: "\ebad"
}
.bx-right-indent:before {
    content: "\ebae"
}
.bx-right-top-arrow-circle:before {
    content: "\ebaf"
}
.bx-rocket:before {
    content: "\ebb0"
}
.bx-rotate-left:before {
    content: "\ebb1"
}
.bx-rotate-right:before {
    content: "\ebb2"
}
.bx-rss:before {
    content: "\ebb3"
}
.bx-ruble:before {
    content: "\ebb4"
}
.bx-ruler:before {
    content: "\ebb5"
}
.bx-run:before {
    content: "\ebb6"
}
.bx-rupee:before {
    content: "\ebb7"
}
.bx-sad:before {
    content: "\ebb8"
}
.bx-save:before {
    content: "\ebb9"
}
.bx-scan:before {
    content: "\ebba"
}
.bx-screenshot:before {
    content: "\ebbb"
}
.bx-search:before {
    content: "\ebbc"
}
.bx-search-alt:before {
    content: "\ebbd"
}
.bx-search-alt-2:before {
    content: "\ebbe"
}
.bx-selection:before {
    content: "\ebbf"
}
.bx-select-multiple:before {
    content: "\ebc0"
}
.bx-send:before {
    content: "\ebc1"
}
.bx-server:before {
    content: "\ebc2"
}
.bx-shape-circle:before {
    content: "\ebc3"
}
.bx-shape-polygon:before {
    content: "\ebc4"
}
.bx-shape-square:before {
    content: "\ebc5"
}
.bx-shape-triangle:before {
    content: "\ebc6"
}
.bx-share:before {
    content: "\ebc7"
}
.bx-share-alt:before {
    content: "\ebc8"
}
.bx-shekel:before {
    content: "\ebc9"
}
.bx-shield:before {
    content: "\ebca"
}
.bx-shield-alt:before {
    content: "\ebcb"
}
.bx-shield-alt-2:before {
    content: "\ebcc"
}
.bx-shield-quarter:before {
    content: "\ebcd"
}
.bx-shield-x:before {
    content: "\ebce"
}
.bx-shocked:before {
    content: "\ebcf"
}
.bx-shopping-bag:before {
    content: "\ebd0"
}
.bx-show:before {
    content: "\ebd1"
}
.bx-show-alt:before {
    content: "\ebd2"
}
.bx-shuffle:before {
    content: "\ebd3"
}
.bx-sidebar:before {
    content: "\ebd4"
}
.bx-sitemap:before {
    content: "\ebd5"
}
.bx-skip-next:before {
    content: "\ebd6"
}
.bx-skip-next-circle:before {
    content: "\ebd7"
}
.bx-skip-previous:before {
    content: "\ebd8"
}
.bx-skip-previous-circle:before {
    content: "\ebd9"
}
.bx-sleepy:before {
    content: "\ebda"
}
.bx-slider:before {
    content: "\ebdb"
}
.bx-slider-alt:before {
    content: "\ebdc"
}
.bx-slideshow:before {
    content: "\ebdd"
}
.bx-smile:before {
    content: "\ebde"
}
.bx-sort:before {
    content: "\ebdf"
}
.bx-sort-a-z:before {
    content: "\ebe0"
}
.bx-sort-down:before {
    content: "\ebe1"
}
.bx-sort-up:before {
    content: "\ebe2"
}
.bx-sort-z-a:before {
    content: "\ebe3"
}
.bx-spa:before {
    content: "\ebe4"
}
.bx-space-bar:before {
    content: "\ebe5"
}
.bx-spray-can:before {
    content: "\ebe6"
}
.bx-spreadsheet:before {
    content: "\ebe7"
}
.bx-square:before {
    content: "\ebe8"
}
.bx-square-rounded:before {
    content: "\ebe9"
}
.bx-star:before {
    content: "\ebea"
}
.bx-station:before {
    content: "\ebeb"
}
.bx-stats:before {
    content: "\ebec"
}
.bx-sticker:before {
    content: "\ebed"
}
.bx-stop:before {
    content: "\ebee"
}
.bx-stop-circle:before {
    content: "\ebef"
}
.bx-stopwatch:before {
    content: "\ebf0"
}
.bx-store:before {
    content: "\ebf1"
}
.bx-store-alt:before {
    content: "\ebf2"
}
.bx-street-view:before {
    content: "\ebf3"
}
.bx-strikethrough:before {
    content: "\ebf4"
}
.bx-subdirectory-left:before {
    content: "\ebf5"
}
.bx-subdirectory-right:before {
    content: "\ebf6"
}
.bx-sun:before {
    content: "\ebf7"
}
.bx-support:before {
    content: "\ebf8"
}
.bx-swim:before {
    content: "\ebf9"
}
.bx-sync:before {
    content: "\ebfa"
}
.bx-tab:before {
    content: "\ebfb"
}
.bx-table:before {
    content: "\ebfc"
}
.bx-tachometer:before {
    content: "\ebfd"
}
.bx-tag:before {
    content: "\ebfe"
}
.bx-target-lock:before {
    content: "\ebff"
}
.bx-task:before {
    content: "\ec00"
}
.bx-task-x:before {
    content: "\ec01"
}
.bx-taxi:before {
    content: "\ec02"
}
.bx-tennis-ball:before {
    content: "\ec03"
}
.bx-terminal:before {
    content: "\ec04"
}
.bx-test-tube:before {
    content: "\ec05"
}
.bx-text:before {
    content: "\ec06"
}
.bx-time:before {
    content: "\ec07"
}
.bx-time-five:before {
    content: "\ec08"
}
.bx-timer:before {
    content: "\ec09"
}
.bx-tired:before {
    content: "\ec0a"
}
.bx-toggle-left:before {
    content: "\ec0b"
}
.bx-toggle-right:before {
    content: "\ec0c"
}
.bx-tone:before {
    content: "\ec0d"
}
.bx-traffic-cone:before {
    content: "\ec0e"
}
.bx-train:before {
    content: "\ec0f"
}
.bx-transfer:before {
    content: "\ec10"
}
.bx-transfer-alt:before {
    content: "\ec11"
}
.bx-trash:before {
    content: "\ec12"
}
.bx-trash-alt:before {
    content: "\ec13"
}
.bx-trending-down:before {
    content: "\ec14"
}
.bx-trending-up:before {
    content: "\ec15"
}
.bx-trim:before {
    content: "\ec16"
}
.bx-trophy:before {
    content: "\ec17"
}
.bx-tv:before {
    content: "\ec18"
}
.bx-underline:before {
    content: "\ec19"
}
.bx-undo:before {
    content: "\ec1a"
}
.bx-unite:before {
    content: "\ec1b"
}
.bx-unlink:before {
    content: "\ec1c"
}
.bx-up-arrow:before {
    content: "\ec1d"
}
.bx-up-arrow-alt:before {
    content: "\ec1e"
}
.bx-up-arrow-circle:before {
    content: "\ec1f"
}
.bx-upload:before {
    content: "\ec20"
}
.bx-upside-down:before {
    content: "\ec21"
}
.bx-upvote:before {
    content: "\ec22"
}
.bx-usb:before {
    content: "\ec23"
}
.bx-user:before {
    content: "\ec24"
}
.bx-user-check:before {
    content: "\ec25"
}
.bx-user-circle:before {
    content: "\ec26"
}
.bx-user-minus:before {
    content: "\ec27"
}
.bx-user-pin:before {
    content: "\ec28"
}
.bx-user-plus:before {
    content: "\ec29"
}
.bx-user-voice:before {
    content: "\ec2a"
}
.bx-user-x:before {
    content: "\ec2b"
}
.bx-vector:before {
    content: "\ec2c"
}
.bx-vertical-center:before {
    content: "\ec2d"
}
.bx-vial:before {
    content: "\ec2e"
}
.bx-video:before {
    content: "\ec2f"
}
.bx-video-off:before {
    content: "\ec30"
}
.bx-video-plus:before {
    content: "\ec31"
}
.bx-video-recording:before {
    content: "\ec32"
}
.bx-voicemail:before {
    content: "\ec33"
}
.bx-volume:before {
    content: "\ec34"
}
.bx-volume-full:before {
    content: "\ec35"
}
.bx-volume-low:before {
    content: "\ec36"
}
.bx-volume-mute:before {
    content: "\ec37"
}
.bx-walk:before {
    content: "\ec38"
}
.bx-wallet:before {
    content: "\ec39"
}
.bx-wallet-alt:before {
    content: "\ec3a"
}
.bx-water:before {
    content: "\ec3b"
}
.bx-webcam:before {
    content: "\ec3c"
}
.bx-wifi:before {
    content: "\ec3d"
}
.bx-wifi-0:before {
    content: "\ec3e"
}
.bx-wifi-1:before {
    content: "\ec3f"
}
.bx-wifi-2:before {
    content: "\ec40"
}
.bx-wifi-off:before {
    content: "\ec41"
}
.bx-wind:before {
    content: "\ec42"
}
.bx-window:before {
    content: "\ec43"
}
.bx-window-alt:before {
    content: "\ec44"
}
.bx-window-close:before {
    content: "\ec45"
}
.bx-window-open:before {
    content: "\ec46"
}
.bx-windows:before {
    content: "\ec47"
}
.bx-wine:before {
    content: "\ec48"
}
.bx-wink-smile:before {
    content: "\ec49"
}
.bx-wink-tongue:before {
    content: "\ec4a"
}
.bx-won:before {
    content: "\ec4b"
}
.bx-world:before {
    content: "\ec4c"
}
.bx-wrench:before {
    content: "\ec4d"
}
.bx-x:before {
    content: "\ec4e"
}
.bx-x-circle:before {
    content: "\ec4f"
}
.bx-yen:before {
    content: "\ec50"
}
.bx-zoom-in:before {
    content: "\ec51"
}
.bx-zoom-out:before {
    content: "\ec52"
}
.bxs-add-to-queue:before {
    content: "\ec53"
}
.bxs-adjust:before {
    content: "\ec54"
}
.bxs-adjust-alt:before {
    content: "\ec55"
}
.bxs-alarm:before {
    content: "\ec56"
}
.bxs-alarm-add:before {
    content: "\ec57"
}
.bxs-alarm-exclamation:before {
    content: "\ec58"
}
.bxs-alarm-off:before {
    content: "\ec59"
}
.bxs-alarm-snooze:before {
    content: "\ec5a"
}
.bxs-album:before {
    content: "\ec5b"
}
.bxs-ambulance:before {
    content: "\ec5c"
}
.bxs-analyse:before {
    content: "\ec5d"
}
.bxs-angry:before {
    content: "\ec5e"
}
.bxs-arch:before {
    content: "\ec5f"
}
.bxs-archive:before {
    content: "\ec60"
}
.bxs-archive-in:before {
    content: "\ec61"
}
.bxs-archive-out:before {
    content: "\ec62"
}
.bxs-area:before {
    content: "\ec63"
}
.bxs-arrow-from-bottom:before {
    content: "\ec64"
}
.bxs-arrow-from-left:before {
    content: "\ec65"
}
.bxs-arrow-from-right:before {
    content: "\ec66"
}
.bxs-arrow-from-top:before {
    content: "\ec67"
}
.bxs-arrow-to-bottom:before {
    content: "\ec68"
}
.bxs-arrow-to-left:before {
    content: "\ec69"
}
.bxs-arrow-to-right:before {
    content: "\ec6a"
}
.bxs-arrow-to-top:before {
    content: "\ec6b"
}
.bxs-award:before {
    content: "\ec6c"
}
.bxs-baby-carriage:before {
    content: "\ec6d"
}
.bxs-backpack:before {
    content: "\ec6e"
}
.bxs-badge:before {
    content: "\ec6f"
}
.bxs-badge-check:before {
    content: "\ec70"
}
.bxs-badge-dollar:before {
    content: "\ec71"
}
.bxs-ball:before {
    content: "\ec72"
}
.bxs-band-aid:before {
    content: "\ec73"
}
.bxs-bank:before {
    content: "\ec74"
}
.bxs-bar-chart-alt-2:before {
    content: "\ec75"
}
.bxs-bar-chart-square:before {
    content: "\ec76"
}
.bxs-barcode:before {
    content: "\ec77"
}
.bxs-baseball:before {
    content: "\ec78"
}
.bxs-basket:before {
    content: "\ec79"
}
.bxs-basketball:before {
    content: "\ec7a"
}
.bxs-bath:before {
    content: "\ec7b"
}
.bxs-battery:before {
    content: "\ec7c"
}
.bxs-battery-charging:before {
    content: "\ec7d"
}
.bxs-battery-full:before {
    content: "\ec7e"
}
.bxs-battery-low:before {
    content: "\ec7f"
}
.bxs-bed:before {
    content: "\ec80"
}
.bxs-been-here:before {
    content: "\ec81"
}
.bxs-beer:before {
    content: "\ec82"
}
.bxs-bell:before {
    content: "\ec83"
}
.bxs-bell-minus:before {
    content: "\ec84"
}
.bxs-bell-off:before {
    content: "\ec85"
}
.bxs-bell-plus:before {
    content: "\ec86"
}
.bxs-bell-ring:before {
    content: "\ec87"
}
.bxs-bible:before {
    content: "\ec88"
}
.bxs-binoculars:before {
    content: "\ec89"
}
.bxs-blanket:before {
    content: "\ec8a"
}
.bxs-bolt:before {
    content: "\ec8b"
}
.bxs-bolt-circle:before {
    content: "\ec8c"
}
.bxs-bomb:before {
    content: "\ec8d"
}
.bxs-bone:before {
    content: "\ec8e"
}
.bxs-bong:before {
    content: "\ec8f"
}
.bxs-book:before {
    content: "\ec90"
}
.bxs-book-add:before {
    content: "\ec91"
}
.bxs-book-alt:before {
    content: "\ec92"
}
.bxs-book-bookmark:before {
    content: "\ec93"
}
.bxs-book-content:before {
    content: "\ec94"
}
.bxs-book-heart:before {
    content: "\ec95"
}
.bxs-bookmark:before {
    content: "\ec96"
}
.bxs-bookmark-minus:before {
    content: "\ec97"
}
.bxs-bookmark-plus:before {
    content: "\ec98"
}
.bxs-bookmarks:before {
    content: "\ec99"
}
.bxs-bookmark-star:before {
    content: "\ec9a"
}
.bxs-book-open:before {
    content: "\ec9b"
}
.bxs-book-reader:before {
    content: "\ec9c"
}
.bxs-bot:before {
    content: "\ec9d"
}
.bxs-bowling-ball:before {
    content: "\ec9e"
}
.bxs-box:before {
    content: "\ec9f"
}
.bxs-brain:before {
    content: "\eca0"
}
.bxs-briefcase:before {
    content: "\eca1"
}
.bxs-briefcase-alt:before {
    content: "\eca2"
}
.bxs-briefcase-alt-2:before {
    content: "\eca3"
}
.bxs-brightness:before {
    content: "\eca4"
}
.bxs-brightness-half:before {
    content: "\eca5"
}
.bxs-brush:before {
    content: "\eca6"
}
.bxs-brush-alt:before {
    content: "\eca7"
}
.bxs-bug:before {
    content: "\eca8"
}
.bxs-bug-alt:before {
    content: "\eca9"
}
.bxs-building:before {
    content: "\ecaa"
}
.bxs-building-house:before {
    content: "\ecab"
}
.bxs-buildings:before {
    content: "\ecac"
}
.bxs-bulb:before {
    content: "\ecad"
}
.bxs-bullseye:before {
    content: "\ecae"
}
.bxs-buoy:before {
    content: "\ecaf"
}
.bxs-bus:before {
    content: "\ecb0"
}
.bxs-business:before {
    content: "\ecb1"
}
.bxs-bus-school:before {
    content: "\ecb2"
}
.bxs-cabinet:before {
    content: "\ecb3"
}
.bxs-cake:before {
    content: "\ecb4"
}
.bxs-calculator:before {
    content: "\ecb5"
}
.bxs-calendar:before {
    content: "\ecb6"
}
.bxs-calendar-alt:before {
    content: "\ecb7"
}
.bxs-calendar-check:before {
    content: "\ecb8"
}
.bxs-calendar-edit:before {
    content: "\ecb9"
}
.bxs-calendar-event:before {
    content: "\ecba"
}
.bxs-calendar-exclamation:before {
    content: "\ecbb"
}
.bxs-calendar-heart:before {
    content: "\ecbc"
}
.bxs-calendar-minus:before {
    content: "\ecbd"
}
.bxs-calendar-plus:before {
    content: "\ecbe"
}
.bxs-calendar-star:before {
    content: "\ecbf"
}
.bxs-calendar-week:before {
    content: "\ecc0"
}
.bxs-calendar-x:before {
    content: "\ecc1"
}
.bxs-camera:before {
    content: "\ecc2"
}
.bxs-camera-home:before {
    content: "\ecc3"
}
.bxs-camera-movie:before {
    content: "\ecc4"
}
.bxs-camera-off:before {
    content: "\ecc5"
}
.bxs-camera-plus:before {
    content: "\ecc6"
}
.bxs-capsule:before {
    content: "\ecc7"
}
.bxs-captions:before {
    content: "\ecc8"
}
.bxs-car:before {
    content: "\ecc9"
}
.bxs-car-battery:before {
    content: "\ecca"
}
.bxs-car-crash:before {
    content: "\eccb"
}
.bxs-card:before {
    content: "\eccc"
}
.bxs-caret-down-circle:before {
    content: "\eccd"
}
.bxs-caret-down-square:before {
    content: "\ecce"
}
.bxs-caret-left-circle:before {
    content: "\eccf"
}
.bxs-caret-left-square:before {
    content: "\ecd0"
}
.bxs-caret-right-circle:before {
    content: "\ecd1"
}
.bxs-caret-right-square:before {
    content: "\ecd2"
}
.bxs-caret-up-circle:before {
    content: "\ecd3"
}
.bxs-caret-up-square:before {
    content: "\ecd4"
}
.bxs-car-garage:before {
    content: "\ecd5"
}
.bxs-car-mechanic:before {
    content: "\ecd6"
}
.bxs-carousel:before {
    content: "\ecd7"
}
.bxs-cart:before {
    content: "\ecd8"
}
.bxs-cart-add:before {
    content: "\ecd9"
}
.bxs-cart-alt:before {
    content: "\ecda"
}
.bxs-cart-download:before {
    content: "\ecdb"
}
.bxs-car-wash:before {
    content: "\ecdc"
}
.bxs-cctv:before {
    content: "\ecdd"
}
.bxs-certification:before {
    content: "\ecde"
}
.bxs-chalkboard:before {
    content: "\ecdf"
}
.bxs-chart:before {
    content: "\ece0"
}
.bxs-chat:before {
    content: "\ece1"
}
.bxs-checkbox:before {
    content: "\ece2"
}
.bxs-checkbox-checked:before {
    content: "\ece3"
}
.bxs-check-circle:before {
    content: "\ece4"
}
.bxs-check-shield:before {
    content: "\ece5"
}
.bxs-check-square:before {
    content: "\ece6"
}
.bxs-chess:before {
    content: "\ece7"
}
.bxs-chevron-down:before {
    content: "\ece8"
}
.bxs-chevron-down-circle:before {
    content: "\ece9"
}
.bxs-chevron-down-square:before {
    content: "\ecea"
}
.bxs-chevron-left:before {
    content: "\eceb"
}
.bxs-chevron-left-circle:before {
    content: "\ecec"
}
.bxs-chevron-left-square:before {
    content: "\eced"
}
.bxs-chevron-right:before {
    content: "\ecee"
}
.bxs-chevron-right-circle:before {
    content: "\ecef"
}
.bxs-chevron-right-square:before {
    content: "\ecf0"
}
.bxs-chevrons-down:before {
    content: "\ecf1"
}
.bxs-chevrons-left:before {
    content: "\ecf2"
}
.bxs-chevrons-right:before {
    content: "\ecf3"
}
.bxs-chevrons-up:before {
    content: "\ecf4"
}
.bxs-chevron-up:before {
    content: "\ecf5"
}
.bxs-chevron-up-circle:before {
    content: "\ecf6"
}
.bxs-chevron-up-square:before {
    content: "\ecf7"
}
.bxs-chip:before {
    content: "\ecf8"
}
.bxs-church:before {
    content: "\ecf9"
}
.bxs-circle:before {
    content: "\ecfa"
}
.bxs-city:before {
    content: "\ecfb"
}
.bxs-clinic:before {
    content: "\ecfc"
}
.bxs-cloud:before {
    content: "\ecfd"
}
.bxs-cloud-download:before {
    content: "\ecfe"
}
.bxs-cloud-lightning:before {
    content: "\ecff"
}
.bxs-cloud-rain:before {
    content: "\ed00"
}
.bxs-cloud-upload:before {
    content: "\ed01"
}
.bxs-coffee:before {
    content: "\ed02"
}
.bxs-coffee-alt:before {
    content: "\ed03"
}
.bxs-coffee-togo:before {
    content: "\ed04"
}
.bxs-cog:before {
    content: "\ed05"
}
.bxs-coin:before {
    content: "\ed06"
}
.bxs-coin-stack:before {
    content: "\ed07"
}
.bxs-collection:before {
    content: "\ed08"
}
.bxs-color-fill:before {
    content: "\ed09"
}
.bxs-comment:before {
    content: "\ed0a"
}
.bxs-comment-add:before {
    content: "\ed0b"
}
.bxs-comment-check:before {
    content: "\ed0c"
}
.bxs-comment-detail:before {
    content: "\ed0d"
}
.bxs-comment-dots:before {
    content: "\ed0e"
}
.bxs-comment-edit:before {
    content: "\ed0f"
}
.bxs-comment-error:before {
    content: "\ed10"
}
.bxs-comment-minus:before {
    content: "\ed11"
}
.bxs-comment-x:before {
    content: "\ed12"
}
.bxs-compass:before {
    content: "\ed13"
}
.bxs-component:before {
    content: "\ed14"
}
.bxs-confused:before {
    content: "\ed15"
}
.bxs-contact:before {
    content: "\ed16"
}
.bxs-conversation:before {
    content: "\ed17"
}
.bxs-cookie:before {
    content: "\ed18"
}
.bxs-cool:before {
    content: "\ed19"
}
.bxs-copy:before {
    content: "\ed1a"
}
.bxs-copy-alt:before {
    content: "\ed1b"
}
.bxs-copyright:before {
    content: "\ed1c"
}
.bxs-coupon:before {
    content: "\ed1d"
}
.bxs-credit-card:before {
    content: "\ed1e"
}
.bxs-credit-card-alt:before {
    content: "\ed1f"
}
.bxs-credit-card-front:before {
    content: "\ed20"
}
.bxs-crop:before {
    content: "\ed21"
}
.bxs-crown:before {
    content: "\ed22"
}
.bxs-cube:before {
    content: "\ed23"
}
.bxs-cube-alt:before {
    content: "\ed24"
}
.bxs-cuboid:before {
    content: "\ed25"
}
.bxs-customize:before {
    content: "\ed26"
}
.bxs-cylinder:before {
    content: "\ed27"
}
.bxs-dashboard:before {
    content: "\ed28"
}
.bxs-data:before {
    content: "\ed29"
}
.bxs-detail:before {
    content: "\ed2a"
}
.bxs-devices:before {
    content: "\ed2b"
}
.bxs-diamond:before {
    content: "\ed2c"
}
.bxs-dice-1:before {
    content: "\ed2d"
}
.bxs-dice-2:before {
    content: "\ed2e"
}
.bxs-dice-3:before {
    content: "\ed2f"
}
.bxs-dice-4:before {
    content: "\ed30"
}
.bxs-dice-5:before {
    content: "\ed31"
}
.bxs-dice-6:before {
    content: "\ed32"
}
.bxs-direction-left:before {
    content: "\ed33"
}
.bxs-direction-right:before {
    content: "\ed34"
}
.bxs-directions:before {
    content: "\ed35"
}
.bxs-disc:before {
    content: "\ed36"
}
.bxs-discount:before {
    content: "\ed37"
}
.bxs-dish:before {
    content: "\ed38"
}
.bxs-dislike:before {
    content: "\ed39"
}
.bxs-dizzy:before {
    content: "\ed3a"
}
.bxs-dock-bottom:before {
    content: "\ed3b"
}
.bxs-dock-left:before {
    content: "\ed3c"
}
.bxs-dock-right:before {
    content: "\ed3d"
}
.bxs-dock-top:before {
    content: "\ed3e"
}
.bxs-dollar-circle:before {
    content: "\ed3f"
}
.bxs-donate-blood:before {
    content: "\ed40"
}
.bxs-donate-heart:before {
    content: "\ed41"
}
.bxs-door-open:before {
    content: "\ed42"
}
.bxs-doughnut-chart:before {
    content: "\ed43"
}
.bxs-down-arrow:before {
    content: "\ed44"
}
.bxs-down-arrow-alt:before {
    content: "\ed45"
}
.bxs-down-arrow-circle:before {
    content: "\ed46"
}
.bxs-down-arrow-square:before {
    content: "\ed47"
}
.bxs-download:before {
    content: "\ed48"
}
.bxs-downvote:before {
    content: "\ed49"
}
.bxs-drink:before {
    content: "\ed4a"
}
.bxs-droplet:before {
    content: "\ed4b"
}
.bxs-droplet-half:before {
    content: "\ed4c"
}
.bxs-dryer:before {
    content: "\ed4d"
}
.bxs-duplicate:before {
    content: "\ed4e"
}
.bxs-edit:before {
    content: "\ed4f"
}
.bxs-edit-alt:before {
    content: "\ed50"
}
.bxs-edit-location:before {
    content: "\ed51"
}
.bxs-eject:before {
    content: "\ed52"
}
.bxs-envelope:before {
    content: "\ed53"
}
.bxs-envelope-open:before {
    content: "\ed54"
}
.bxs-eraser:before {
    content: "\ed55"
}
.bxs-error:before {
    content: "\ed56"
}
.bxs-error-alt:before {
    content: "\ed57"
}
.bxs-error-circle:before {
    content: "\ed58"
}
.bxs-ev-station:before {
    content: "\ed59"
}
.bxs-exit:before {
    content: "\ed5a"
}
.bxs-extension:before {
    content: "\ed5b"
}
.bxs-eyedropper:before {
    content: "\ed5c"
}
.bxs-face:before {
    content: "\ed5d"
}
.bxs-factory:before {
    content: "\ed5e"
}
.bxs-fast-forward-circle:before {
    content: "\ed5f"
}
.bxs-file:before {
    content: "\ed60"
}
.bxs-file-archive:before {
    content: "\ed61"
}
.bxs-file-blank:before {
    content: "\ed62"
}
.bxs-file-css:before {
    content: "\ed63"
}
.bxs-file-doc:before {
    content: "\ed64"
}
.bxs-file-export:before {
    content: "\ed65"
}
.bxs-file-find:before {
    content: "\ed66"
}
.bxs-file-gif:before {
    content: "\ed67"
}
.bxs-file-html:before {
    content: "\ed68"
}
.bxs-file-image:before {
    content: "\ed69"
}
.bxs-file-import:before {
    content: "\ed6a"
}
.bxs-file-jpg:before {
    content: "\ed6b"
}
.bxs-file-js:before {
    content: "\ed6c"
}
.bxs-file-json:before {
    content: "\ed6d"
}
.bxs-file-md:before {
    content: "\ed6e"
}
.bxs-file-pdf:before {
    content: "\ed6f"
}
.bxs-file-plus:before {
    content: "\ed70"
}
.bxs-file-png:before {
    content: "\ed71"
}
.bxs-file-txt:before {
    content: "\ed72"
}
.bxs-film:before {
    content: "\ed73"
}
.bxs-filter-alt:before {
    content: "\ed74"
}
.bxs-first-aid:before {
    content: "\ed75"
}
.bxs-flag:before {
    content: "\ed76"
}
.bxs-flag-alt:before {
    content: "\ed77"
}
.bxs-flag-checkered:before {
    content: "\ed78"
}
.bxs-flame:before {
    content: "\ed79"
}
.bxs-flask:before {
    content: "\ed7a"
}
.bxs-florist:before {
    content: "\ed7b"
}
.bxs-folder:before {
    content: "\ed7c"
}
.bxs-folder-minus:before {
    content: "\ed7d"
}
.bxs-folder-open:before {
    content: "\ed7e"
}
.bxs-folder-plus:before {
    content: "\ed7f"
}
.bxs-food-menu:before {
    content: "\ed80"
}
.bxs-fridge:before {
    content: "\ed81"
}
.bxs-game:before {
    content: "\ed82"
}
.bxs-gas-pump:before {
    content: "\ed83"
}
.bxs-ghost:before {
    content: "\ed84"
}
.bxs-gift:before {
    content: "\ed85"
}
.bxs-graduation:before {
    content: "\ed86"
}
.bxs-grid:before {
    content: "\ed87"
}
.bxs-grid-alt:before {
    content: "\ed88"
}
.bxs-group:before {
    content: "\ed89"
}
.bxs-guitar-amp:before {
    content: "\ed8a"
}
.bxs-hand-down:before {
    content: "\ed8b"
}
.bxs-hand-left:before {
    content: "\ed8c"
}
.bxs-hand-right:before {
    content: "\ed8d"
}
.bxs-hand-up:before {
    content: "\ed8e"
}
.bxs-happy:before {
    content: "\ed8f"
}
.bxs-happy-alt:before {
    content: "\ed90"
}
.bxs-happy-beaming:before {
    content: "\ed91"
}
.bxs-happy-heart-eyes:before {
    content: "\ed92"
}
.bxs-hdd:before {
    content: "\ed93"
}
.bxs-heart:before {
    content: "\ed94"
}
.bxs-heart-circle:before {
    content: "\ed95"
}
.bxs-heart-square:before {
    content: "\ed96"
}
.bxs-help-circle:before {
    content: "\ed97"
}
.bxs-hide:before {
    content: "\ed98"
}
.bxs-home:before {
    content: "\ed99"
}
.bxs-home-circle:before {
    content: "\ed9a"
}
.bxs-home-heart:before {
    content: "\ed9b"
}
.bxs-home-smile:before {
    content: "\ed9c"
}
.bxs-hot:before {
    content: "\ed9d"
}
.bxs-hotel:before {
    content: "\ed9e"
}
.bxs-hourglass:before {
    content: "\ed9f"
}
.bxs-hourglass-bottom:before {
    content: "\eda0"
}
.bxs-hourglass-top:before {
    content: "\eda1"
}
.bxs-id-card:before {
    content: "\eda2"
}
.bxs-image:before {
    content: "\eda3"
}
.bxs-image-add:before {
    content: "\eda4"
}
.bxs-image-alt:before {
    content: "\eda5"
}
.bxs-inbox:before {
    content: "\eda6"
}
.bxs-info-circle:before {
    content: "\eda7"
}
.bxs-info-square:before {
    content: "\eda8"
}
.bxs-institution:before {
    content: "\eda9"
}
.bxs-joystick:before {
    content: "\edaa"
}
.bxs-joystick-alt:before {
    content: "\edab"
}
.bxs-joystick-button:before {
    content: "\edac"
}
.bxs-key:before {
    content: "\edad"
}
.bxs-keyboard:before {
    content: "\edae"
}
.bxs-label:before {
    content: "\edaf"
}
.bxs-landmark:before {
    content: "\edb0"
}
.bxs-landscape:before {
    content: "\edb1"
}
.bxs-laugh:before {
    content: "\edb2"
}
.bxs-layer:before {
    content: "\edb3"
}
.bxs-layer-minus:before {
    content: "\edb4"
}
.bxs-layer-plus:before {
    content: "\edb5"
}
.bxs-layout:before {
    content: "\edb6"
}
.bxs-left-arrow:before {
    content: "\edb7"
}
.bxs-left-arrow-alt:before {
    content: "\edb8"
}
.bxs-left-arrow-circle:before {
    content: "\edb9"
}
.bxs-left-arrow-square:before {
    content: "\edba"
}
.bxs-left-down-arrow-circle:before {
    content: "\edbb"
}
.bxs-left-top-arrow-circle:before {
    content: "\edbc"
}
.bxs-like:before {
    content: "\edbd"
}
.bxs-location-plus:before {
    content: "\edbe"
}
.bxs-lock:before {
    content: "\edbf"
}
.bxs-lock-alt:before {
    content: "\edc0"
}
.bxs-lock-open:before {
    content: "\edc1"
}
.bxs-lock-open-alt:before {
    content: "\edc2"
}
.bxs-log-in:before {
    content: "\edc3"
}
.bxs-log-in-circle:before {
    content: "\edc4"
}
.bxs-log-out:before {
    content: "\edc5"
}
.bxs-log-out-circle:before {
    content: "\edc6"
}
.bxs-low-vision:before {
    content: "\edc7"
}
.bxs-magic-wand:before {
    content: "\edc8"
}
.bxs-magnet:before {
    content: "\edc9"
}
.bxs-map:before {
    content: "\edca"
}
.bxs-map-alt:before {
    content: "\edcb"
}
.bxs-map-pin:before {
    content: "\edcc"
}
.bxs-mask:before {
    content: "\edcd"
}
.bxs-medal:before {
    content: "\edce"
}
.bxs-megaphone:before {
    content: "\edcf"
}
.bxs-meh:before {
    content: "\edd0"
}
.bxs-meh-alt:before {
    content: "\edd1"
}
.bxs-meh-blank:before {
    content: "\edd2"
}
.bxs-memory-card:before {
    content: "\edd3"
}
.bxs-message:before {
    content: "\edd4"
}
.bxs-message-add:before {
    content: "\edd5"
}
.bxs-message-alt:before {
    content: "\edd6"
}
.bxs-message-alt-add:before {
    content: "\edd7"
}
.bxs-message-alt-check:before {
    content: "\edd8"
}
.bxs-message-alt-detail:before {
    content: "\edd9"
}
.bxs-message-alt-dots:before {
    content: "\edda"
}
.bxs-message-alt-edit:before {
    content: "\eddb"
}
.bxs-message-alt-error:before {
    content: "\eddc"
}
.bxs-message-alt-minus:before {
    content: "\eddd"
}
.bxs-message-alt-x:before {
    content: "\edde"
}
.bxs-message-check:before {
    content: "\eddf"
}
.bxs-message-detail:before {
    content: "\ede0"
}
.bxs-message-dots:before {
    content: "\ede1"
}
.bxs-message-edit:before {
    content: "\ede2"
}
.bxs-message-error:before {
    content: "\ede3"
}
.bxs-message-minus:before {
    content: "\ede4"
}
.bxs-message-rounded:before {
    content: "\ede5"
}
.bxs-message-rounded-add:before {
    content: "\ede6"
}
.bxs-message-rounded-check:before {
    content: "\ede7"
}
.bxs-message-rounded-detail:before {
    content: "\ede8"
}
.bxs-message-rounded-dots:before {
    content: "\ede9"
}
.bxs-message-rounded-edit:before {
    content: "\edea"
}
.bxs-message-rounded-error:before {
    content: "\edeb"
}
.bxs-message-rounded-minus:before {
    content: "\edec"
}
.bxs-message-rounded-x:before {
    content: "\eded"
}
.bxs-message-square:before {
    content: "\edee"
}
.bxs-message-square-add:before {
    content: "\edef"
}
.bxs-message-square-check:before {
    content: "\edf0"
}
.bxs-message-square-detail:before {
    content: "\edf1"
}
.bxs-message-square-dots:before {
    content: "\edf2"
}
.bxs-message-square-edit:before {
    content: "\edf3"
}
.bxs-message-square-error:before {
    content: "\edf4"
}
.bxs-message-square-minus:before {
    content: "\edf5"
}
.bxs-message-square-x:before {
    content: "\edf6"
}
.bxs-message-x:before {
    content: "\edf7"
}
.bxs-meteor:before {
    content: "\edf8"
}
.bxs-microchip:before {
    content: "\edf9"
}
.bxs-microphone:before {
    content: "\edfa"
}
.bxs-microphone-alt:before {
    content: "\edfb"
}
.bxs-microphone-off:before {
    content: "\edfc"
}
.bxs-minus-circle:before {
    content: "\edfd"
}
.bxs-minus-square:before {
    content: "\edfe"
}
.bxs-mobile:before {
    content: "\edff"
}
.bxs-mobile-vibration:before {
    content: "\ee00"
}
.bxs-moon:before {
    content: "\ee01"
}
.bxs-mouse:before {
    content: "\ee02"
}
.bxs-mouse-alt:before {
    content: "\ee03"
}
.bxs-movie:before {
    content: "\ee04"
}
.bxs-music:before {
    content: "\ee05"
}
.bxs-navigation:before {
    content: "\ee06"
}
.bxs-network-chart:before {
    content: "\ee07"
}
.bxs-news:before {
    content: "\ee08"
}
.bxs-no-entry:before {
    content: "\ee09"
}
.bxs-note:before {
    content: "\ee0a"
}
.bxs-notepad:before {
    content: "\ee0b"
}
.bxs-notification:before {
    content: "\ee0c"
}
.bxs-notification-off:before {
    content: "\ee0d"
}
.bxs-offer:before {
    content: "\ee0e"
}
.bxs-package:before {
    content: "\ee0f"
}
.bxs-paint:before {
    content: "\ee10"
}
.bxs-paint-roll:before {
    content: "\ee11"
}
.bxs-palette:before {
    content: "\ee12"
}
.bxs-paper-plane:before {
    content: "\ee13"
}
.bxs-parking:before {
    content: "\ee14"
}
.bxs-paste:before {
    content: "\ee15"
}
.bxs-pen:before {
    content: "\ee16"
}
.bxs-pencil:before {
    content: "\ee17"
}
.bxs-phone:before {
    content: "\ee18"
}
.bxs-phone-call:before {
    content: "\ee19"
}
.bxs-phone-incoming:before {
    content: "\ee1a"
}
.bxs-phone-outgoing:before {
    content: "\ee1b"
}
.bxs-photo-album:before {
    content: "\ee1c"
}
.bxs-piano:before {
    content: "\ee1d"
}
.bxs-pie-chart:before {
    content: "\ee1e"
}
.bxs-pie-chart-alt:before {
    content: "\ee1f"
}
.bxs-pie-chart-alt-2:before {
    content: "\ee20"
}
.bxs-pin:before {
    content: "\ee21"
}
.bxs-pizza:before {
    content: "\ee22"
}
.bxs-plane:before {
    content: "\ee23"
}
.bxs-plane-alt:before {
    content: "\ee24"
}
.bxs-plane-land:before {
    content: "\ee25"
}
.bxs-planet:before {
    content: "\ee26"
}
.bxs-plane-take-off:before {
    content: "\ee27"
}
.bxs-playlist:before {
    content: "\ee28"
}
.bxs-plug:before {
    content: "\ee29"
}
.bxs-plus-circle:before {
    content: "\ee2a"
}
.bxs-plus-square:before {
    content: "\ee2b"
}
.bxs-pointer:before {
    content: "\ee2c"
}
.bxs-polygon:before {
    content: "\ee2d"
}
.bxs-printer:before {
    content: "\ee2e"
}
.bxs-purchase-tag:before {
    content: "\ee2f"
}
.bxs-purchase-tag-alt:before {
    content: "\ee30"
}
.bxs-pyramid:before {
    content: "\ee31"
}
.bxs-quote-alt-left:before {
    content: "\ee32"
}
.bxs-quote-alt-right:before {
    content: "\ee33"
}
.bxs-quote-left:before {
    content: "\ee34"
}
.bxs-quote-right:before {
    content: "\ee35"
}
.bxs-quote-single-left:before {
    content: "\ee36"
}
.bxs-quote-single-right:before {
    content: "\ee37"
}
.bxs-radiation:before {
    content: "\ee38"
}
.bxs-radio:before {
    content: "\ee39"
}
.bxs-receipt:before {
    content: "\ee3a"
}
.bxs-rectangle:before {
    content: "\ee3b"
}
.bxs-rename:before {
    content: "\ee3c"
}
.bxs-report:before {
    content: "\ee3d"
}
.bxs-rewind-circle:before {
    content: "\ee3e"
}
.bxs-right-arrow:before {
    content: "\ee3f"
}
.bxs-right-arrow-alt:before {
    content: "\ee40"
}
.bxs-right-arrow-circle:before {
    content: "\ee41"
}
.bxs-right-arrow-square:before {
    content: "\ee42"
}
.bxs-right-down-arrow-circle:before {
    content: "\ee43"
}
.bxs-right-top-arrow-circle:before {
    content: "\ee44"
}
.bxs-rocket:before {
    content: "\ee45"
}
.bxs-ruler:before {
    content: "\ee46"
}
.bxs-sad:before {
    content: "\ee47"
}
.bxs-save:before {
    content: "\ee48"
}
.bxs-school:before {
    content: "\ee49"
}
.bxs-search:before {
    content: "\ee4a"
}
.bxs-search-alt-2:before {
    content: "\ee4b"
}
.bxs-select-multiple:before {
    content: "\ee4c"
}
.bxs-send:before {
    content: "\ee4d"
}
.bxs-server:before {
    content: "\ee4e"
}
.bxs-shapes:before {
    content: "\ee4f"
}
.bxs-share:before {
    content: "\ee50"
}
.bxs-share-alt:before {
    content: "\ee51"
}
.bxs-shield:before {
    content: "\ee52"
}
.bxs-shield-alt-2:before {
    content: "\ee53"
}
.bxs-shield-x:before {
    content: "\ee54"
}
.bxs-ship:before {
    content: "\ee55"
}
.bxs-shocked:before {
    content: "\ee56"
}
.bxs-shopping-bag:before {
    content: "\ee57"
}
.bxs-shopping-bag-alt:before {
    content: "\ee58"
}
.bxs-shopping-bags:before {
    content: "\ee59"
}
.bxs-show:before {
    content: "\ee5a"
}
.bxs-skip-next-circle:before {
    content: "\ee5b"
}
.bxs-skip-previous-circle:before {
    content: "\ee5c"
}
.bxs-skull:before {
    content: "\ee5d"
}
.bxs-sleepy:before {
    content: "\ee5e"
}
.bxs-slideshow:before {
    content: "\ee5f"
}
.bxs-smile:before {
    content: "\ee60"
}
.bxs-sort-alt:before {
    content: "\ee61"
}
.bxs-spa:before {
    content: "\ee62"
}
.bxs-spray-can:before {
    content: "\ee63"
}
.bxs-spreadsheet:before {
    content: "\ee64"
}
.bxs-square:before {
    content: "\ee65"
}
.bxs-square-rounded:before {
    content: "\ee66"
}
.bxs-star:before {
    content: "\ee67"
}
.bxs-star-half:before {
    content: "\ee68"
}
.bxs-sticker:before {
    content: "\ee69"
}
.bxs-stopwatch:before {
    content: "\ee6a"
}
.bxs-store:before {
    content: "\ee6b"
}
.bxs-store-alt:before {
    content: "\ee6c"
}
.bxs-sun:before {
    content: "\ee6d"
}
.bxs-tachometer:before {
    content: "\ee6e"
}
.bxs-tag:before {
    content: "\ee6f"
}
.bxs-tag-x:before {
    content: "\ee70"
}
.bxs-taxi:before {
    content: "\ee71"
}
.bxs-tennis-ball:before {
    content: "\ee72"
}
.bxs-terminal:before {
    content: "\ee73"
}
.bxs-thermometer:before {
    content: "\ee74"
}
.bxs-time:before {
    content: "\ee75"
}
.bxs-time-five:before {
    content: "\ee76"
}
.bxs-timer:before {
    content: "\ee77"
}
.bxs-tired:before {
    content: "\ee78"
}
.bxs-toggle-left:before {
    content: "\ee79"
}
.bxs-toggle-right:before {
    content: "\ee7a"
}
.bxs-tone:before {
    content: "\ee7b"
}
.bxs-torch:before {
    content: "\ee7c"
}
.bxs-to-top:before {
    content: "\ee7d"
}
.bxs-traffic:before {
    content: "\ee7e"
}
.bxs-traffic-barrier:before {
    content: "\ee7f"
}
.bxs-traffic-cone:before {
    content: "\ee80"
}
.bxs-train:before {
    content: "\ee81"
}
.bxs-trash:before {
    content: "\ee82"
}
.bxs-trash-alt:before {
    content: "\ee83"
}
.bxs-tree:before {
    content: "\ee84"
}
.bxs-trophy:before {
    content: "\ee85"
}
.bxs-truck:before {
    content: "\ee86"
}
.bxs-t-shirt:before {
    content: "\ee87"
}
.bxs-up-arrow:before {
    content: "\ee88"
}
.bxs-up-arrow-alt:before {
    content: "\ee89"
}
.bxs-up-arrow-circle:before {
    content: "\ee8a"
}
.bxs-up-arrow-square:before {
    content: "\ee8b"
}
.bxs-upside-down:before {
    content: "\ee8c"
}
.bxs-upvote:before {
    content: "\ee8d"
}
.bxs-user:before {
    content: "\ee8e"
}
.bxs-user-badge:before {
    content: "\ee8f"
}
.bxs-user-check:before {
    content: "\ee90"
}
.bxs-user-circle:before {
    content: "\ee91"
}
.bxs-user-detail:before {
    content: "\ee92"
}
.bxs-user-minus:before {
    content: "\ee93"
}
.bxs-user-pin:before {
    content: "\ee94"
}
.bxs-user-plus:before {
    content: "\ee95"
}
.bxs-user-rectangle:before {
    content: "\ee96"
}
.bxs-user-voice:before {
    content: "\ee97"
}
.bxs-user-x:before {
    content: "\ee98"
}
.bxs-vector:before {
    content: "\ee99"
}
.bxs-vial:before {
    content: "\ee9a"
}
.bxs-video:before {
    content: "\ee9b"
}
.bxs-video-off:before {
    content: "\ee9c"
}
.bxs-video-plus:before {
    content: "\ee9d"
}
.bxs-video-recording:before {
    content: "\ee9e"
}
.bxs-videos:before {
    content: "\ee9f"
}
.bxs-volume:before {
    content: "\eea0"
}
.bxs-volume-full:before {
    content: "\eea1"
}
.bxs-volume-low:before {
    content: "\eea2"
}
.bxs-volume-mute:before {
    content: "\eea3"
}
.bxs-wallet:before {
    content: "\eea4"
}
.bxs-wallet-alt:before {
    content: "\eea5"
}
.bxs-washer:before {
    content: "\eea6"
}
.bxs-watch:before {
    content: "\eea7"
}
.bxs-watch-alt:before {
    content: "\eea8"
}
.bxs-webcam:before {
    content: "\eea9"
}
.bxs-widget:before {
    content: "\eeaa"
}
.bxs-window-alt:before {
    content: "\eeab"
}
.bxs-wine:before {
    content: "\eeac"
}
.bxs-wink-smile:before {
    content: "\eead"
}
.bxs-wink-tongue:before {
    content: "\eeae"
}
.bxs-wrench:before {
    content: "\eeaf"
}
.bxs-x-circle:before {
    content: "\eeb0"
}
.bxs-x-square:before {
    content: "\eeb1"
}
.bxs-yin-yang:before {
    content: "\eeb2"
}
.bxs-zap:before {
    content: "\eeb3"
}
.bxs-zoom-in:before {
    content: "\eeb4"
}
.bxs-zoom-out:before {
    content: "\eeb5"
}






/*--------------------------------

LineIcons Web Font
Learn More: lineicons.com

-------------------------------- */
@font-face {
  font-family: 'LineIcons';
  src: url('../fonts/LineIcons.eot');
  src: url('../fonts/LineIcons.eot') format('embedded-opentype'), url('../fonts/LineIcons.woff2') format('woff2'), url('../fonts/LineIcons.woff') format('woff'), url('../fonts/LineIcons.ttf') format('truetype'), url('../fonts/LineIcons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* --------------------------------

icons

-------------------------------- */
.lni {
  display: inline-block;
  font: normal normal normal 32px/1 'LineIcons';
  font-size:inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  font icons
-------------------------*/

.lni-500px::before {
    content: "\ea02";
}

.lni-add-files::before {
    content: "\ea03";
}

.lni-alarm-clock::before {
    content: "\ea04";
}

.lni-alarm::before {
    content: "\ea05";
}

.lni-airbnb::before {
    content: "\ea06";
}

.lni-adobe::before {
    content: "\ea07";
}

.lni-amazon-pay::before {
    content: "\ea08";
}

.lni-amazon::before {
    content: "\ea09";
}

.lni-amex::before {
    content: "\ea0a";
}

.lni-anchor::before {
    content: "\ea0b";
}

.lni-amazon-original::before {
    content: "\ea0c";
}

.lni-android-original::before {
    content: "\ea0d";
}

.lni-android::before {
    content: "\ea0e";
}

.lni-angellist::before {
    content: "\ea0f";
}

.lni-angle-double-down::before {
    content: "\ea10";
}

.lni-angle-double-left::before {
    content: "\ea11";
}

.lni-angle-double-right::before {
    content: "\ea12";
}

.lni-angle-double-up::before {
    content: "\ea13";
}

.lni-angular::before {
    content: "\ea14";
}

.lni-apartment::before {
    content: "\ea15";
}

.lni-app-store::before {
    content: "\ea16";
}

.lni-apple-pay::before {
    content: "\ea17";
}

.lni-apple::before {
    content: "\ea18";
}

.lni-archive::before {
    content: "\ea19";
}

.lni-arrow-down-circle::before {
    content: "\ea1a";
}

.lni-arrow-left-circle::before {
    content: "\ea1b";
}

.lni-arrow-left::before {
    content: "\ea1c";
}

.lni-arrow-right-circle::before {
    content: "\ea1d";
}

.lni-arrow-right::before {
    content: "\ea1e";
}

.lni-arrow-top-left::before {
    content: "\ea1f";
}

.lni-arrow-top-right::before {
    content: "\ea20";
}

.lni-arrow-up-circle::before {
    content: "\ea21";
}

.lni-arrow-up::before {
    content: "\ea22";
}

.lni-arrows-horizontal::before {
    content: "\ea23";
}

.lni-arrows-vertical::before {
    content: "\ea24";
}

.lni-atlassian::before {
    content: "\ea25";
}

.lni-aws::before {
    content: "\ea26";
}

.lni-arrow-down::before {
    content: "\ea27";
}

.lni-ambulance::before {
    content: "\ea28";
}

.lni-agenda::before {
    content: "\ea29";
}

.lni-backward::before {
    content: "\ea2a";
}

.lni-baloon::before {
    content: "\ea2b";
}

.lni-ban::before {
    content: "\ea2c";
}

.lni-bar-chart::before {
    content: "\ea2d";
}

.lni-behance-original::before {
    content: "\ea2e";
}

.lni-bitbucket::before {
    content: "\ea2f";
}

.lni-bitcoin::before {
    content: "\ea30";
}

.lni-blackboard::before {
    content: "\ea31";
}

.lni-blogger::before {
    content: "\ea32";
}

.lni-bluetooth::before {
    content: "\ea33";
}

.lni-bold::before {
    content: "\ea34";
}

.lni-bolt-alt::before {
    content: "\ea35";
}

.lni-bolt::before {
    content: "\ea36";
}

.lni-book::before {
    content: "\ea37";
}

.lni-bookmark-alt::before {
    content: "\ea38";
}

.lni-bookmark::before {
    content: "\ea39";
}

.lni-bootstrap::before {
    content: "\ea3a";
}

.lni-bricks::before {
    content: "\ea3b";
}

.lni-bridge::before {
    content: "\ea3c";
}

.lni-briefcase::before {
    content: "\ea3d";
}

.lni-brush-alt::before {
    content: "\ea3e";
}

.lni-brush::before {
    content: "\ea3f";
}

.lni-bubble::before {
    content: "\ea40";
}

.lni-bug::before {
    content: "\ea41";
}

.lni-bulb::before {
    content: "\ea42";
}

.lni-bullhorn::before {
    content: "\ea43";
}

.lni-burger::before {
    content: "\ea44";
}

.lni-bus::before {
    content: "\ea45";
}

.lni-cake::before {
    content: "\ea46";
}

.lni-calculator::before {
    content: "\ea47";
}

.lni-calendar::before {
    content: "\ea48";
}

.lni-camera::before {
    content: "\ea49";
}

.lni-candy-cane::before {
    content: "\ea4a";
}

.lni-candy::before {
    content: "\ea4b";
}

.lni-capsule::before {
    content: "\ea4c";
}

.lni-car-alt::before {
    content: "\ea4d";
}

.lni-car::before {
    content: "\ea4e";
}

.lni-caravan::before {
    content: "\ea4f";
}

.lni-cart-full::before {
    content: "\ea50";
}

.lni-cart::before {
    content: "\ea51";
}

.lni-certificate::before {
    content: "\ea52";
}

.lni-checkbox::before {
    content: "\ea53";
}

.lni-checkmark-circle::before {
    content: "\ea54";
}

.lni-checkmark::before {
    content: "\ea55";
}

.lni-chef-hat::before {
    content: "\ea56";
}

.lni-chevron-down-circle::before {
    content: "\ea57";
}

.lni-chevron-down::before {
    content: "\ea58";
}

.lni-chevron-left-circle::before {
    content: "\ea59";
}

.lni-chevron-left::before {
    content: "\ea5a";
}

.lni-chevron-right-circle::before {
    content: "\ea5b";
}

.lni-chevron-right::before {
    content: "\ea5c";
}

.lni-chevron-up-circle::before {
    content: "\ea5d";
}

.lni-chevron-up::before {
    content: "\ea5e";
}

.lni-chrome::before {
    content: "\ea5f";
}

.lni-circle-minus::before {
    content: "\ea60";
}

.lni-circle-plus::before {
    content: "\ea61";
}

.lni-clipboard::before {
    content: "\ea62";
}

.lni-close::before {
    content: "\ea63";
}

.lni-cloud-check::before {
    content: "\ea64";
}

.lni-cloud-download::before {
    content: "\ea65";
}

.lni-cloud-network::before {
    content: "\ea66";
}

.lni-cloud-sync::before {
    content: "\ea67";
}

.lni-cloud-upload::before {
    content: "\ea68";
}

.lni-cloud::before {
    content: "\ea69";
}

.lni-cloudy-sun::before {
    content: "\ea6a";
}

.lni-code-alt::before {
    content: "\ea6b";
}

.lni-code::before {
    content: "\ea6c";
}

.lni-codepen::before {
    content: "\ea6d";
}

.lni-coffee-cup::before {
    content: "\ea6e";
}

.lni-cog::before {
    content: "\ea6f";
}

.lni-cogs::before {
    content: "\ea70";
}

.lni-coin::before {
    content: "\ea71";
}

.lni-comments-alt::before {
    content: "\ea72";
}

.lni-comments-reply::before {
    content: "\ea73";
}

.lni-comments::before {
    content: "\ea74";
}

.lni-compass::before {
    content: "\ea75";
}

.lni-construction-hammer::before {
    content: "\ea76";
}

.lni-construction::before {
    content: "\ea77";
}

.lni-consulting::before {
    content: "\ea78";
}

.lni-control-panel::before {
    content: "\ea79";
}

.lni-cpanel::before {
    content: "\ea7a";
}

.lni-creative-commons::before {
    content: "\ea7b";
}

.lni-credit-cards::before {
    content: "\ea7c";
}

.lni-crop::before {
    content: "\ea7d";
}

.lni-cross-circle::before {
    content: "\ea7e";
}

.lni-crown::before {
    content: "\ea7f";
}

.lni-css3::before {
    content: "\ea80";
}

.lni-cup::before {
    content: "\ea81";
}

.lni-customer::before {
    content: "\ea82";
}

.lni-cut::before {
    content: "\ea83";
}

.lni-dashboard::before {
    content: "\ea84";
}

.lni-database::before {
    content: "\ea85";
}

.lni-delivery::before {
    content: "\ea86";
}

.lni-dev::before {
    content: "\ea87";
}

.lni-diamond-alt::before {
    content: "\ea88";
}

.lni-diamond::before {
    content: "\ea89";
}

.lni-diners-club::before {
    content: "\ea8a";
}

.lni-dinner::before {
    content: "\ea8b";
}

.lni-direction-alt::before {
    content: "\ea8c";
}

.lni-direction-ltr::before {
    content: "\ea8d";
}

.lni-direction-rtl::before {
    content: "\ea8e";
}

.lni-direction::before {
    content: "\ea8f";
}

.lni-discord::before {
    content: "\ea90";
}

.lni-discover::before {
    content: "\ea91";
}

.lni-display-alt::before {
    content: "\ea92";
}

.lni-display::before {
    content: "\ea93";
}

.lni-docker::before {
    content: "\ea94";
}

.lni-dollar::before {
    content: "\ea95";
}

.lni-domain::before {
    content: "\ea96";
}

.lni-download::before {
    content: "\ea97";
}

.lni-dribbble::before {
    content: "\ea98";
}

.lni-drop::before {
    content: "\ea99";
}

.lni-dropbox-original::before {
    content: "\ea9a";
}

.lni-dropbox::before {
    content: "\ea9b";
}

.lni-drupal-original::before {
    content: "\ea9c";
}

.lni-drupal::before {
    content: "\ea9d";
}

.lni-dumbbell::before {
    content: "\ea9e";
}

.lni-edge::before {
    content: "\ea9f";
}

.lni-emoji-cool::before {
    content: "\eaa0";
}

.lni-emoji-friendly::before {
    content: "\eaa1";
}

.lni-emoji-happy::before {
    content: "\eaa2";
}

.lni-emoji-sad::before {
    content: "\eaa3";
}

.lni-emoji-smile::before {
    content: "\eaa4";
}

.lni-emoji-speechless::before {
    content: "\eaa5";
}

.lni-emoji-suspect::before {
    content: "\eaa6";
}

.lni-emoji-tounge::before {
    content: "\eaa7";
}

.lni-empty-file::before {
    content: "\eaa8";
}

.lni-enter::before {
    content: "\eaa9";
}

.lni-envato::before {
    content: "\eaaa";
}

.lni-envelope::before {
    content: "\eaab";
}

.lni-eraser::before {
    content: "\eaac";
}

.lni-euro::before {
    content: "\eaad";
}

.lni-exit-down::before {
    content: "\eaae";
}

.lni-exit-up::before {
    content: "\eaaf";
}

.lni-exit::before {
    content: "\eab0";
}

.lni-eye::before {
    content: "\eab1";
}

.lni-facebook-filled::before {
    content: "\eab2";
}

.lni-facebook-messenger::before {
    content: "\eab3";
}

.lni-facebook-original::before {
    content: "\eab4";
}

.lni-facebook-oval::before {
    content: "\eab5";
}

.lni-facebook::before {
    content: "\eab6";
}

.lni-figma::before {
    content: "\eab7";
}

.lni-files::before {
    content: "\eab8";
}

.lni-firefox-original::before {
    content: "\eab9";
}

.lni-firefox::before {
    content: "\eaba";
}

.lni-fireworks::before {
    content: "\eabb";
}

.lni-first-aid::before {
    content: "\eabc";
}

.lni-flag-alt::before {
    content: "\eabd";
}

.lni-flag::before {
    content: "\eabe";
}

.lni-flags::before {
    content: "\eabf";
}

.lni-flickr::before {
    content: "\eac0";
}

.lni-basketball::before {
    content: "\eac1";
}

.lni-behance::before {
    content: "\eac2";
}

.lni-forward::before {
    content: "\eac3";
}

.lni-frame-expand::before {
    content: "\eac4";
}

.lni-flower::before {
    content: "\eac5";
}

.lni-full-screen::before {
    content: "\eac6";
}

.lni-funnel::before {
    content: "\eac7";
}

.lni-gallery::before {
    content: "\eac8";
}

.lni-game::before {
    content: "\eac9";
}

.lni-gift::before {
    content: "\eaca";
}

.lni-git::before {
    content: "\eacb";
}

.lni-github-original::before {
    content: "\eacc";
}

.lni-github::before {
    content: "\eacd";
}

.lni-goodreads::before {
    content: "\eace";
}

.lni-google-drive::before {
    content: "\eacf";
}

.lni-google-pay::before {
    content: "\ead0";
}

.lni-fresh-juice::before {
    content: "\ead1";
}

.lni-folder::before {
    content: "\ead2";
}

.lni-bi-cycle::before {
    content: "\ead3";
}

.lni-graph::before {
    content: "\ead4";
}

.lni-grid-alt::before {
    content: "\ead5";
}

.lni-grid::before {
    content: "\ead6";
}

.lni-google-wallet::before {
    content: "\ead7";
}

.lni-grow::before {
    content: "\ead8";
}

.lni-hammer::before {
    content: "\ead9";
}

.lni-hand::before {
    content: "\eada";
}

.lni-handshake::before {
    content: "\eadb";
}

.lni-harddrive::before {
    content: "\eadc";
}

.lni-headphone-alt::before {
    content: "\eadd";
}

.lni-headphone::before {
    content: "\eade";
}

.lni-heart-filled::before {
    content: "\eadf";
}

.lni-heart-monitor::before {
    content: "\eae0";
}

.lni-heart::before {
    content: "\eae1";
}

.lni-helicopter::before {
    content: "\eae2";
}

.lni-helmet::before {
    content: "\eae3";
}

.lni-help::before {
    content: "\eae4";
}

.lni-highlight-alt::before {
    content: "\eae5";
}

.lni-highlight::before {
    content: "\eae6";
}

.lni-home::before {
    content: "\eae7";
}

.lni-hospital::before {
    content: "\eae8";
}

.lni-hourglass::before {
    content: "\eae9";
}

.lni-html5::before {
    content: "\eaea";
}

.lni-image::before {
    content: "\eaeb";
}

.lni-inbox::before {
    content: "\eaec";
}

.lni-indent-decrease::before {
    content: "\eaed";
}

.lni-indent-increase::before {
    content: "\eaee";
}

.lni-infinite::before {
    content: "\eaef";
}

.lni-information::before {
    content: "\eaf0";
}

.lni-instagram-filled::before {
    content: "\eaf1";
}

.lni-instagram-original::before {
    content: "\eaf2";
}

.lni-instagram::before {
    content: "\eaf3";
}

.lni-invention::before {
    content: "\eaf4";
}

.lni-graduation::before {
    content: "\eaf5";
}

.lni-invest-monitor::before {
    content: "\eaf6";
}

.lni-island::before {
    content: "\eaf7";
}

.lni-italic::before {
    content: "\eaf8";
}

.lni-java::before {
    content: "\eaf9";
}

.lni-javascript::before {
    content: "\eafa";
}

.lni-jcb::before {
    content: "\eafb";
}

.lni-joomla-original::before {
    content: "\eafc";
}

.lni-joomla::before {
    content: "\eafd";
}

.lni-jsfiddle::before {
    content: "\eafe";
}

.lni-juice::before {
    content: "\eaff";
}

.lni-key::before {
    content: "\eb00";
}

.lni-keyboard::before {
    content: "\eb01";
}

.lni-keyword-research::before {
    content: "\eb02";
}

.lni-hacker-news::before {
    content: "\eb03";
}

.lni-google::before {
    content: "\eb04";
}

.lni-laravel::before {
    content: "\eb05";
}

.lni-layers::before {
    content: "\eb06";
}

.lni-layout::before {
    content: "\eb07";
}

.lni-leaf::before {
    content: "\eb08";
}

.lni-library::before {
    content: "\eb09";
}

.lni-licencse::before {
    content: "\eb0a";
}

.lni-life-ring::before {
    content: "\eb0b";
}

.lni-line-dashed::before {
    content: "\eb0c";
}

.lni-line-dotted::before {
    content: "\eb0d";
}

.lni-line-double::before {
    content: "\eb0e";
}

.lni-line-spacing::before {
    content: "\eb0f";
}

.lni-line::before {
    content: "\eb10";
}

.lni-lineicons-alt::before {
    content: "\eb11";
}

.lni-lineicons::before {
    content: "\eb12";
}

.lni-link::before {
    content: "\eb13";
}

.lni-linkedin-original::before {
    content: "\eb14";
}

.lni-linkedin::before {
    content: "\eb15";
}

.lni-list::before {
    content: "\eb16";
}

.lni-lock-alt::before {
    content: "\eb17";
}

.lni-lock::before {
    content: "\eb18";
}

.lni-magnet::before {
    content: "\eb19";
}

.lni-magnifier::before {
    content: "\eb1a";
}

.lni-mailchimp::before {
    content: "\eb1b";
}

.lni-map-marker::before {
    content: "\eb1c";
}

.lni-map::before {
    content: "\eb1d";
}

.lni-mashroom::before {
    content: "\eb1e";
}

.lni-mastercard::before {
    content: "\eb1f";
}

.lni-medall-alt::before {
    content: "\eb20";
}

.lni-medall::before {
    content: "\eb21";
}

.lni-medium::before {
    content: "\eb22";
}

.lni-laptop::before {
    content: "\eb23";
}

.lni-investment::before {
    content: "\eb24";
}

.lni-laptop-phone::before {
    content: "\eb25";
}

.lni-megento::before {
    content: "\eb26";
}

.lni-mic::before {
    content: "\eb27";
}

.lni-microphone::before {
    content: "\eb28";
}

.lni-menu::before {
    content: "\eb29";
}

.lni-microscope::before {
    content: "\eb2a";
}

.lni-money-location::before {
    content: "\eb2b";
}

.lni-minus::before {
    content: "\eb2c";
}

.lni-mobile::before {
    content: "\eb2d";
}

.lni-more-alt::before {
    content: "\eb2e";
}

.lni-mouse::before {
    content: "\eb2f";
}

.lni-move::before {
    content: "\eb30";
}

.lni-music::before {
    content: "\eb31";
}

.lni-network::before {
    content: "\eb32";
}

.lni-night::before {
    content: "\eb33";
}

.lni-nodejs-alt::before {
    content: "\eb34";
}

.lni-nodejs::before {
    content: "\eb35";
}

.lni-notepad::before {
    content: "\eb36";
}

.lni-npm::before {
    content: "\eb37";
}

.lni-offer::before {
    content: "\eb38";
}

.lni-opera::before {
    content: "\eb39";
}

.lni-package::before {
    content: "\eb3a";
}

.lni-page-break::before {
    content: "\eb3b";
}

.lni-pagination::before {
    content: "\eb3c";
}

.lni-paint-bucket::before {
    content: "\eb3d";
}

.lni-paint-roller::before {
    content: "\eb3e";
}

.lni-pallet::before {
    content: "\eb3f";
}

.lni-paperclip::before {
    content: "\eb40";
}

.lni-more::before {
    content: "\eb41";
}

.lni-pause::before {
    content: "\eb42";
}

.lni-paypal-original::before {
    content: "\eb43";
}

.lni-microsoft::before {
    content: "\eb44";
}

.lni-money-protection::before {
    content: "\eb45";
}

.lni-pencil::before {
    content: "\eb46";
}

.lni-paypal::before {
    content: "\eb47";
}

.lni-pencil-alt::before {
    content: "\eb48";
}

.lni-patreon::before {
    content: "\eb49";
}

.lni-phone-set::before {
    content: "\eb4a";
}

.lni-phone::before {
    content: "\eb4b";
}

.lni-pin::before {
    content: "\eb4c";
}

.lni-pinterest::before {
    content: "\eb4d";
}

.lni-pie-chart::before {
    content: "\eb4e";
}

.lni-pilcrow::before {
    content: "\eb4f";
}

.lni-plane::before {
    content: "\eb50";
}

.lni-play::before {
    content: "\eb51";
}

.lni-plug::before {
    content: "\eb52";
}

.lni-plus::before {
    content: "\eb53";
}

.lni-pointer-down::before {
    content: "\eb54";
}

.lni-pointer-left::before {
    content: "\eb55";
}

.lni-pointer-right::before {
    content: "\eb56";
}

.lni-pointer-up::before {
    content: "\eb57";
}

.lni-play-store::before {
    content: "\eb58";
}

.lni-pizza::before {
    content: "\eb59";
}

.lni-postcard::before {
    content: "\eb5a";
}

.lni-pound::before {
    content: "\eb5b";
}

.lni-power-switch::before {
    content: "\eb5c";
}

.lni-printer::before {
    content: "\eb5d";
}

.lni-producthunt::before {
    content: "\eb5e";
}

.lni-protection::before {
    content: "\eb5f";
}

.lni-pulse::before {
    content: "\eb60";
}

.lni-pyramids::before {
    content: "\eb61";
}

.lni-python::before {
    content: "\eb62";
}

.lni-pointer::before {
    content: "\eb63";
}

.lni-popup::before {
    content: "\eb64";
}

.lni-quotation::before {
    content: "\eb65";
}

.lni-radio-button::before {
    content: "\eb66";
}

.lni-rain::before {
    content: "\eb67";
}

.lni-quora::before {
    content: "\eb68";
}

.lni-react::before {
    content: "\eb69";
}

.lni-question-circle::before {
    content: "\eb6a";
}

.lni-php::before {
    content: "\eb6b";
}

.lni-reddit::before {
    content: "\eb6c";
}

.lni-reload::before {
    content: "\eb6d";
}

.lni-restaurant::before {
    content: "\eb6e";
}

.lni-road::before {
    content: "\eb6f";
}

.lni-rocket::before {
    content: "\eb70";
}

.lni-rss-feed::before {
    content: "\eb71";
}

.lni-ruler-alt::before {
    content: "\eb72";
}

.lni-ruler-pencil::before {
    content: "\eb73";
}

.lni-ruler::before {
    content: "\eb74";
}

.lni-rupee::before {
    content: "\eb75";
}

.lni-save::before {
    content: "\eb76";
}

.lni-school-bench-alt::before {
    content: "\eb77";
}

.lni-school-bench::before {
    content: "\eb78";
}

.lni-scooter::before {
    content: "\eb79";
}

.lni-scroll-down::before {
    content: "\eb7a";
}

.lni-search-alt::before {
    content: "\eb7b";
}

.lni-search::before {
    content: "\eb7c";
}

.lni-select::before {
    content: "\eb7d";
}

.lni-seo::before {
    content: "\eb7e";
}

.lni-service::before {
    content: "\eb7f";
}

.lni-share-alt::before {
    content: "\eb80";
}

.lni-share::before {
    content: "\eb81";
}

.lni-shield::before {
    content: "\eb82";
}

.lni-shift-left::before {
    content: "\eb83";
}

.lni-shift-right::before {
    content: "\eb84";
}

.lni-ship::before {
    content: "\eb85";
}

.lni-shopify::before {
    content: "\eb86";
}

.lni-shopping-basket::before {
    content: "\eb87";
}

.lni-shortcode::before {
    content: "\eb88";
}

.lni-shovel::before {
    content: "\eb89";
}

.lni-shuffle::before {
    content: "\eb8a";
}

.lni-signal::before {
    content: "\eb8b";
}

.lni-sketch::before {
    content: "\eb8c";
}

.lni-skipping-rope::before {
    content: "\eb8d";
}

.lni-skype::before {
    content: "\eb8e";
}

.lni-slack::before {
    content: "\eb8f";
}

.lni-slice::before {
    content: "\eb90";
}

.lni-slideshare::before {
    content: "\eb91";
}

.lni-slim::before {
    content: "\eb92";
}

.lni-reply::before {
    content: "\eb93";
}

.lni-sort-alpha-asc::before {
    content: "\eb94";
}

.lni-remove-file::before {
    content: "\eb95";
}

.lni-sort-amount-dsc::before {
    content: "\eb96";
}

.lni-sort-amount-asc::before {
    content: "\eb97";
}

.lni-soundcloud::before {
    content: "\eb98";
}

.lni-souncloud-original::before {
    content: "\eb99";
}

.lni-spiner-solid::before {
    content: "\eb9a";
}

.lni-revenue::before {
    content: "\eb9b";
}

.lni-spinner::before {
    content: "\eb9c";
}

.lni-spellcheck::before {
    content: "\eb9d";
}

.lni-spotify::before {
    content: "\eb9e";
}

.lni-spray::before {
    content: "\eb9f";
}

.lni-sprout::before {
    content: "\eba0";
}

.lni-snapchat::before {
    content: "\eba1";
}

.lni-stamp::before {
    content: "\eba2";
}

.lni-star-empty::before {
    content: "\eba3";
}

.lni-star-filled::before {
    content: "\eba4";
}

.lni-star-half::before {
    content: "\eba5";
}

.lni-star::before {
    content: "\eba6";
}

.lni-stats-down::before {
    content: "\eba7";
}

.lni-spinner-arrow::before {
    content: "\eba8";
}

.lni-steam::before {
    content: "\eba9";
}

.lni-stackoverflow::before {
    content: "\ebaa";
}

.lni-stop::before {
    content: "\ebab";
}

.lni-strikethrough::before {
    content: "\ebac";
}

.lni-sthethoscope::before {
    content: "\ebad";
}

.lni-stumbleupon::before {
    content: "\ebae";
}

.lni-sun::before {
    content: "\ebaf";
}

.lni-support::before {
    content: "\ebb0";
}

.lni-surf-board::before {
    content: "\ebb1";
}

.lni-swift::before {
    content: "\ebb2";
}

.lni-syringe::before {
    content: "\ebb3";
}

.lni-tab::before {
    content: "\ebb4";
}

.lni-tag::before {
    content: "\ebb5";
}

.lni-target-customer::before {
    content: "\ebb6";
}

.lni-target-revenue::before {
    content: "\ebb7";
}

.lni-target::before {
    content: "\ebb8";
}

.lni-taxi::before {
    content: "\ebb9";
}

.lni-stats-up::before {
    content: "\ebba";
}

.lni-telegram-original::before {
    content: "\ebbb";
}

.lni-telegram::before {
    content: "\ebbc";
}

.lni-text-align-center::before {
    content: "\ebbd";
}

.lni-text-align-justify::before {
    content: "\ebbe";
}

.lni-text-align-left::before {
    content: "\ebbf";
}

.lni-text-format-remove::before {
    content: "\ebc0";
}

.lni-text-align-right::before {
    content: "\ebc1";
}

.lni-text-format::before {
    content: "\ebc2";
}

.lni-thought::before {
    content: "\ebc3";
}

.lni-thumbs-down::before {
    content: "\ebc4";
}

.lni-thumbs-up::before {
    content: "\ebc5";
}

.lni-thunder-alt::before {
    content: "\ebc6";
}

.lni-thunder::before {
    content: "\ebc7";
}

.lni-ticket-alt::before {
    content: "\ebc8";
}

.lni-ticket::before {
    content: "\ebc9";
}

.lni-timer::before {
    content: "\ebca";
}

.lni-train-alt::before {
    content: "\ebcb";
}

.lni-train::before {
    content: "\ebcc";
}

.lni-trash::before {
    content: "\ebcd";
}

.lni-travel::before {
    content: "\ebce";
}

.lni-tree::before {
    content: "\ebcf";
}

.lni-trees::before {
    content: "\ebd0";
}

.lni-trello::before {
    content: "\ebd1";
}

.lni-trowel::before {
    content: "\ebd2";
}

.lni-tshirt::before {
    content: "\ebd3";
}

.lni-tumblr::before {
    content: "\ebd4";
}

.lni-twitch::before {
    content: "\ebd5";
}

.lni-twitter-filled::before {
    content: "\ebd6";
}

.lni-twitter-original::before {
    content: "\ebd7";
}

.lni-twitter::before {
    content: "\ebd8";
}

.lni-ubuntu::before {
    content: "\ebd9";
}

.lni-underline::before {
    content: "\ebda";
}

.lni-unlink::before {
    content: "\ebdb";
}

.lni-unlock::before {
    content: "\ebdc";
}

.lni-upload::before {
    content: "\ebdd";
}

.lni-user::before {
    content: "\ebde";
}

.lni-users::before {
    content: "\ebdf";
}

.lni-ux::before {
    content: "\ebe0";
}

.lni-vector::before {
    content: "\ebe1";
}

.lni-video::before {
    content: "\ebe2";
}

.lni-vimeo::before {
    content: "\ebe3";
}

.lni-visa::before {
    content: "\ebe4";
}

.lni-vk::before {
    content: "\ebe5";
}

.lni-volume-high::before {
    content: "\ebe6";
}

.lni-volume-low::before {
    content: "\ebe7";
}

.lni-volume-medium::before {
    content: "\ebe8";
}

.lni-volume-mute::before {
    content: "\ebe9";
}

.lni-volume::before {
    content: "\ebea";
}

.lni-wallet::before {
    content: "\ebeb";
}

.lni-warning::before {
    content: "\ebec";
}

.lni-website-alt::before {
    content: "\ebed";
}

.lni-website::before {
    content: "\ebee";
}

.lni-wechat::before {
    content: "\ebef";
}

.lni-weight::before {
    content: "\ebf0";
}

.lni-whatsapp::before {
    content: "\ebf1";
}

.lni-wheelbarrow::before {
    content: "\ebf2";
}

.lni-wheelchair::before {
    content: "\ebf3";
}

.lni-windows::before {
    content: "\ebf4";
}

.lni-wordpress-filled::before {
    content: "\ebf5";
}

.lni-wordpress::before {
    content: "\ebf6";
}

.lni-world-alt::before {
    content: "\ebf7";
}

.lni-world::before {
    content: "\ebf8";
}

.lni-write::before {
    content: "\ebf9";
}

.lni-yahoo::before {
    content: "\ebfa";
}

.lni-ycombinator::before {
    content: "\ebfb";
}

.lni-yen::before {
    content: "\ebfc";
}

.lni-youtube::before {
    content: "\ebfd";
}

.lni-zip::before {
    content: "\ebfe";
}

.lni-zoom-in::before {
    content: "\ebff";
}

.lni-zoom-out::before {
    content: "\ec00";
}

.lni-teabag::before {
    content: "\ec01";
}

.lni-stripe::before {
    content: "\ec02";
}

.lni-spotify-original::before {
    content: "\ec03";
}



/* Flag Icons */


.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\00a0";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon-ad {
  background-image: url(../flags/4x3/ad.svg);
}
.flag-icon-ad.flag-icon-squared {
  background-image: url(../flags/1x1/ad.svg);
}
.flag-icon-ae {
  background-image: url(../flags/4x3/ae.svg);
}
.flag-icon-ae.flag-icon-squared {
  background-image: url(../flags/1x1/ae.svg);
}
.flag-icon-af {
  background-image: url(../flags/4x3/af.svg);
}
.flag-icon-af.flag-icon-squared {
  background-image: url(../flags/1x1/af.svg);
}
.flag-icon-ag {
  background-image: url(../flags/4x3/ag.svg);
}
.flag-icon-ag.flag-icon-squared {
  background-image: url(../flags/1x1/ag.svg);
}
.flag-icon-ai {
  background-image: url(../flags/4x3/ai.svg);
}
.flag-icon-ai.flag-icon-squared {
  background-image: url(../flags/1x1/ai.svg);
}
.flag-icon-al {
  background-image: url(../flags/4x3/al.svg);
}
.flag-icon-al.flag-icon-squared {
  background-image: url(../flags/1x1/al.svg);
}
.flag-icon-am {
  background-image: url(../flags/4x3/am.svg);
}
.flag-icon-am.flag-icon-squared {
  background-image: url(../flags/1x1/am.svg);
}
.flag-icon-ao {
  background-image: url(../flags/4x3/ao.svg);
}
.flag-icon-ao.flag-icon-squared {
  background-image: url(../flags/1x1/ao.svg);
}
.flag-icon-aq {
  background-image: url(../flags/4x3/aq.svg);
}
.flag-icon-aq.flag-icon-squared {
  background-image: url(../flags/1x1/aq.svg);
}
.flag-icon-ar {
  background-image: url(../flags/4x3/ar.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(../flags/1x1/ar.svg);
}
.flag-icon-as {
  background-image: url(../flags/4x3/as.svg);
}
.flag-icon-as.flag-icon-squared {
  background-image: url(../flags/1x1/as.svg);
}
.flag-icon-at {
  background-image: url(../flags/4x3/at.svg);
}
.flag-icon-at.flag-icon-squared {
  background-image: url(../flags/1x1/at.svg);
}
.flag-icon-au {
  background-image: url(../flags/4x3/au.svg);
}
.flag-icon-au.flag-icon-squared {
  background-image: url(../flags/1x1/au.svg);
}
.flag-icon-aw {
  background-image: url(../flags/4x3/aw.svg);
}
.flag-icon-aw.flag-icon-squared {
  background-image: url(../flags/1x1/aw.svg);
}
.flag-icon-ax {
  background-image: url(../flags/4x3/ax.svg);
}
.flag-icon-ax.flag-icon-squared {
  background-image: url(../flags/1x1/ax.svg);
}
.flag-icon-az {
  background-image: url(../flags/4x3/az.svg);
}
.flag-icon-az.flag-icon-squared {
  background-image: url(../flags/1x1/az.svg);
}
.flag-icon-ba {
  background-image: url(../flags/4x3/ba.svg);
}
.flag-icon-ba.flag-icon-squared {
  background-image: url(../flags/1x1/ba.svg);
}
.flag-icon-bb {
  background-image: url(../flags/4x3/bb.svg);
}
.flag-icon-bb.flag-icon-squared {
  background-image: url(../flags/1x1/bb.svg);
}
.flag-icon-bd {
  background-image: url(../flags/4x3/bd.svg);
}
.flag-icon-bd.flag-icon-squared {
  background-image: url(../flags/1x1/bd.svg);
}
.flag-icon-be {
  background-image: url(../flags/4x3/be.svg);
}
.flag-icon-be.flag-icon-squared {
  background-image: url(../flags/1x1/be.svg);
}
.flag-icon-bf {
  background-image: url(../flags/4x3/bf.svg);
}
.flag-icon-bf.flag-icon-squared {
  background-image: url(../flags/1x1/bf.svg);
}
.flag-icon-bg {
  background-image: url(../flags/4x3/bg.svg);
}
.flag-icon-bg.flag-icon-squared {
  background-image: url(../flags/1x1/bg.svg);
}
.flag-icon-bh {
  background-image: url(../flags/4x3/bh.svg);
}
.flag-icon-bh.flag-icon-squared {
  background-image: url(../flags/1x1/bh.svg);
}
.flag-icon-bi {
  background-image: url(../flags/4x3/bi.svg);
}
.flag-icon-bi.flag-icon-squared {
  background-image: url(../flags/1x1/bi.svg);
}
.flag-icon-bj {
  background-image: url(../flags/4x3/bj.svg);
}
.flag-icon-bj.flag-icon-squared {
  background-image: url(../flags/1x1/bj.svg);
}
.flag-icon-bl {
  background-image: url(../flags/4x3/bl.svg);
}
.flag-icon-bl.flag-icon-squared {
  background-image: url(../flags/1x1/bl.svg);
}
.flag-icon-bm {
  background-image: url(../flags/4x3/bm.svg);
}
.flag-icon-bm.flag-icon-squared {
  background-image: url(../flags/1x1/bm.svg);
}
.flag-icon-bn {
  background-image: url(../flags/4x3/bn.svg);
}
.flag-icon-bn.flag-icon-squared {
  background-image: url(../flags/1x1/bn.svg);
}
.flag-icon-bo {
  background-image: url(../flags/4x3/bo.svg);
}
.flag-icon-bo.flag-icon-squared {
  background-image: url(../flags/1x1/bo.svg);
}
.flag-icon-bq {
  background-image: url(../flags/4x3/bq.svg);
}
.flag-icon-bq.flag-icon-squared {
  background-image: url(../flags/1x1/bq.svg);
}
.flag-icon-br {
  background-image: url(../flags/4x3/br.svg);
}
.flag-icon-br.flag-icon-squared {
  background-image: url(../flags/1x1/br.svg);
}
.flag-icon-bs {
  background-image: url(../flags/4x3/bs.svg);
}
.flag-icon-bs.flag-icon-squared {
  background-image: url(../flags/1x1/bs.svg);
}
.flag-icon-bt {
  background-image: url(../flags/4x3/bt.svg);
}
.flag-icon-bt.flag-icon-squared {
  background-image: url(../flags/1x1/bt.svg);
}
.flag-icon-bv {
  background-image: url(../flags/4x3/bv.svg);
}
.flag-icon-bv.flag-icon-squared {
  background-image: url(../flags/1x1/bv.svg);
}
.flag-icon-bw {
  background-image: url(../flags/4x3/bw.svg);
}
.flag-icon-bw.flag-icon-squared {
  background-image: url(../flags/1x1/bw.svg);
}
.flag-icon-by {
  background-image: url(../flags/4x3/by.svg);
}
.flag-icon-by.flag-icon-squared {
  background-image: url(../flags/1x1/by.svg);
}
.flag-icon-bz {
  background-image: url(../flags/4x3/bz.svg);
}
.flag-icon-bz.flag-icon-squared {
  background-image: url(../flags/1x1/bz.svg);
}
.flag-icon-ca {
  background-image: url(../flags/4x3/ca.svg);
}
.flag-icon-ca.flag-icon-squared {
  background-image: url(../flags/1x1/ca.svg);
}
.flag-icon-cc {
  background-image: url(../flags/4x3/cc.svg);
}
.flag-icon-cc.flag-icon-squared {
  background-image: url(../flags/1x1/cc.svg);
}
.flag-icon-cd {
  background-image: url(../flags/4x3/cd.svg);
}
.flag-icon-cd.flag-icon-squared {
  background-image: url(../flags/1x1/cd.svg);
}
.flag-icon-cf {
  background-image: url(../flags/4x3/cf.svg);
}
.flag-icon-cf.flag-icon-squared {
  background-image: url(../flags/1x1/cf.svg);
}
.flag-icon-cg {
  background-image: url(../flags/4x3/cg.svg);
}
.flag-icon-cg.flag-icon-squared {
  background-image: url(../flags/1x1/cg.svg);
}
.flag-icon-ch {
  background-image: url(../flags/4x3/ch.svg);
}
.flag-icon-ch.flag-icon-squared {
  background-image: url(../flags/1x1/ch.svg);
}
.flag-icon-ci {
  background-image: url(../flags/4x3/ci.svg);
}
.flag-icon-ci.flag-icon-squared {
  background-image: url(../flags/1x1/ci.svg);
}
.flag-icon-ck {
  background-image: url(../flags/4x3/ck.svg);
}
.flag-icon-ck.flag-icon-squared {
  background-image: url(../flags/1x1/ck.svg);
}
.flag-icon-cl {
  background-image: url(../flags/4x3/cl.svg);
}
.flag-icon-cl.flag-icon-squared {
  background-image: url(../flags/1x1/cl.svg);
}
.flag-icon-cm {
  background-image: url(../flags/4x3/cm.svg);
}
.flag-icon-cm.flag-icon-squared {
  background-image: url(../flags/1x1/cm.svg);
}
.flag-icon-cn {
  background-image: url(../flags/4x3/cn.svg);
}
.flag-icon-cn.flag-icon-squared {
  background-image: url(../flags/1x1/cn.svg);
}
.flag-icon-co {
  background-image: url(../flags/4x3/co.svg);
}
.flag-icon-co.flag-icon-squared {
  background-image: url(../flags/1x1/co.svg);
}
.flag-icon-cr {
  background-image: url(../flags/4x3/cr.svg);
}
.flag-icon-cr.flag-icon-squared {
  background-image: url(../flags/1x1/cr.svg);
}
.flag-icon-cu {
  background-image: url(../flags/4x3/cu.svg);
}
.flag-icon-cu.flag-icon-squared {
  background-image: url(../flags/1x1/cu.svg);
}
.flag-icon-cv {
  background-image: url(../flags/4x3/cv.svg);
}
.flag-icon-cv.flag-icon-squared {
  background-image: url(../flags/1x1/cv.svg);
}
.flag-icon-cw {
  background-image: url(../flags/4x3/cw.svg);
}
.flag-icon-cw.flag-icon-squared {
  background-image: url(../flags/1x1/cw.svg);
}
.flag-icon-cx {
  background-image: url(../flags/4x3/cx.svg);
}
.flag-icon-cx.flag-icon-squared {
  background-image: url(../flags/1x1/cx.svg);
}
.flag-icon-cy {
  background-image: url(../flags/4x3/cy.svg);
}
.flag-icon-cy.flag-icon-squared {
  background-image: url(../flags/1x1/cy.svg);
}
.flag-icon-cz {
  background-image: url(../flags/4x3/cz.svg);
}
.flag-icon-cz.flag-icon-squared {
  background-image: url(../flags/1x1/cz.svg);
}
.flag-icon-de {
  background-image: url(../flags/4x3/de.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(../flags/1x1/de.svg);
}
.flag-icon-dj {
  background-image: url(../flags/4x3/dj.svg);
}
.flag-icon-dj.flag-icon-squared {
  background-image: url(../flags/1x1/dj.svg);
}
.flag-icon-dk {
  background-image: url(../flags/4x3/dk.svg);
}
.flag-icon-dk.flag-icon-squared {
  background-image: url(../flags/1x1/dk.svg);
}
.flag-icon-dm {
  background-image: url(../flags/4x3/dm.svg);
}
.flag-icon-dm.flag-icon-squared {
  background-image: url(../flags/1x1/dm.svg);
}
.flag-icon-do {
  background-image: url(../flags/4x3/do.svg);
}
.flag-icon-do.flag-icon-squared {
  background-image: url(../flags/1x1/do.svg);
}
.flag-icon-dz {
  background-image: url(../flags/4x3/dz.svg);
}
.flag-icon-dz.flag-icon-squared {
  background-image: url(../flags/1x1/dz.svg);
}
.flag-icon-ec {
  background-image: url(../flags/4x3/ec.svg);
}
.flag-icon-ec.flag-icon-squared {
  background-image: url(../flags/1x1/ec.svg);
}
.flag-icon-ee {
  background-image: url(../flags/4x3/ee.svg);
}
.flag-icon-ee.flag-icon-squared {
  background-image: url(../flags/1x1/ee.svg);
}
.flag-icon-eg {
  background-image: url(../flags/4x3/eg.svg);
}
.flag-icon-eg.flag-icon-squared {
  background-image: url(../flags/1x1/eg.svg);
}
.flag-icon-eh {
  background-image: url(../flags/4x3/eh.svg);
}
.flag-icon-eh.flag-icon-squared {
  background-image: url(../flags/1x1/eh.svg);
}
.flag-icon-er {
  background-image: url(../flags/4x3/er.svg);
}
.flag-icon-er.flag-icon-squared {
  background-image: url(../flags/1x1/er.svg);
}
.flag-icon-es {
  background-image: url(../flags/4x3/es.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(../flags/1x1/es.svg);
}
.flag-icon-et {
  background-image: url(../flags/4x3/et.svg);
}
.flag-icon-et.flag-icon-squared {
  background-image: url(../flags/1x1/et.svg);
}
.flag-icon-fi {
  background-image: url(../flags/4x3/fi.svg);
}
.flag-icon-fi.flag-icon-squared {
  background-image: url(../flags/1x1/fi.svg);
}
.flag-icon-fj {
  background-image: url(../flags/4x3/fj.svg);
}
.flag-icon-fj.flag-icon-squared {
  background-image: url(../flags/1x1/fj.svg);
}
.flag-icon-fk {
  background-image: url(../flags/4x3/fk.svg);
}
.flag-icon-fk.flag-icon-squared {
  background-image: url(../flags/1x1/fk.svg);
}
.flag-icon-fm {
  background-image: url(../flags/4x3/fm.svg);
}
.flag-icon-fm.flag-icon-squared {
  background-image: url(../flags/1x1/fm.svg);
}
.flag-icon-fo {
  background-image: url(../flags/4x3/fo.svg);
}
.flag-icon-fo.flag-icon-squared {
  background-image: url(../flags/1x1/fo.svg);
}
.flag-icon-fr {
  background-image: url(../flags/4x3/fr.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(../flags/1x1/fr.svg);
}
.flag-icon-ga {
  background-image: url(../flags/4x3/ga.svg);
}
.flag-icon-ga.flag-icon-squared {
  background-image: url(../flags/1x1/ga.svg);
}
.flag-icon-gb {
  background-image: url(../flags/4x3/gb.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(../flags/1x1/gb.svg);
}
.flag-icon-gd {
  background-image: url(../flags/4x3/gd.svg);
}
.flag-icon-gd.flag-icon-squared {
  background-image: url(../flags/1x1/gd.svg);
}
.flag-icon-ge {
  background-image: url(../flags/4x3/ge.svg);
}
.flag-icon-ge.flag-icon-squared {
  background-image: url(../flags/1x1/ge.svg);
}
.flag-icon-gf {
  background-image: url(../flags/4x3/gf.svg);
}
.flag-icon-gf.flag-icon-squared {
  background-image: url(../flags/1x1/gf.svg);
}
.flag-icon-gg {
  background-image: url(../flags/4x3/gg.svg);
}
.flag-icon-gg.flag-icon-squared {
  background-image: url(../flags/1x1/gg.svg);
}
.flag-icon-gh {
  background-image: url(../flags/4x3/gh.svg);
}
.flag-icon-gh.flag-icon-squared {
  background-image: url(../flags/1x1/gh.svg);
}
.flag-icon-gi {
  background-image: url(../flags/4x3/gi.svg);
}
.flag-icon-gi.flag-icon-squared {
  background-image: url(../flags/1x1/gi.svg);
}
.flag-icon-gl {
  background-image: url(../flags/4x3/gl.svg);
}
.flag-icon-gl.flag-icon-squared {
  background-image: url(../flags/1x1/gl.svg);
}
.flag-icon-gm {
  background-image: url(../flags/4x3/gm.svg);
}
.flag-icon-gm.flag-icon-squared {
  background-image: url(../flags/1x1/gm.svg);
}
.flag-icon-gn {
  background-image: url(../flags/4x3/gn.svg);
}
.flag-icon-gn.flag-icon-squared {
  background-image: url(../flags/1x1/gn.svg);
}
.flag-icon-gp {
  background-image: url(../flags/4x3/gp.svg);
}
.flag-icon-gp.flag-icon-squared {
  background-image: url(../flags/1x1/gp.svg);
}
.flag-icon-gq {
  background-image: url(../flags/4x3/gq.svg);
}
.flag-icon-gq.flag-icon-squared {
  background-image: url(../flags/1x1/gq.svg);
}
.flag-icon-gr {
  background-image: url(../flags/4x3/gr.svg);
}
.flag-icon-gr.flag-icon-squared {
  background-image: url(../flags/1x1/gr.svg);
}
.flag-icon-gs {
  background-image: url(../flags/4x3/gs.svg);
}
.flag-icon-gs.flag-icon-squared {
  background-image: url(../flags/1x1/gs.svg);
}
.flag-icon-gt {
  background-image: url(../flags/4x3/gt.svg);
}
.flag-icon-gt.flag-icon-squared {
  background-image: url(../flags/1x1/gt.svg);
}
.flag-icon-gu {
  background-image: url(../flags/4x3/gu.svg);
}
.flag-icon-gu.flag-icon-squared {
  background-image: url(../flags/1x1/gu.svg);
}
.flag-icon-gw {
  background-image: url(../flags/4x3/gw.svg);
}
.flag-icon-gw.flag-icon-squared {
  background-image: url(../flags/1x1/gw.svg);
}
.flag-icon-gy {
  background-image: url(../flags/4x3/gy.svg);
}
.flag-icon-gy.flag-icon-squared {
  background-image: url(../flags/1x1/gy.svg);
}
.flag-icon-hk {
  background-image: url(../flags/4x3/hk.svg);
}
.flag-icon-hk.flag-icon-squared {
  background-image: url(../flags/1x1/hk.svg);
}
.flag-icon-hm {
  background-image: url(../flags/4x3/hm.svg);
}
.flag-icon-hm.flag-icon-squared {
  background-image: url(../flags/1x1/hm.svg);
}
.flag-icon-hn {
  background-image: url(../flags/4x3/hn.svg);
}
.flag-icon-hn.flag-icon-squared {
  background-image: url(../flags/1x1/hn.svg);
}
.flag-icon-hr {
  background-image: url(../flags/4x3/hr.svg);
}
.flag-icon-hr.flag-icon-squared {
  background-image: url(../flags/1x1/hr.svg);
}
.flag-icon-ht {
  background-image: url(../flags/4x3/ht.svg);
}
.flag-icon-ht.flag-icon-squared {
  background-image: url(../flags/1x1/ht.svg);
}
.flag-icon-hu {
  background-image: url(../flags/4x3/hu.svg);
}
.flag-icon-hu.flag-icon-squared {
  background-image: url(../flags/1x1/hu.svg);
}
.flag-icon-id {
  background-image: url(../flags/4x3/id.svg);
}
.flag-icon-id.flag-icon-squared {
  background-image: url(../flags/1x1/id.svg);
}
.flag-icon-ie {
  background-image: url(../flags/4x3/ie.svg);
}
.flag-icon-ie.flag-icon-squared {
  background-image: url(../flags/1x1/ie.svg);
}
.flag-icon-il {
  background-image: url(../flags/4x3/il.svg);
}
.flag-icon-il.flag-icon-squared {
  background-image: url(../flags/1x1/il.svg);
}
.flag-icon-im {
  background-image: url(../flags/4x3/im.svg);
}
.flag-icon-im.flag-icon-squared {
  background-image: url(../flags/1x1/im.svg);
}
.flag-icon-in {
  background-image: url(../flags/4x3/in.svg);
}
.flag-icon-in.flag-icon-squared {
  background-image: url(../flags/1x1/in.svg);
}
.flag-icon-io {
  background-image: url(../flags/4x3/io.svg);
}
.flag-icon-io.flag-icon-squared {
  background-image: url(../flags/1x1/io.svg);
}
.flag-icon-iq {
  background-image: url(../flags/4x3/iq.svg);
}
.flag-icon-iq.flag-icon-squared {
  background-image: url(../flags/1x1/iq.svg);
}
.flag-icon-ir {
  background-image: url(../flags/4x3/ir.svg);
}
.flag-icon-ir.flag-icon-squared {
  background-image: url(../flags/1x1/ir.svg);
}
.flag-icon-is {
  background-image: url(../flags/4x3/is.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(../flags/1x1/is.svg);
}
.flag-icon-it {
  background-image: url(../flags/4x3/it.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(../flags/1x1/it.svg);
}
.flag-icon-je {
  background-image: url(../flags/4x3/je.svg);
}
.flag-icon-je.flag-icon-squared {
  background-image: url(../flags/1x1/je.svg);
}
.flag-icon-jm {
  background-image: url(../flags/4x3/jm.svg);
}
.flag-icon-jm.flag-icon-squared {
  background-image: url(../flags/1x1/jm.svg);
}
.flag-icon-jo {
  background-image: url(../flags/4x3/jo.svg);
}
.flag-icon-jo.flag-icon-squared {
  background-image: url(../flags/1x1/jo.svg);
}
.flag-icon-jp {
  background-image: url(../flags/4x3/jp.svg);
}
.flag-icon-jp.flag-icon-squared {
  background-image: url(../flags/1x1/jp.svg);
}
.flag-icon-ke {
  background-image: url(../flags/4x3/ke.svg);
}
.flag-icon-ke.flag-icon-squared {
  background-image: url(../flags/1x1/ke.svg);
}
.flag-icon-kg {
  background-image: url(../flags/4x3/kg.svg);
}
.flag-icon-kg.flag-icon-squared {
  background-image: url(../flags/1x1/kg.svg);
}
.flag-icon-kh {
  background-image: url(../flags/4x3/kh.svg);
}
.flag-icon-kh.flag-icon-squared {
  background-image: url(../flags/1x1/kh.svg);
}
.flag-icon-ki {
  background-image: url(../flags/4x3/ki.svg);
}
.flag-icon-ki.flag-icon-squared {
  background-image: url(../flags/1x1/ki.svg);
}
.flag-icon-km {
  background-image: url(../flags/4x3/km.svg);
}
.flag-icon-km.flag-icon-squared {
  background-image: url(../flags/1x1/km.svg);
}
.flag-icon-kn {
  background-image: url(../flags/4x3/kn.svg);
}
.flag-icon-kn.flag-icon-squared {
  background-image: url(../flags/1x1/kn.svg);
}
.flag-icon-kp {
  background-image: url(../flags/4x3/kp.svg);
}
.flag-icon-kp.flag-icon-squared {
  background-image: url(../flags/1x1/kp.svg);
}
.flag-icon-kr {
  background-image: url(../flags/4x3/kr.svg);
}
.flag-icon-kr.flag-icon-squared {
  background-image: url(../flags/1x1/kr.svg);
}
.flag-icon-kw {
  background-image: url(../flags/4x3/kw.svg);
}
.flag-icon-kw.flag-icon-squared {
  background-image: url(../flags/1x1/kw.svg);
}
.flag-icon-ky {
  background-image: url(../flags/4x3/ky.svg);
}
.flag-icon-ky.flag-icon-squared {
  background-image: url(../flags/1x1/ky.svg);
}
.flag-icon-kz {
  background-image: url(../flags/4x3/kz.svg);
}
.flag-icon-kz.flag-icon-squared {
  background-image: url(../flags/1x1/kz.svg);
}
.flag-icon-la {
  background-image: url(../flags/4x3/la.svg);
}
.flag-icon-la.flag-icon-squared {
  background-image: url(../flags/1x1/la.svg);
}
.flag-icon-lb {
  background-image: url(../flags/4x3/lb.svg);
}
.flag-icon-lb.flag-icon-squared {
  background-image: url(../flags/1x1/lb.svg);
}
.flag-icon-lc {
  background-image: url(../flags/4x3/lc.svg);
}
.flag-icon-lc.flag-icon-squared {
  background-image: url(../flags/1x1/lc.svg);
}
.flag-icon-li {
  background-image: url(../flags/4x3/li.svg);
}
.flag-icon-li.flag-icon-squared {
  background-image: url(../flags/1x1/li.svg);
}
.flag-icon-lk {
  background-image: url(../flags/4x3/lk.svg);
}
.flag-icon-lk.flag-icon-squared {
  background-image: url(../flags/1x1/lk.svg);
}
.flag-icon-lr {
  background-image: url(../flags/4x3/lr.svg);
}
.flag-icon-lr.flag-icon-squared {
  background-image: url(../flags/1x1/lr.svg);
}
.flag-icon-ls {
  background-image: url(../flags/4x3/ls.svg);
}
.flag-icon-ls.flag-icon-squared {
  background-image: url(../flags/1x1/ls.svg);
}
.flag-icon-lt {
  background-image: url(../flags/4x3/lt.svg);
}
.flag-icon-lt.flag-icon-squared {
  background-image: url(../flags/1x1/lt.svg);
}
.flag-icon-lu {
  background-image: url(../flags/4x3/lu.svg);
}
.flag-icon-lu.flag-icon-squared {
  background-image: url(../flags/1x1/lu.svg);
}
.flag-icon-lv {
  background-image: url(../flags/4x3/lv.svg);
}
.flag-icon-lv.flag-icon-squared {
  background-image: url(../flags/1x1/lv.svg);
}
.flag-icon-ly {
  background-image: url(../flags/4x3/ly.svg);
}
.flag-icon-ly.flag-icon-squared {
  background-image: url(../flags/1x1/ly.svg);
}
.flag-icon-ma {
  background-image: url(../flags/4x3/ma.svg);
}
.flag-icon-ma.flag-icon-squared {
  background-image: url(../flags/1x1/ma.svg);
}
.flag-icon-mc {
  background-image: url(../flags/4x3/mc.svg);
}
.flag-icon-mc.flag-icon-squared {
  background-image: url(../flags/1x1/mc.svg);
}
.flag-icon-md {
  background-image: url(../flags/4x3/md.svg);
}
.flag-icon-md.flag-icon-squared {
  background-image: url(../flags/1x1/md.svg);
}
.flag-icon-me {
  background-image: url(../flags/4x3/me.svg);
}
.flag-icon-me.flag-icon-squared {
  background-image: url(../flags/1x1/me.svg);
}
.flag-icon-mf {
  background-image: url(../flags/4x3/mf.svg);
}
.flag-icon-mf.flag-icon-squared {
  background-image: url(../flags/1x1/mf.svg);
}
.flag-icon-mg {
  background-image: url(../flags/4x3/mg.svg);
}
.flag-icon-mg.flag-icon-squared {
  background-image: url(../flags/1x1/mg.svg);
}
.flag-icon-mh {
  background-image: url(../flags/4x3/mh.svg);
}
.flag-icon-mh.flag-icon-squared {
  background-image: url(../flags/1x1/mh.svg);
}
.flag-icon-mk {
  background-image: url(../flags/4x3/mk.svg);
}
.flag-icon-mk.flag-icon-squared {
  background-image: url(../flags/1x1/mk.svg);
}
.flag-icon-ml {
  background-image: url(../flags/4x3/ml.svg);
}
.flag-icon-ml.flag-icon-squared {
  background-image: url(../flags/1x1/ml.svg);
}
.flag-icon-mm {
  background-image: url(../flags/4x3/mm.svg);
}
.flag-icon-mm.flag-icon-squared {
  background-image: url(../flags/1x1/mm.svg);
}
.flag-icon-mn {
  background-image: url(../flags/4x3/mn.svg);
}
.flag-icon-mn.flag-icon-squared {
  background-image: url(../flags/1x1/mn.svg);
}
.flag-icon-mo {
  background-image: url(../flags/4x3/mo.svg);
}
.flag-icon-mo.flag-icon-squared {
  background-image: url(../flags/1x1/mo.svg);
}
.flag-icon-mp {
  background-image: url(../flags/4x3/mp.svg);
}
.flag-icon-mp.flag-icon-squared {
  background-image: url(../flags/1x1/mp.svg);
}
.flag-icon-mq {
  background-image: url(../flags/4x3/mq.svg);
}
.flag-icon-mq.flag-icon-squared {
  background-image: url(../flags/1x1/mq.svg);
}
.flag-icon-mr {
  background-image: url(../flags/4x3/mr.svg);
}
.flag-icon-mr.flag-icon-squared {
  background-image: url(../flags/1x1/mr.svg);
}
.flag-icon-ms {
  background-image: url(../flags/4x3/ms.svg);
}
.flag-icon-ms.flag-icon-squared {
  background-image: url(../flags/1x1/ms.svg);
}
.flag-icon-mt {
  background-image: url(../flags/4x3/mt.svg);
}
.flag-icon-mt.flag-icon-squared {
  background-image: url(../flags/1x1/mt.svg);
}
.flag-icon-mu {
  background-image: url(../flags/4x3/mu.svg);
}
.flag-icon-mu.flag-icon-squared {
  background-image: url(../flags/1x1/mu.svg);
}
.flag-icon-mv {
  background-image: url(../flags/4x3/mv.svg);
}
.flag-icon-mv.flag-icon-squared {
  background-image: url(../flags/1x1/mv.svg);
}
.flag-icon-mw {
  background-image: url(../flags/4x3/mw.svg);
}
.flag-icon-mw.flag-icon-squared {
  background-image: url(../flags/1x1/mw.svg);
}
.flag-icon-mx {
  background-image: url(../flags/4x3/mx.svg);
}
.flag-icon-mx.flag-icon-squared {
  background-image: url(../flags/1x1/mx.svg);
}
.flag-icon-my {
  background-image: url(../flags/4x3/my.svg);
}
.flag-icon-my.flag-icon-squared {
  background-image: url(../flags/1x1/my.svg);
}
.flag-icon-mz {
  background-image: url(../flags/4x3/mz.svg);
}
.flag-icon-mz.flag-icon-squared {
  background-image: url(../flags/1x1/mz.svg);
}
.flag-icon-na {
  background-image: url(../flags/4x3/na.svg);
}
.flag-icon-na.flag-icon-squared {
  background-image: url(../flags/1x1/na.svg);
}
.flag-icon-nc {
  background-image: url(../flags/4x3/nc.svg);
}
.flag-icon-nc.flag-icon-squared {
  background-image: url(../flags/1x1/nc.svg);
}
.flag-icon-ne {
  background-image: url(../flags/4x3/ne.svg);
}
.flag-icon-ne.flag-icon-squared {
  background-image: url(../flags/1x1/ne.svg);
}
.flag-icon-nf {
  background-image: url(../flags/4x3/nf.svg);
}
.flag-icon-nf.flag-icon-squared {
  background-image: url(../flags/1x1/nf.svg);
}
.flag-icon-ng {
  background-image: url(../flags/4x3/ng.svg);
}
.flag-icon-ng.flag-icon-squared {
  background-image: url(../flags/1x1/ng.svg);
}
.flag-icon-ni {
  background-image: url(../flags/4x3/ni.svg);
}
.flag-icon-ni.flag-icon-squared {
  background-image: url(../flags/1x1/ni.svg);
}
.flag-icon-nl {
  background-image: url(../flags/4x3/nl.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(../flags/1x1/nl.svg);
}
.flag-icon-no {
  background-image: url(../flags/4x3/no.svg);
}
.flag-icon-no.flag-icon-squared {
  background-image: url(../flags/1x1/no.svg);
}
.flag-icon-np {
  background-image: url(../flags/4x3/np.svg);
}
.flag-icon-np.flag-icon-squared {
  background-image: url(../flags/1x1/np.svg);
}
.flag-icon-nr {
  background-image: url(../flags/4x3/nr.svg);
}
.flag-icon-nr.flag-icon-squared {
  background-image: url(../flags/1x1/nr.svg);
}
.flag-icon-nu {
  background-image: url(../flags/4x3/nu.svg);
}
.flag-icon-nu.flag-icon-squared {
  background-image: url(../flags/1x1/nu.svg);
}
.flag-icon-nz {
  background-image: url(../flags/4x3/nz.svg);
}
.flag-icon-nz.flag-icon-squared {
  background-image: url(../flags/1x1/nz.svg);
}
.flag-icon-om {
  background-image: url(../flags/4x3/om.svg);
}
.flag-icon-om.flag-icon-squared {
  background-image: url(../flags/1x1/om.svg);
}
.flag-icon-pa {
  background-image: url(../flags/4x3/pa.svg);
}
.flag-icon-pa.flag-icon-squared {
  background-image: url(../flags/1x1/pa.svg);
}
.flag-icon-pe {
  background-image: url(../flags/4x3/pe.svg);
}
.flag-icon-pe.flag-icon-squared {
  background-image: url(../flags/1x1/pe.svg);
}
.flag-icon-pf {
  background-image: url(../flags/4x3/pf.svg);
}
.flag-icon-pf.flag-icon-squared {
  background-image: url(../flags/1x1/pf.svg);
}
.flag-icon-pg {
  background-image: url(../flags/4x3/pg.svg);
}
.flag-icon-pg.flag-icon-squared {
  background-image: url(../flags/1x1/pg.svg);
}
.flag-icon-ph {
  background-image: url(../flags/4x3/ph.svg);
}
.flag-icon-ph.flag-icon-squared {
  background-image: url(../flags/1x1/ph.svg);
}
.flag-icon-pk {
  background-image: url(../flags/4x3/pk.svg);
}
.flag-icon-pk.flag-icon-squared {
  background-image: url(../flags/1x1/pk.svg);
}
.flag-icon-pl {
  background-image: url(../flags/4x3/pl.svg);
}
.flag-icon-pl.flag-icon-squared {
  background-image: url(../flags/1x1/pl.svg);
}
.flag-icon-pm {
  background-image: url(../flags/4x3/pm.svg);
}
.flag-icon-pm.flag-icon-squared {
  background-image: url(../flags/1x1/pm.svg);
}
.flag-icon-pn {
  background-image: url(../flags/4x3/pn.svg);
}
.flag-icon-pn.flag-icon-squared {
  background-image: url(../flags/1x1/pn.svg);
}
.flag-icon-pr {
  background-image: url(../flags/4x3/pr.svg);
}
.flag-icon-pr.flag-icon-squared {
  background-image: url(../flags/1x1/pr.svg);
}
.flag-icon-ps {
  background-image: url(../flags/4x3/ps.svg);
}
.flag-icon-ps.flag-icon-squared {
  background-image: url(../flags/1x1/ps.svg);
}
.flag-icon-pt {
  background-image: url(../flags/4x3/pt.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(../flags/1x1/pt.svg);
}
.flag-icon-pw {
  background-image: url(../flags/4x3/pw.svg);
}
.flag-icon-pw.flag-icon-squared {
  background-image: url(../flags/1x1/pw.svg);
}
.flag-icon-py {
  background-image: url(../flags/4x3/py.svg);
}
.flag-icon-py.flag-icon-squared {
  background-image: url(../flags/1x1/py.svg);
}
.flag-icon-qa {
  background-image: url(../flags/4x3/qa.svg);
}
.flag-icon-qa.flag-icon-squared {
  background-image: url(../flags/1x1/qa.svg);
}
.flag-icon-re {
  background-image: url(../flags/4x3/re.svg);
}
.flag-icon-re.flag-icon-squared {
  background-image: url(../flags/1x1/re.svg);
}
.flag-icon-ro {
  background-image: url(../flags/4x3/ro.svg);
}
.flag-icon-ro.flag-icon-squared {
  background-image: url(../flags/1x1/ro.svg);
}
.flag-icon-rs {
  background-image: url(../flags/4x3/rs.svg);
}
.flag-icon-rs.flag-icon-squared {
  background-image: url(../flags/1x1/rs.svg);
}
.flag-icon-ru {
  background-image: url(../flags/4x3/ru.svg);
}
.flag-icon-ru.flag-icon-squared {
  background-image: url(../flags/1x1/ru.svg);
}
.flag-icon-rw {
  background-image: url(../flags/4x3/rw.svg);
}
.flag-icon-rw.flag-icon-squared {
  background-image: url(../flags/1x1/rw.svg);
}
.flag-icon-sa {
  background-image: url(../flags/4x3/sa.svg);
}
.flag-icon-sa.flag-icon-squared {
  background-image: url(../flags/1x1/sa.svg);
}
.flag-icon-sb {
  background-image: url(../flags/4x3/sb.svg);
}
.flag-icon-sb.flag-icon-squared {
  background-image: url(../flags/1x1/sb.svg);
}
.flag-icon-sc {
  background-image: url(../flags/4x3/sc.svg);
}
.flag-icon-sc.flag-icon-squared {
  background-image: url(../flags/1x1/sc.svg);
}
.flag-icon-sd {
  background-image: url(../flags/4x3/sd.svg);
}
.flag-icon-sd.flag-icon-squared {
  background-image: url(../flags/1x1/sd.svg);
}
.flag-icon-se {
  background-image: url(../flags/4x3/se.svg);
}
.flag-icon-se.flag-icon-squared {
  background-image: url(../flags/1x1/se.svg);
}
.flag-icon-sg {
  background-image: url(../flags/4x3/sg.svg);
}
.flag-icon-sg.flag-icon-squared {
  background-image: url(../flags/1x1/sg.svg);
}
.flag-icon-sh {
  background-image: url(../flags/4x3/sh.svg);
}
.flag-icon-sh.flag-icon-squared {
  background-image: url(../flags/1x1/sh.svg);
}
.flag-icon-si {
  background-image: url(../flags/4x3/si.svg);
}
.flag-icon-si.flag-icon-squared {
  background-image: url(../flags/1x1/si.svg);
}
.flag-icon-sj {
  background-image: url(../flags/4x3/sj.svg);
}
.flag-icon-sj.flag-icon-squared {
  background-image: url(../flags/1x1/sj.svg);
}
.flag-icon-sk {
  background-image: url(../flags/4x3/sk.svg);
}
.flag-icon-sk.flag-icon-squared {
  background-image: url(../flags/1x1/sk.svg);
}
.flag-icon-sl {
  background-image: url(../flags/4x3/sl.svg);
}
.flag-icon-sl.flag-icon-squared {
  background-image: url(../flags/1x1/sl.svg);
}
.flag-icon-sm {
  background-image: url(../flags/4x3/sm.svg);
}
.flag-icon-sm.flag-icon-squared {
  background-image: url(../flags/1x1/sm.svg);
}
.flag-icon-sn {
  background-image: url(../flags/4x3/sn.svg);
}
.flag-icon-sn.flag-icon-squared {
  background-image: url(../flags/1x1/sn.svg);
}
.flag-icon-so {
  background-image: url(../flags/4x3/so.svg);
}
.flag-icon-so.flag-icon-squared {
  background-image: url(../flags/1x1/so.svg);
}
.flag-icon-sr {
  background-image: url(../flags/4x3/sr.svg);
}
.flag-icon-sr.flag-icon-squared {
  background-image: url(../flags/1x1/sr.svg);
}
.flag-icon-ss {
  background-image: url(../flags/4x3/ss.svg);
}
.flag-icon-ss.flag-icon-squared {
  background-image: url(../flags/1x1/ss.svg);
}
.flag-icon-st {
  background-image: url(../flags/4x3/st.svg);
}
.flag-icon-st.flag-icon-squared {
  background-image: url(../flags/1x1/st.svg);
}
.flag-icon-sv {
  background-image: url(../flags/4x3/sv.svg);
}
.flag-icon-sv.flag-icon-squared {
  background-image: url(../flags/1x1/sv.svg);
}
.flag-icon-sx {
  background-image: url(../flags/4x3/sx.svg);
}
.flag-icon-sx.flag-icon-squared {
  background-image: url(../flags/1x1/sx.svg);
}
.flag-icon-sy {
  background-image: url(../flags/4x3/sy.svg);
}
.flag-icon-sy.flag-icon-squared {
  background-image: url(../flags/1x1/sy.svg);
}
.flag-icon-sz {
  background-image: url(../flags/4x3/sz.svg);
}
.flag-icon-sz.flag-icon-squared {
  background-image: url(../flags/1x1/sz.svg);
}
.flag-icon-tc {
  background-image: url(../flags/4x3/tc.svg);
}
.flag-icon-tc.flag-icon-squared {
  background-image: url(../flags/1x1/tc.svg);
}
.flag-icon-td {
  background-image: url(../flags/4x3/td.svg);
}
.flag-icon-td.flag-icon-squared {
  background-image: url(../flags/1x1/td.svg);
}
.flag-icon-tf {
  background-image: url(../flags/4x3/tf.svg);
}
.flag-icon-tf.flag-icon-squared {
  background-image: url(../flags/1x1/tf.svg);
}
.flag-icon-tg {
  background-image: url(../flags/4x3/tg.svg);
}
.flag-icon-tg.flag-icon-squared {
  background-image: url(../flags/1x1/tg.svg);
}
.flag-icon-th {
  background-image: url(../flags/4x3/th.svg);
}
.flag-icon-th.flag-icon-squared {
  background-image: url(../flags/1x1/th.svg);
}
.flag-icon-tj {
  background-image: url(../flags/4x3/tj.svg);
}
.flag-icon-tj.flag-icon-squared {
  background-image: url(../flags/1x1/tj.svg);
}
.flag-icon-tk {
  background-image: url(../flags/4x3/tk.svg);
}
.flag-icon-tk.flag-icon-squared {
  background-image: url(../flags/1x1/tk.svg);
}
.flag-icon-tl {
  background-image: url(../flags/4x3/tl.svg);
}
.flag-icon-tl.flag-icon-squared {
  background-image: url(../flags/1x1/tl.svg);
}
.flag-icon-tm {
  background-image: url(../flags/4x3/tm.svg);
}
.flag-icon-tm.flag-icon-squared {
  background-image: url(../flags/1x1/tm.svg);
}
.flag-icon-tn {
  background-image: url(../flags/4x3/tn.svg);
}
.flag-icon-tn.flag-icon-squared {
  background-image: url(../flags/1x1/tn.svg);
}
.flag-icon-to {
  background-image: url(../flags/4x3/to.svg);
}
.flag-icon-to.flag-icon-squared {
  background-image: url(../flags/1x1/to.svg);
}
.flag-icon-tr {
  background-image: url(../flags/4x3/tr.svg);
}
.flag-icon-tr.flag-icon-squared {
  background-image: url(../flags/1x1/tr.svg);
}
.flag-icon-tt {
  background-image: url(../flags/4x3/tt.svg);
}
.flag-icon-tt.flag-icon-squared {
  background-image: url(../flags/1x1/tt.svg);
}
.flag-icon-tv {
  background-image: url(../flags/4x3/tv.svg);
}
.flag-icon-tv.flag-icon-squared {
  background-image: url(../flags/1x1/tv.svg);
}
.flag-icon-tw {
  background-image: url(../flags/4x3/tw.svg);
}
.flag-icon-tw.flag-icon-squared {
  background-image: url(../flags/1x1/tw.svg);
}
.flag-icon-tz {
  background-image: url(../flags/4x3/tz.svg);
}
.flag-icon-tz.flag-icon-squared {
  background-image: url(../flags/1x1/tz.svg);
}
.flag-icon-ua {
  background-image: url(../flags/4x3/ua.svg);
}
.flag-icon-ua.flag-icon-squared {
  background-image: url(../flags/1x1/ua.svg);
}
.flag-icon-ug {
  background-image: url(../flags/4x3/ug.svg);
}
.flag-icon-ug.flag-icon-squared {
  background-image: url(../flags/1x1/ug.svg);
}
.flag-icon-um {
  background-image: url(../flags/4x3/um.svg);
}
.flag-icon-um.flag-icon-squared {
  background-image: url(../flags/1x1/um.svg);
}
.flag-icon-us {
  background-image: url(../flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(../flags/1x1/us.svg);
}
.flag-icon-uy {
  background-image: url(../flags/4x3/uy.svg);
}
.flag-icon-uy.flag-icon-squared {
  background-image: url(../flags/1x1/uy.svg);
}
.flag-icon-uz {
  background-image: url(../flags/4x3/uz.svg);
}
.flag-icon-uz.flag-icon-squared {
  background-image: url(../flags/1x1/uz.svg);
}
.flag-icon-va {
  background-image: url(../flags/4x3/va.svg);
}
.flag-icon-va.flag-icon-squared {
  background-image: url(../flags/1x1/va.svg);
}
.flag-icon-vc {
  background-image: url(../flags/4x3/vc.svg);
}
.flag-icon-vc.flag-icon-squared {
  background-image: url(../flags/1x1/vc.svg);
}
.flag-icon-ve {
  background-image: url(../flags/4x3/ve.svg);
}
.flag-icon-ve.flag-icon-squared {
  background-image: url(../flags/1x1/ve.svg);
}
.flag-icon-vg {
  background-image: url(../flags/4x3/vg.svg);
}
.flag-icon-vg.flag-icon-squared {
  background-image: url(../flags/1x1/vg.svg);
}
.flag-icon-vi {
  background-image: url(../flags/4x3/vi.svg);
}
.flag-icon-vi.flag-icon-squared {
  background-image: url(../flags/1x1/vi.svg);
}
.flag-icon-vn {
  background-image: url(../flags/4x3/vn.svg);
}
.flag-icon-vn.flag-icon-squared {
  background-image: url(../flags/1x1/vn.svg);
}
.flag-icon-vu {
  background-image: url(../flags/4x3/vu.svg);
}
.flag-icon-vu.flag-icon-squared {
  background-image: url(../flags/1x1/vu.svg);
}
.flag-icon-wf {
  background-image: url(../flags/4x3/wf.svg);
}
.flag-icon-wf.flag-icon-squared {
  background-image: url(../flags/1x1/wf.svg);
}
.flag-icon-ws {
  background-image: url(../flags/4x3/ws.svg);
}
.flag-icon-ws.flag-icon-squared {
  background-image: url(../flags/1x1/ws.svg);
}
.flag-icon-ye {
  background-image: url(../flags/4x3/ye.svg);
}
.flag-icon-ye.flag-icon-squared {
  background-image: url(../flags/1x1/ye.svg);
}
.flag-icon-yt {
  background-image: url(../flags/4x3/yt.svg);
}
.flag-icon-yt.flag-icon-squared {
  background-image: url(../flags/1x1/yt.svg);
}
.flag-icon-za {
  background-image: url(../flags/4x3/za.svg);
}
.flag-icon-za.flag-icon-squared {
  background-image: url(../flags/1x1/za.svg);
}
.flag-icon-zm {
  background-image: url(../flags/4x3/zm.svg);
}
.flag-icon-zm.flag-icon-squared {
  background-image: url(../flags/1x1/zm.svg);
}
.flag-icon-zw {
  background-image: url(../flags/4x3/zw.svg);
}
.flag-icon-zw.flag-icon-squared {
  background-image: url(../flags/1x1/zw.svg);
}
.flag-icon-es-ct {
  background-image: url(../flags/4x3/es-ct.svg);
}
.flag-icon-es-ct.flag-icon-squared {
  background-image: url(../flags/1x1/es-ct.svg);
}
.flag-icon-eu {
  background-image: url(../flags/4x3/eu.svg);
}
.flag-icon-eu.flag-icon-squared {
  background-image: url(../flags/1x1/eu.svg);
}
.flag-icon-gb-eng {
  background-image: url(../flags/4x3/gb-eng.svg);
}
.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(../flags/1x1/gb-eng.svg);
}
.flag-icon-gb-nir {
  background-image: url(../flags/4x3/gb-nir.svg);
}
.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(../flags/1x1/gb-nir.svg);
}
.flag-icon-gb-sct {
  background-image: url(../flags/4x3/gb-sct.svg);
}
.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(../flags/1x1/gb-sct.svg);
}
.flag-icon-gb-wls {
  background-image: url(../flags/4x3/gb-wls.svg);
}
.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(../flags/1x1/gb-wls.svg);
}
.flag-icon-un {
  background-image: url(../flags/4x3/un.svg);
}
.flag-icon-un.flag-icon-squared {
  background-image: url(../flags/1x1/un.svg);
}















